import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "../pages/Table.css";
import { GetTableHeader, SetTableHeader } from "../store/subscriptionAtom";
import SubscriptionTableSelect from "./SubscriptionTableselect";
import { subscriptionService } from "./../services/subscription.service";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function SubscriptionTable(props) {

  const tableHeader = GetTableHeader();
  const setItemTableHeader = SetTableHeader();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const filterHeader = tableHeader?.filter((item) => item.Parent_Name2 === "1");
  const customColumn = filterHeader?.map((item) => {
      return {
        ...item,
        field: item.Parent_Name1.trim(),
        headerClassName: "bg-gray-200 ",
        headerName: item.Name,
        width: 130,
      };
    }).sort((a, b) => {
      return a.Parent_Code2 - b.Parent_Code2;
    });

  const tableMenuSelect = (option, i) => {
    var choice = option.Parent_Name2 === "1" ? "0" : "1";
    var temp = tableHeader?.map((item) => {
        if (item.Code === option.Code) {
          return { ...item, Parent_Name2: choice };
        }
        return item;
      })
      .sort((a, b) => {
        return a.Parent_Code2 - b.Parent_Code2;
      });
    // let newList = under
    setItemTableHeader(temp);
  };

  function saveCurrentTableHeader() {
    var xmlString = "";
    // filterHeader?.map((element) => {
    //   if (element.Parent_Name2 === "1") {
    //     xmlString += `<a><refno>${element.Code} </refno></a>`;
    //   }
    // });
    // subscriptionService
    //   .updateItemTableHeader(`<root> ${xmlString} </root>`)
    //   .then((res) => {
    //   });
  }

  function onSelectChange(selectedList) {
    var selection = selectedList?.map((index) => {
      return props.tabledata[index];
    });
    props.selectedTableItems(selection);
  }

  function onTableRowClickClick(event) {
    const subId = event.row["Subscription_Ref_No"];
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${subId}`
    );
  }

  return (
    <>
      <div className="dropdown mr-2">
        <button
          className="btn btn-primary bg-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-toggle="dropdown"
          aria-expanded="false"
        ></button>

        <ul
          className="dropdown-menu  scrollable-menu "
          role="menu"
          aria-labelledby="dropdownMenuButton1"
        >
          {tableHeader?.map((val, i) => {
            return (
              <SubscriptionTableSelect
                key={i}
                val={val}
                i={i}
                tableMenuSelect={tableMenuSelect}
              />
            );
          })}

          <div className=" flex  justify-center">
            <div className="">
              {" "}
              <button
                style={{ backgroundColor: "#476EF8" }}
                className="btn text-white  btn-sm"
                onClick={() => saveCurrentTableHeader()}
              >
                Save
              </button>{" "}
            </div>
            <div className="ml-3">
              {" "}
              <button className="btn text-white  bg-danger btn-sm">
                Cancel
              </button>{" "}
            </div>
          </div>
        </ul>
      </div>
      <div>
        <DataGrid
          style={{ height: 500, width: "100%" }}
          className=" table table_css"
          columns={customColumn ? customColumn : []}
          rows={props.tableData?.map((item, i) => {
            return { ...item, id: i };
          })} 
          pageSize={6}
          size={"small"}
          checkboxSelection={true}
          rowsPerPageOptions={[6]}
          onRowSelectionModelChange={onSelectChange}
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          onRowClick={(event) => {
            onTableRowClickClick(event);
          }}
          sx={{
            // boxShadow: 2,
            // border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
              cursor: "pointer",
            },
          }}
        />
      </div>
    </>
  );
}

export default SubscriptionTable;
