import React, { useRef, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import moment from "moment/moment";
import message from "../../assets/message.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";


import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { SetEditReturn, GetCarrierBagStoreList, SetCarrierBagStoreList} from "../../store/carrierBagReturn";
import { rewardService } from "../../services/Reward.service";
import LogicContext from "../../Context/LogicContext";
import CarrierScroller from "./CarrierScroller";


// import { useReactToPrint } from 'react-to-print';



const CarrierDetailsPage = () => {
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  const setEditReturn = SetEditReturn();
  const getReturnList = GetCarrierBagStoreList();
  const setReturnList = SetCarrierBagStoreList();

  const {  id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [historyDetails, setHistoryDetails] = useState({});
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
  
       document.title = 'Reward history details';
    getHistoryDetails(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  
  const getHistoryDetails = async (id) => {
    rewardService.carrierDetails(id).then((response) => {
      if (response.data[0]) {
        setHistoryDetails(response.data[0]);
      }
    });
  };


  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getHistoryDetails(id);
  }



  const editCurrentData = async () => {
    const response = await myPromiseModal({title: "Modify Return",
      body: " Do you want to modify this record?",showInput: false,
      placeHolder: ""});
      if(response.decision === true){
        setEditReturn(historyDetails);
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
      }
  
  };




  const deleteCurrentData = async () => {
    const response = await myPromiseModal({title: "Delete Return",
      body: " Do you want to delete this record?",showInput: false,
      placeHolder: ""});
      if(response.decision === true){
        rewardService.deleteReturn(id).then((res)=> {
          const data =  logicContext.httpResponseManager(res.data[0]);
          if(data?.message_id == 1){
            const newList = getReturnList.filter((item)=>  item?.Ref_No != id);
            setReturnList(newList);
            logicContext.showToast(data?.message_text, 'success');
            navigate(-1);
          }

        });
      }
  
  };





  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }




  const manageStatus = (statusCode, statusName) =>{
    let defaultClass = 'bg-primary text-white';

    switch (statusCode) {
      case 1:
      defaultClass = 'color_1';
        break;
      case  2:
      defaultClass = 'color_2';
        break;
      case 3:
      defaultClass = 'color_3';
        break;
      case 4:
      defaultClass = 'color_4';
        break;
      case 5:
      defaultClass = 'color_5';
        break;
    
      case 6:
      defaultClass = 'color_6';
        break;
    
      default:
        break;
    }

        return (
          <span className={` py-2 px-4 h5 ${defaultClass} rounded`}>
          {statusName}
        </span>
        )

    
      
  }



  return (
    <>
      <section className="  mt-2 ">
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <section className="mt-4 max-auto max-w-6xl d-flex flex-row-reverse ">
              <div className="flex flex-wrap justify-between md:">
                <div>
             
                  <div className="flex ">
                   
                    {/* <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={paper} alt="" className="" />
                    </button> */}
                    {/* <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={pdf} alt="" className="" />
                    </button> */}
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={printer} alt="" className="" />
                    </button>

                    <button
                      style={{ borderRadius: "5px" }}
                      onClick={editCurrentData}
                      className="px-3 mr-2 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 "
                    >
                      <img src={pencil} alt="" />
                    </button>
                    <button
                      onClick={deleteCurrentData}
                      style={{ borderRadius: "5px" }}
                      className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2"
                    >
                      <img src={trash} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
              <CarrierScroller
                onSelection={onSelection}
                viewItemDetails={viewItemDetails}
              ></CarrierScroller>

              <section className="w-full z-50  ">
                <section className=" ml-[2vw] mt-2 max-auto max-w-6xl text-filterTextColor">
                  {/* Package page  */}
                  <div className="row mt-5 border-2 p-5" >
                    <div className="col" >
                    <ul class="list-group">
                      <li class="list pb-3"> Ref. No <span className="font-bold ml-4" >{historyDetails?.Ref_No} </span> </li>
                      <li class="list pb-3">Date  <span className="font-bold ml-4" >{historyDetails?.Return_Date_String} </span> </li>
                      <li class="list pb-3">Name  <span className="font-bold ml-4" >{historyDetails?.Customer_Full_Name} </span> </li>
                      <li class="list pb-3">Email  <span className="font-bold ml-4" >{historyDetails?.Customer_Email_Address} </span> </li>
                      <li class="list pb-3">Mobile  <span className="font-bold ml-4" >{historyDetails?.Customer_Mobile_No} </span> </li>
                  
                    </ul>
                        
                    </div>
                    <div className="col" >

                      <h2 className=" h1 text-center mt-4  mb-4 font-bold" >Quantity</h2>
                      <h2 className=" h1 text-center mt-4  mb-4 font-bold" >{historyDetails?.Bag_Quantity} </h2>

                    </div>

                  </div>
                </section>
              </section>
            </section>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default CarrierDetailsPage;
