import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {  message, Upload } from 'antd';
import { itemsService } from '../../../services/items.service';
import {  GetItemImageToEdit, SetItemImageToEdit } from '../../../store/itemAtom';
import { BASE_WIDTH, BASE_HEIGHT } from '../../../helpers/constantsFile';



const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return;

    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      return ;
    }
    return isJpgOrPng && isLt2M;
  };



function PrimaryImageUpload(props) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
  const getItemImageToEdit = GetItemImageToEdit();
  const setItemImageToEdit = SetItemImageToEdit();

  useEffect(()=> {
    if(props?.defaultImage){
      setImageUrl(props.defaultImage);
    }
  },[props?.defaultImage]);



  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
  
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
  
        img.onerror = reject;
      };
  
      reader.onerror = reject;
    });
  };
  

    
    const customUpload = async  ({ file, onSuccess, onError }) => {
       await beforeUpload(file); // check file size and type if valid
        getImageDimensions(file).then((dim)=> {
          if(dim?.height !== BASE_HEIGHT && dim.width !== BASE_WIDTH){
          message.error(`REJECTED! The image dimension should be ${BASE_HEIGHT}H and ${BASE_WIDTH}W`);
          return;
          }else{
            // image is good for upload
            message.info('Uploading image...');
            const formData = new FormData();
            formData.append('file', file);
        const formObject = {Item_Id: props?.Item_Id, image_id: props?.imageId || 0 ,  option: props?.imageId ? 4 : 1}
    formData.append('formObject', JSON.stringify(formObject));
  
    itemsService.submitItemImage(formData).then(res => {
      onSuccess(res.data);
      const newUrl =  res.data?.file_link;
    message.success('Upload successful');
      setImageUrl(newUrl);
      const newList =  getItemImageToEdit.map((img)=> {
        if(img.Image_Id === props?.Item_Id){
          return {...img, Image_URL : newUrl}
        }else{
          return img;
        }
      });
      setItemImageToEdit(newList);
      })
      .catch(err => {
        onError(err);
        setLoading(false);
        window.alert('Image upload failed!');
      }) }

        }).catch((err)=> {
          console.log('dim_error ', err);
        })

     
      
      
    };


  const uploadButton = (
    <button  style={{  border: 0,  background: 'none'  }}type="button" >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{  marginTop: 8,  }}  >
        Upload
      </div>
    </button>
  );

    return ( 
        <>
           <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
       
        // onChange={onFileChange}
        // beforeUpload={beforeUpload}
        customRequest={customUpload}  // Use manual upload
        multiple={false}
      >
        {imageUrl ? (
          <>
          <img   src={imageUrl}  alt="avatar"   style={{   width: '100%',  }}  />
         
          </>
          
        ) : (    uploadButton  )}
      </Upload>

      
        </>
     );
}

export default PrimaryImageUpload;