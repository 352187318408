/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useState } from "react";
import AddCustomerInfoForm from "./AddCustomerInfoForm";
import AddCustomerAddress from "./AddCustomerAddress";
import { customerService } from "../services/customer.service";
import { useContext } from "react";
import LogicContext from "../Context/LogicContext";
import { useEffect } from "react";
import AddCustomerLocation from "./AddCustomerLocation";
import AddCustomerOtherInfo from "./AddOcustomertherInfo";
import {  useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { editCustomerAtom } from "../store/customerAtom";
import CustomerContext from "../Context/CustomerContext";

const FORM_SECTION = {
  GENERAL_INFO: "GENERAL_INFO",
  ADDRESS: "ADDRESS",
  LOCATION: "LOCATION",
  OTHERS: "OTHERS",
};


function AddCustomerPage() {
  const logicContext = useContext(LogicContext);
  var customerToEdit = useRecoilValue(editCustomerAtom);

  const [formSection, setFormSection] = useState(FORM_SECTION.GENERAL_INFO);
  const [newCustomerId, setnewCustomerId] = useState(undefined);
  const [masterData, setMasterData] = useState({});
  const navigate = useNavigate();
  const formData = new FormData();


  const selectSection = (section) => {
    setFormSection(section);
  };


  const selectedCustomerImage = (imageFile) => {
      formData.append('file', imageFile);
  }


  useEffect(() => {
    let isMounted = true;
    if (isMounted){
      customerService.getMasterData().then((res) => { 
        setMasterData(res?.data) 
      });
    }

    return ()=> {
      isMounted = false;
    }
  
  }, []);


  const { pathname } = useLocation(); 
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  

  const submitGeneralInfo = (form) => {
    for (const [key, value ]  of Object.entries(form)){
      formData.set(key, value)
    }


    if (customerToEdit?.Customer_Id) {     // update customer since ID exist.
      form.customer_id = customerToEdit?.Customer_Id;
      formData.append('customer_id',customerToEdit?.Customer_Id )

      customerService.updateCustomer(formData).then((res) => {
          setnewCustomerId(res.data["message_ref_no"]);
          logicContext.showToast(res.data["message_text"], "success");
          setFormSection(FORM_SECTION.ADDRESS);
      
      }).catch(err => {
        logicContext.showToast('Error saving record!', "error");
      })

    } else {
      // Save record as  new cutomer
      customerService.saveGeneralInformation(formData).then((res) => {
          const data = logicContext.httpResponseManager(res.data);
          if(data?.message_id === 1){
            setnewCustomerId(res.data["message_ref_no"]);
            setFormSection(FORM_SECTION.ADDRESS);
          
            logicContext.showToast(data?.message_text, 'success');
          }
          formData.append('customer_id', newCustomerId);
          customerService.submitCustomerImage(formData).then(res => {
          });


      }).catch(err => {
        logicContext.showToast('Error saving record!', "error");
      })
    }
  };



  const submitCustomerImage =(formData) => {
    customerService.submitCustomerImage(formData).then(res => {
    }).catch(err => {
      console.info(err);
    })
  }


  const submitAddress = (formData) => {
    if (customerToEdit?.Customer_Id) {
      formData.customer_id = customerToEdit?.Customer_Id;
    } else {
      formData.customer_id = newCustomerId;
    }
    customerService.saveCustomerAddress(formData).then((res) => {
      const {result_status} =  res.data;
      if (result_status[0]["message_id"] === 1) {
        logicContext.showToast(result_status[0]["message_text"], "success");
        setFormSection(FORM_SECTION.LOCATION);
      } else {
        logicContext.showToast(res.data["msg"], "error");
      }
    });
  };


  const submitLocation = (formData) => {
    if (customerToEdit?.Customer_Id) {
      formData.customer_id = customerToEdit?.Customer_Id;
    } else {
      formData.customer_id = newCustomerId;
    }
    
    customerService.saveCustomerLocation(formData).then((res) => {
      if (res.data["message_id"] === 1) {
        setnewCustomerId(res.data["message_ref_no"]);
        logicContext.showToast(res.data["message_text"], "success");
        setFormSection(FORM_SECTION.OTHERS);
      } else {
        logicContext.showToast(res.data["msg"], "error");
      }
    });
  };



  
  const submitOtherInfomation = (formData) => {
        let newForm =  {...formData}
    if (customerToEdit?.Customer_Id) {
      newForm = {...formData,customer_id: customerToEdit?.Customer_Id };
    } else {
      newForm = {...formData,customer_id: newCustomerId };
    }
    customerService.saveCustomerOtherInfo(newForm).then((res) => {
      if (res.data["message_id"] === 1) {
        setnewCustomerId(res.data["message_ref_no"]);
        logicContext.showToast(res.data["message_text"], "success");
        setFormSection(FORM_SECTION.GENERAL_INFO);
        navigate(-1);
      } else {
        logicContext.showToast(res.data["msg"], "error");
      }
    });
  };

  return (
  <>
    <section>
      <section className="   ">
        <div className=" mt-[5vh]   flex flex-wrap justify-start  w-full max-w-6xl max-auto md:   lg: ">
          <div className="  md:mt-2">
            <span
              className={
                "  rounded-lg  px-4 py-2 mx-2 " + (formSection === FORM_SECTION.GENERAL_INFO ? "bg-blue-500 " : "bg-neutral-300")}
              role="button"
              onClick={() => selectSection(FORM_SECTION.GENERAL_INFO)}
            >
              General Info
            </span>

            {newCustomerId || customerToEdit?.Customer_Id ? (
              <span
              className={
                " rounded-lg px-4 py-2 mx-2 " + (formSection === FORM_SECTION.ADDRESS ? "bg-blue-500 " : "bg-neutral-300")}
                role="button"
                onClick={() => selectSection(FORM_SECTION.ADDRESS)}
              >
                Address
              </span>
            ) : null}
             
          </div>
         

          <div className="mt-2 ">
            {newCustomerId || customerToEdit?.Customer_Id ? (
              <span
                href="#"
                className={
                  "  rounded-lg  px-4 py-2 mx-2 " + (formSection === FORM_SECTION.LOCATION ? "bg-blue-500 " : "bg-neutral-300")}
                role="button"
                onClick={() => selectSection(FORM_SECTION.LOCATION)}
              >
                GPS info
              </span>
            ) : null}


            {newCustomerId || customerToEdit?.Customer_Id ? (
              <span
                href="#"
                className={
                  "  rounded-lg  px-4 py-2 mx-2 " + (formSection === FORM_SECTION.OTHERS ? "bg-blue-500 " : "bg-neutral-300")}
                role="button"
                onClick={() => selectSection(FORM_SECTION.OTHERS)}
              >
                Other
              </span>
            ) : null}
          </div>
        </div>
      </section>

      {formSection === FORM_SECTION.GENERAL_INFO ? (
        <AddCustomerInfoForm selectedCustomerImage={selectedCustomerImage}  
         masterData={masterData} submitSection={submitGeneralInfo} submitCustomerImage={submitCustomerImage} />
      ) : null}

      {formSection === FORM_SECTION.ADDRESS ? (
        <AddCustomerAddress
          masterData={masterData}
          submitSection={submitAddress}
        />
      ) : null}

      {formSection === FORM_SECTION.LOCATION ? (
        <AddCustomerLocation
          masterData={masterData}
          submitSection={submitLocation}
        />
      ) : null}

      {formSection === FORM_SECTION.OTHERS ? (
        <AddCustomerOtherInfo
          masterData={masterData}
          submitSection={submitOtherInfomation}
        />
      ) : null}
    </section>
     {/* <LastSection></LastSection>  */}
  </>

  );
}

export default AddCustomerPage;
