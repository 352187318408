import React, { useEffect } from "react";
import "../../pages/Image.css";

import imageDisplay from "../../assets/imageD.png";
import CurrencyFormat from 'react-currency-format';

import { itemsService } from "../../services/items.service";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import SubscriptionChangeDate from "./SubcriptionChangeDate";
import  moment from 'moment';
import SubscriptionGiftDetails from "./SubscriptionGiftDetails";
import SubManageContact from "./SubManageContact";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { formatCustomDate } from "../../helpers/formatCustomDate";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function SubOverview(props) {
  const { subDetails, itemList} = props;


  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


 
  
  useEffect(() => {}, [props?.subDetails?.Subscription_Ref_No]);




  function viewSalesOrder(subscription){
    if(subDetails?.Sales_Order_Ref_No){
      navigate(`/${currModuleId}/${currModuleName}/${'sales-order'}/details/${subDetails?.Sales_Order_Ref_No}`);
    }
   


  }


  const getSubTotal = (list) => {
    let temp = 0;
    list.forEach((product) => {
        temp += product.rate * product.quantity

    });
    return temp;
}

  return (
    <>
   <div className="p-4 space-y-6">
      {/* Profile Section */}
      <section className="border rounded-lg p-6 bg-white shadow-md">
        <Grid container spacing={4}>
          {/* Customer Details */}
          <Grid item xs={12} md={6}>
            <div className="flex flex-col items-start space-y-4">
              <img src={imageDisplay} alt="Profile" className="w-24 h-24 rounded-full" />
              <div>
                <p><span className="font-medium">Name:</span> <span className="font-bold">{subDetails?.customer_full_name}</span></p>
                <p><span className="font-medium">Email:</span> <span className="font-bold">{subDetails?.customer_email_address}</span></p>
                <p><span className="font-medium">Mobile:</span> <span className="font-bold">{subDetails?.customer_mobile_no}</span></p>
                <p>
                  <span className="font-medium">Subscription Status:</span>
                  <span className={`ml-2 px-3 py-1 rounded-lg text-sm font-bold ${subDetails?.subscription_status_id === 2 ? 'bg-gray-200 text-gray-700' : 'bg-green-100 text-green-600'}`}>
                    {subDetails?.subscription_status_name}
                  </span>
                </p>
              </div>
            </div>
          </Grid>
          {/* Subscription Details */}
          <Grid item xs={12} md={6}>
            <div className="text-right space-y-2">
              <h3 className="text-blue-600 font-bold">Subscription Details</h3>
              <p><span className="font-medium">Subscription ID:</span> {subDetails?.subscription_ref_no}</p>
              <p><span className="font-medium">Subscription No:</span> {subDetails?.subscription_no}</p>
              <p><span className="font-medium">Sales Person:</span> {subDetails?.gift_name}</p>
              <p><span className="font-medium">Created by:</span> {subDetails?.encoded_by_name}</p>
              {subDetails?.gift_id === 1  ?
              <SubscriptionGiftDetails giftPerson={subDetails} /> : null
              }
              
            </div>
          </Grid>
        </Grid>
      </section>
      
      {/* Billing and Shipping Address */}
      <section className="border rounded-lg p-6 bg-white shadow-md">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <h3 className="text-blue-600 font-bold">Billing Address</h3>
            <p>Country: <span className="font-bold">{subDetails?.billing_city_name}</span></p>
            <p>State: <span className="font-bold">{subDetails?.billing_country_name}</span></p>
            <p>Address: <span className="font-bold">{subDetails?.billing_address1}</span></p>
          </Grid>
          <Grid item xs={12} md={4}>
            <h3 className="text-blue-600 font-bold">Shipping Address</h3>
            <p>Country: <span className="font-bold">{subDetails?.shipping_country_name}</span></p>
            <p>State: <span className="font-bold">{subDetails?.shipping_city_name}</span></p>
            <p>Address: <span className="font-bold">{subDetails?.shipping_address1}</span></p>
          </Grid>
          <Grid item xs={12} md={4}>
            <h3 className="text-blue-600 font-bold">Associated Contacts</h3>
            <p>noreply@grocedy.com</p>
            <SubManageContact contactsList={subDetails?.contacts} />
          </Grid>
        </Grid>
      </section>

      {/* Subscription Payment Details */}
      <section className="border rounded-lg p-6 bg-white shadow-md">
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <p>Payment Term: <span className="font-bold">{subDetails?.payment_term_name}</span></p>
          </Grid>
          <Grid item xs={6} className="text-right">
            <p>Subscription Start Date: <span className="font-bold">{formatCustomDate(subDetails?.subscription_activated_date_string)}</span></p>
          </Grid>
        </Grid>
      </section>

      {/* Subscription Amount and Billing */}
      <section className="border rounded-lg p-6 bg-white shadow-md">
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <p className="text-lg font-bold">Subscription Amount</p>
            <p className="text-xl font-bold">₦{formatCustomNumber(subDetails?.subscription_amount)}</p>
          </Grid>
          <Grid item xs={4}>
            <p className="text-lg font-bold">Next Billing Date</p>
            <p className="text-xl font-bold">{formatCustomDate(subDetails?.next_bill_on)}</p>
            <SubscriptionChangeDate updateBillingDate={props.updateBillingDate} />
          </Grid>
          <Grid item xs={4}>
            <p className="text-lg font-bold">Last Billing Date</p>
            <p className="text-xl font-bold">{formatCustomDate(subDetails?.last_billed_on)}</p>
          </Grid>
        </Grid>
      </section>

      {/* Plan & Addon Details */}
      <section className="border rounded-lg p-6 bg-white shadow-md">
        <table className="w-full text-left">
          <thead className="bg-gray-200">
            <tr>
              <th>Plan & Addon Details</th>
              <th>Recurring Cycle</th>
              <th>Qty</th>
              <th>Rate</th>
              <th>Tax</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {itemList?.map((product, i) => (
              <tr key={i} className="border-b cursor-pointer" onClick={() => viewSalesOrder(product)}>
                <td className="font-bold">{product?.product_feature_name}</td>
                <td>{product?.recuring_cycle}</td>
                <td>{product?.quantity}</td>
                <td>₦{formatCustomNumber(product?.rate)}</td>
                <td>{product?.Tax_Id}</td>
                <td>₦{formatCustomNumber(product?.total_amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end mt-4 text-right">
          <ol>
            <li className="font-bold"> SubTotal: ₦{formatCustomNumber(getSubTotal(itemList))} </li>
            <li className="font-bold"> Coupon Discount: ₦{formatCustomNumber(subDetails?.discount_amount)} </li>
            <li className="font-bold"> Net: ₦{formatCustomNumber(getSubTotal(itemList) - subDetails?.discount_amount)} </li>
          </ol>
        </div>
      </section>
    </div>
    </>
  );
}

export default SubOverview;
