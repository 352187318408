import { useEffect, useState } from "react";
import Select from 'react-select';
import "../Notification/Noti.css"



function NotificationSelect(props) {
  
  const [tempDefault, setTempDefault] = useState({});

  const customOption = props?.dataList?.map((item, i)=> {
 
    return ({...item, label:`${item.name}`})
  });
  if(customOption?.length){
  }


  
  
  useEffect(()=> {
    
    setTempDefault(props?.dataList[0]);
  },[props?.dataList?.length, props?.dataList, props]);

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 53,
      backgroundColor: '#F1F1F1',
      // width: 520,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),

  
    
  }

 
    return ( 
        <>
   
         <Select 
        //  defaultValue={props?.dataList?.length ? {...props?.dataList[0], label: props?.dataList[0]?.name}  : {}} 
         defaultValue={ {label: props?.dataList[0]?.name, ...tempDefault}} 
         onChange={(event)=> props?.onSearchSelect(event)} 
          placeholder={"Select filter type..."}   
          options={customOption}
         />


        </>
     );
}

export default NotificationSelect;
