import React, { useCallback, useContext, useRef } from "react";

import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import "./SubscriptionDetails.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";

import SubOverview from "./SubOverview";
import { subscriptionService } from "../../services/subscription.service";
import { SetSubScriptionEdit } from "../../store/subscriptionAtom";
import LogicContext from "../../Context/LogicContext";
import SubDetailsScroll from "./SubDetailsScroll";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { SetSubList, GetSubList, GetSubMasterData } from "../../store/subscriptionAtom";
import SubscriptionInvoiceHistory from "./SubscriptionInvoiceHistory";
import SubscriptionSalesOrder from "./SubsciptionSalesOrder";
import { spinnerService } from "../../services/spinner.service";



function SubscriptionDetailsPage() {

  const [subDetails, setSubDetails] = useState({});
  const [invoiceList, setSubInvoice] = useState([]);
  const [salesOrderList, setSalesOrder] = useState([]);
  const logicContext = useContext(LogicContext);
  const myPromiseModal = createModal(GeneralModal);
  const setSubscriptionEdit = SetSubScriptionEdit();
  const [bulkActionData, setBulkActionData] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const masterData = GetSubMasterData();
  const [tabValue, setTabValue] = React.useState('1');
  const subData = GetSubList();
  const setSubData = SetSubList();

  const setSubList = SetSubList();


  const { sub_id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const mountRef = useRef(0)




  useEffect(() => {
    document.title = 'Subscription Details'
    getSubDetails(sub_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub_id]);



  const getSubDetails = async(id) => {
    // setPlanAddonCouponList([]);
    spinnerService.showSpinner();
    subscriptionService.getSubscription(id).then((res) => {
        const serverData = res.data;
        if(serverData?.subscription_info){
          setSubDetails(serverData?.subscription_info[0]);
        }

          if(serverData?.invoice_info?.length){
            setSubInvoice(serverData?.invoice_info);
        }else{
          setSubInvoice([]);
        }
          if(serverData?.salesorder_info?.length){
            setSalesOrder(serverData?.salesorder_info);
        }else{
          setSalesOrder([]);
        }

   if(serverData?.subscription_item_info){
    setItemList(serverData?.subscription_item_info);
        }
      spinnerService.disableSpinner();

      }).catch((err) => {
      console.log('Err ', err);
    });
  }


  useEffect(() => {
    mountRef.current = true;
    if (masterData[4] !== undefined) {
      setBulkActionData(masterData[4]);
    }
    return () => {
      mountRef.current = false;
    }
  }, [sub_id]);



  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`);
    getSubDetails(id);
  }


  useEffect(()=> {
    // This is to load main table
    if (subData.subscription_info?.length < 1) {
    spinnerService.showSpinner();

      subscriptionService.getSubList().then(res => {
        
        if(res?.data){
          setSubData(res?.data);
          
        }
        spinnerService.disableSpinner();
    });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[subData.subscription_info]);



  const editSubscription = async () => {


    await myPromiseModal({ title: "Confirmation", body: " Do you want to modify this subscription?",
    showInput:false, placeHolder:'' }).then(res => {
      if (res.decision === true) {
        setSubscriptionEdit({  details: subDetails, itemList:itemList });
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);

      }
    });
  };



  const addNewSubscription = async () => {
    const response = await myPromiseModal({
      title: "Add subscription", body: " Do you what to add subscription?",showInput:false, placeHolder:''
    });

    if (response.decision === true) {
      navigate(`/${currModuleId}/${currModuleName}/${"subscriptions"}/add-new?Customer_Id=${subDetails?.Customer_Id}&Customer_Full_Name=${subDetails?.Customer_Full_Name}`);

    }
  };


  async function bulkActions(masterOption) {
    await myPromiseModal({ title: masterOption?.Name, body: `Do you want to set ${masterOption?.Name} for selected items?`,
    showInput:false, placeHolder:'' }).then(result => {
      if (result.response === true) {
        var xmlString = "";
        selectedList.map((sub, i) => {
          xmlString += `<refno>${sub.Subscription_Ref_No} </refno>`;
        });
    spinnerService.showSpinner();
        subscriptionService.submitBulkAction({
          option: masterOption.Parent_Name1,
          xml_string_subs: `<root><a> ${xmlString} </a> </root>`
        }).then((res) => {
    spinnerService.disableSpinner();
          const { message_text, message_id } = res?.data[0]
          if (res.data !== undefined && message_id == 1) {
            logicContext.showToast(message_text, 'success')
            getSubDetails(sub_id);
          } else {
            logicContext.showToast('Error processing request!', 'error')
          }
        })
      }
    });
  }


  function onSelection(list) {
    let tempList = list?.filter(item => item.checked === true);
    setSelectedList(tempList)
  }


  async function onSingleAction(Ref_no, option) {
    let title = 'Confirmation', body = 'Please confirm your action!';
    if (option === 10) {
      title = 'Confirm delete';
      body = 'Please ensure you know what are you doing. Deleted records can not be recovered.'
      
    }


    await myPromiseModal({ title, body ,showInput:false, placeHolder:''}).then(response => {
      if (response.decision === true) {
    spinnerService.showSpinner();
        subscriptionService.submitBulkAction({
          option: option,
          xml_string_subs: `<root><a> <refno>${Ref_no} </refno> </a> </root>`
        }).then((res) => {
    spinnerService.disableSpinner();
          const { message_text, message_id } = res?.data[0]
          if (res.data !== undefined && message_id == 1) {
            // loadItemsFromServer();
            if (option === 10) {
              loadItemsFromServer(option);

            } else {

              getSubDetails(Ref_no);
              // loadItemsFromServer(option);
            }
            logicContext.showToast(message_text, 'success')
          } else {
            logicContext.showToast('Error processing request!', 'error')
          }
        })
      }
    });


    function loadItemsFromServer(option) {
    spinnerService.showSpinner();
      subscriptionService.getSubList().then(res => {
        if (res?.data !== undefined) {
          setSubList(res?.data);
          if (option == 10) {
            navigate(-1)
          }
        }
        spinnerService.disableSpinner();
      });
    }


  }

  function updateBillingDate(newDate) {
    if (newDate) {
      let body = { option: 11, Next_Billed_Date: newDate, Subscription_Ref_No: sub_id };
    spinnerService.showSpinner();
      subscriptionService.updateSubBillingDate(body).then(response => {
        const { message_text, message_id } = response.data[0];
        if (message_id === 1) {
          logicContext.showToast(message_text, 'success');
          getSubDetails(sub_id);
        }
        
        spinnerService.disableSpinner();
      });

    }
  }


  return (
    <>
      <section className="mt-2 ">
        <section className=" ">
          <div className="flex w-full   flex-wrap justify-between md:">

            {/*  start */}
            <div className="row">
              <div className="col col-md-4">
                {(selectedList?.length > 0) &&
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        backgroundColor: "#E5E5E5",
                        border: "none", color: "#45444B",
                        fontSize: "12px", height: "45px"
                      }}
                      id="dropdown-basic">
                      Bulk Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {bulkActionData?.map((option, i) => {
                        return (
                          <Dropdown.Item key={i} onClick={() => bulkActions(option)} >
                            {option?.Name}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>

                }

              </div>
            </div>
            {/* end */}

            {/* ADD NEW TRANSACTION SECTION */}
            <div className="flex ">
              {/* paper, edit and pencil section */}

              <div className="flex justify-end ">
                <button
                  onClick={addNewSubscription}
                  style={{ backgroundColor: "#476EF8" }}
                  className=" h-[6vh]  rounded text-white text-xs py-1 mr-2 px-4"
                >
                  NEW SUBSCRIPTION
                </button>

                <button
                  style={{ borderRadius: "5px" }}
                  className="  px-4  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 "
                >
                  <img src={paper} alt="" />
                </button>

                <button
                  style={{ borderRadius: "5px" }}
                  onClick={editSubscription}
                  className=" mx-2 px-4  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
                >
                  <img src={pencil} alt="" />
                </button>

                <Dropdown className="ml-2 ">
                  <Dropdown.Toggle className=""
                    style={{
                      backgroundColor: "#E5E5E5",
                      border: "none", color: "#45444B",
                      fontSize: "12px", height: "35px", minHeight: "45px"
                    }}
                    id="dropdown-basic">
                    More
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onSingleAction(sub_id, 8)}  >
                      Pause Subscription
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => onSingleAction(sub_id, 9)}  >
                      Resume Subscription
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => onSingleAction(sub_id, 4)}  >
                      Cancel Subscription
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => onSingleAction(sub_id, 10)}  >
                      Delete Subscription
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>

            </div>
          </div>
        </section>

        <section className="flex relative  justify-between ">
          <SubDetailsScroll viewItemDetails={viewItemDetails}
            subDetails={subDetails} onSelection={onSelection} />


          {/* start tab */}
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
              <Box className=" ml-4 mt-2" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  <Tab label="Overview" value="1" />

                
                    <Tab label={"Invoice History"} value="2" />
                  

                  <Tab label={`Sales Orders`} value="3" />

                  <Tab label={`Recent Activities`} value="4" />

                </TabList>
              </Box>
              <TabPanel value="1">
                <SubOverview
                  viewCustomer={viewItemDetails}
                  subDetails={subDetails}
                  itemList={itemList}
                  // planAddonCouponList={planAddonCouponList}
                  updateBillingDate={updateBillingDate} />

              </TabPanel>
              <TabPanel value="2">
                <SubscriptionInvoiceHistory invoiceList={invoiceList} />
              </TabPanel>

              <TabPanel value="3">
                <SubscriptionSalesOrder salesOrderList={salesOrderList} />

              </TabPanel>

              <TabPanel value="4">

              </TabPanel>
            </TabContext>
          </Box>
          {/* end tab */}
        </section>
      </section>
    </>

  );
}

export default SubscriptionDetailsPage;
