import React, { useEffect, useState, useContext, useRef } from "react";
// import "../../pages/Plan.css";
import { salesService } from "../../services/sales.service";
import iconImage from "../../assets/Icon.svg";
import CurrencyFormat from "react-currency-format";

import LogicContext from "../../Context/LogicContext";
import { imageService } from "../../services/image.service";

import {
  GetEntryData,
  SetEntryData,
  SetSalesOrderList,
  GetEditSalesOrder,
  GetSalesOrderList,
  ResetEditSalesOrder,
} from "../../store/salesOrderAtom";

import { useForm } from "react-hook-form";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import Grid from "@mui/material/Grid";

import { useSearchParams } from "react-router-dom";
import SalesOrderDiscountType from "./SalesOrderDiscountType";
import AddSalesOrderCustomer from "./AddSalesOrderCustomer";
import AddSalesOrderTable from "./AddSalesOrderTable";
import SalesOrderRate from "./SalesOrderRate";
import SalesOrderPaymentTerms from "./SalesOrderPaymentTerms";
import SalesOrderDeliveryMethod from "./SalesOrderDeliveryMethod";
import SalesOrderWarehouseName from "./SalesOrderWareHouseName";
import SalesOrderSalesPerson from "./SalesOrderSalesPerson";
import AddSalesOrderInvoiceTable from "./AddSalesOrderInvoiceTable";
import GeneralFileUploadPreview from "../../components/GeneralFileUploadPreview";

function AddSalesOrderPage() {
  const setEntryData = SetEntryData();
  const entryMasterData = GetEntryData();
  const editableSalesOrder = GetEditSalesOrder();
  const resetEdit = ResetEditSalesOrder();

  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const logicContext = useContext(LogicContext);
  const [selectedSalesList, setPurchaseList] = useState([]);
  const [selectedInvoiceList, setInvoiceList] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState(null);

  const [discountValue, setDiscountValue] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const [adjustmentValue, setAdjustmentValue] = useState(0);
  const [disCountTypeID, setDiscountTypeID] = useState(1);

  const mountRef = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const customVendorId = searchParams.get("vendor_id");
  const customVendorName = searchParams.get("vendor_name");

  useEffect(() => {
    mountRef.current = true;
    if (entryMasterData["Discount_Type"] === undefined) {
      salesService.getEntrySalesOrderData().then((res) => {
        if (res?.data !== undefined) {
          setEntryData(res.data);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customVendorId !== null) {
      setValue("Vendor_Id", customVendorId);
      setValue("Discount_Type_Id", 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customVendorId]);

  useEffect(() => {
    document.title = "New Sales Order";

    if (editableSalesOrder?.Sales_Order_Ref_No) {
      document.title = "Modify Sales Order";
      setValue("Customer_Id", editableSalesOrder?.Customer_Id);
      setValue("Delivery_Method_Id", editableSalesOrder?.Delivery_Method_Id);
      setValue("Customer_Notes", editableSalesOrder?.Customer_Notes);
      setValue("Sales_Order_Ref_No", editableSalesOrder?.Sales_Order_Ref_No);
      setValue("Subscription_Ref_No", editableSalesOrder?.Subscription_Ref_No);
      setValue("Payment_Term_Id", editableSalesOrder?.Payment_Term_Id);
      setValue("Discount_Type_Id", editableSalesOrder?.Discount_Type_Id);
      setValue("discount_value", editableSalesOrder?.Discount_Amount);
      setValue("Sales_Person_Id", editableSalesOrder?.Sales_Person_Id);
      setValue("Warehouse_Id", editableSalesOrder?.Warehouse_Id);
      setValue(
        "attachment_url",
        editableSalesOrder?.Sales_Order_Attachement_URL
      );
      setValue("Order_Remark", editableSalesOrder?.Order_Remark);

      setDiscountValue(editableSalesOrder?.Discount_Amount);
      setShippingFee(editableSalesOrder?.Shipping_Amount);
      setAdjustmentValue(editableSalesOrder?.Adjustment_Amount);
      setDiscountTypeID(editableSalesOrder?.Discount_Type_Id);
      // if(editableSalesOrder?.Attachments?.length > 0){
      //   reMapImageUri(editableSalesOrder?.Attachments)
      // }
      // set item list
    }

    return () => {
      mountRef.current = false;
      resetEdit();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableSalesOrder]);

  const onCustomerSelect = (customer) => {
    setValue("Customer_Id", customer.Customer_Id);
  };

  function onPaymentTermsSelect(val) {
    setValue("Payment_Term_Id", val.Payment_Term_Id);
  }

  function onDeliveryMethodSelect(val) {
    setValue("Delivery_Method_Id", val.Delivery_Method_Id);
  }

  function onWareHouseSelect(val) {
    setValue("Warehouse_Id", val.Warehouse_Id);
  }

  function onSalesPersonSelect(val) {
    setValue("Sales_Person_Id", val.User_Id);
  }

  const onSubmit = async (option) => {
    const salesForm = getValues();

    //  validate
    if (!salesForm?.Customer_Id) {
      return logicContext.showToast("Please select a customer", "info");
    }
    //  validate
    if (!salesForm?.Delivery_Method_Id) {
      return logicContext.showToast("Please select delivery method", "info");
    }
    //  validate

    if (selectedSalesList.length === 0) {
      return logicContext.showToast(
        "at least one or more item must be selected!",
        "error"
      );
    }

    var tempStringList = selectedSalesList?.map((item) => {
      let temp = `<a> <item_id>${item.Item_Id}  </item_id>
          <item_quantity>${item?.Item_Sales_Quantity} </item_quantity>
          <item_rate>${item?.Item_Sales_Rate} </item_rate>
          <tax_id>${item?.Item_Tax_Id ? item?.Item_Tax_Id : 0} </tax_id>
          <account_id>${item.Account_Id ? item.Account_Id : 1} </account_id>
            </a>`;
      return temp;
    });

    var tempInvoiceList = selectedInvoiceList?.map((invoice) => {
      let temp = `<a> <invoice_ref_no>${invoice.Invoice_Ref_No}  </invoice_ref_no>
            </a>`;
      return temp;
    });

    await myPromiseModal({
      title: "Confirmation",
      body: " Do you want to save this record?",
      showInput: false,
      placeHolder: "",
    })
      .then((res) => {
        if (res.decision === true) {
          let option = 2;
          const newData = getValues();
          if (editableSalesOrder?.Sales_Order_Ref_No) {
            option = 3;
          }
          // attachedFile
          const formData = new FormData();
          for (const keyData in newData) {
            formData.set(keyData, newData[keyData] ? newData[keyData] : 0);
          }
          formData.set("option", option);
          formData.set("xml_string_item", `<root> ${tempStringList} </root>`);
          formData.set(
            "xml_string_invoice",
            `<root> ${tempInvoiceList} </root>`
          );
          formData.set("discount_value", discountValue);
          formData.set("shipping_charge", shippingFee);
          formData.set("adjustment_amount", adjustmentValue);
          if (attachmentFile) {
            formData.set("file", attachmentFile);
          }

          salesService.submitSalesOrder(formData).then((res) => {
            if (res?.data !== undefined) {
              const { message_text, message_id } = res.data[0];
              if (message_id === 1) {
                logicContext.showToast(message_text, "success");
                sessionStorage.setItem("reloadPage", "true");
                navigate(-1);
              } else {
                return logicContext.showToast(message_text, "error");
              }
            }
          });
        }
      })
      .catch((err) => {
        logicContext.showToast("error submitting record", "error");
      })
      .catch((err) => {
        logicContext.showToast(
          "error submitting form, please try again",
          "error"
        );
      });
  };

  function onErrors(error) {
    logicContext.showToast("Fill in the required information!", "error");
  }

  function onFileSelected(file) {
    setAttachmentFile(file);
  }

  function OrderDate() {
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
    if (editableSalesOrder?.Order_Date !== undefined) {
      defaultDate = moment(editableSalesOrder?.Order_Date).format("YYYY-MM-DD");
    }

    return (
      <div className="">
        <input
          defaultValue={defaultDate}
          key={defaultDate}
          className="bg-white pl-3 rounded w-full bottom-0 outline-none border-transparent focus:border-transparent ring-1 ring-gray-200"
          type="date"
          {...register("Order_Date", {
            required: "expected delivery date is required",
          })}
        />
        <small className="text-danger">
          {errors?.Order_Date && errors.Order_Date.message}
        </small>
      </div>
    );
  }

  function pickupDate() {
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
    if (editableSalesOrder?.Pickup_Date !== undefined) {
      defaultDate = moment(editableSalesOrder?.Pickup_Date).format(
        "YYYY-MM-DD"
      );
    }

    return (
      <div>
        <input
          defaultValue={defaultDate}
          key={defaultDate}
          className="-mt-1 h-11 bg-white text-sm rounded-md placeholder:text-sm placeholder:text-gray-400 border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
          type="date"
          {...register("Pickup_Date", {
            required: "pick up date date is required",
          })}
        />
        <small className="text-danger">
          {errors?.Pickup_Date && errors.Pickup_Date.message}
        </small>
      </div>
    );
  }

  function salesOrderDate() {
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
    if (editableSalesOrder?.Sales_Order_Date !== undefined) {
      defaultDate = moment(editableSalesOrder?.Sales_Order_Date).format(
        "YYYY-MM-DD"
      );
    }

    return (
      <div className="">
        <input
          defaultValue={defaultDate}
          key={defaultDate}
          className="-mt-1 h-11 bg-white text-sm rounded-md placeholder:text-sm placeholder:text-gray-400 border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
          type="date"
          {...register("Sales_Order_Date", {
            required: "sales order date is required",
          })}
        />
        <small className="text-danger">
          {errors?.Sales_Order_Date && errors.Sales_Order_Date.message}
        </small>
      </div>
    );
  }

  const onSelectionList = (tableList) => {
    setPurchaseList(tableList);
  };

  const onInvoiceSelectionList = (tableList) => {
    setInvoiceList(tableList);
  };

  const calculateSubTotal = (list) => {
    let result = 0;
    list?.forEach((element) => {
      result += element.Item_Sales_Quantity * element.Item_Sales_Rate;
    });
    return result;
  };

  const onDiscountTypeIDSelect = (typeId) => {
    setValue("Discount_Type_Id", typeId);
    setDiscountTypeID(Number(typeId));
  };

  const calculateGrandTotal = (list) => {
    let result = 0;
    list?.forEach((element) => {
      result += element.Item_Sales_Quantity * element.Item_Sales_Rate;
    });

    if (disCountTypeID === 2) {
      let discount = (discountValue / 100) * result;
      result = result - discount;
    }

    result = result + shippingFee;
    result += adjustmentValue;
    if (disCountTypeID === 1) {
      result = result - discountValue;
    }

    return result;
  };

  return (
    <>
      <h1 className="text-2xl font-bold text-gray-700">New Sales Order</h1>
      <div className="mt-6 @container">
        <form onSubmit={handleSubmit(onSubmit, onErrors)} className="">
          <div className="grid grid-cols-2 gap-4">
            <div className="mt-2">
              <label className="ml-1 text-sm text-gray-700">
                Select Customer
                <span className="text-red-500 font-bold">*</span>
              </label>
              <div className="-mt-1">
                <AddSalesOrderCustomer
                  {...register("Customer_Id", {
                    required: "customer is required",
                  })}
                  defaultVal={{ label: editableSalesOrder?.Customer_Full_Name }}
                  onCustomerSelect={onCustomerSelect}
                />
              </div>

              <small className="text-danger">
                {errors?.Customer_Id && errors.Customer_Id.message}
              </small>
            </div>
            <div className="mt-2">
              <label className="ml-1 text-sm text-gray-700">
                Sales Order Date
              </label>
              {/*  put date here */}
              <div className="">{salesOrderDate()}</div>
            </div>
            <div className="mt-2">
              <label className="ml-1 text-sm text-gray-700">
                Sales Order Number
              </label>
              <input
                type="number"
                readOnly={true}
                value={editableSalesOrder?.Sales_Order_No}
                className="-mt-1 h-11 bg-white text-sm rounded-md placeholder:text-sm placeholder:text-gray-400 border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
                placeholder="Sales order number"
              />
            </div>
            <div className="mt-2 ml-1 text-sm text-gray-700">
              <label className="text-sm "> Order Date</label>
              <div className="-mt-1">{OrderDate()}</div>
            </div>
            <div className="mt-2">
              <label className="ml-1 text-sm text-gray-700">
                Reference Number
              </label>
              <input
                readOnly={true}
                value={editableSalesOrder?.Sales_Order_Ref_No}
                type="number"
                className="-mt-1 h-11 bg-white text-sm rounded-md placeholder:text-sm placeholder:text-gray-400 border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
                placeholder=""
              />
            </div>
            <div className="mt-2">
              <label className="ml-1 text-sm text-gray-700">
                Payment Terms
                <span className="text-red-500 font-bold">*</span>
              </label>
              <div className="-mt-1">
                <SalesOrderPaymentTerms
                  defaultVal={{ label: editableSalesOrder?.Payment_Term_Name }}
                  onPaymentTermsSelect={onPaymentTermsSelect}
                />
              </div>
            </div>
            <div className="mt-2">
              <label className="ml-1 text-sm text-gray-700">
                Delivery Method
                <span className="text-danger font-bold">*</span>
              </label>
              <div className="-mt-1">
                <SalesOrderDeliveryMethod
                  {...register("Delivery_Method_Id", {
                    required: "Delivery method required",
                  })}
                  defaultVal={{
                    label: editableSalesOrder?.Delivery_Method_Name,
                  }}
                  onDeliveryMethodSelect={onDeliveryMethodSelect}
                />
                <small className="text-danger">
                  {errors?.Delivery_Method_Id &&
                    errors.Delivery_Method_Id.message}
                </small>
              </div>
            </div>
            <div className="mt-2">
              <label className="ml-1 text-sm text-gray-700">
                Subscription Id
              </label>
              <input
                type="number"
                className="-mt-1 h-11 bg-white text-sm rounded-md placeholder:text-sm placeholder:text-gray-400 border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
                placeholder=""
                {...register("Subscription_Ref_No", {
                  // required: "Subscription Id date is required",
                })}
              />
            </div>
            <div className="mt-2">
              <label className="ml-1 text-sm text-gray-700">
                Warehouse Name
                <span className="text-danger font-bold">*</span>
              </label>
              <div className="-mt-1">
                <SalesOrderWarehouseName
                  defaultVal={{ label: editableSalesOrder?.Warehouse_Name }}
                  onWareHouseSelect={onWareHouseSelect}
                />
              </div>
            </div>
            <div className="mt-2">
              <div>
                <label className="ml-1 text-sm text-gray-700">
                  Sales Person
                  <span className="text-danger font-bold">*</span>
                </label>
              </div>
              <div className="-mt-1">
                <SalesOrderSalesPerson
                  defaultVal={{ label: editableSalesOrder?.Sales_Person_Name }}
                  onSalesPersonSelect={onSalesPersonSelect}
                />
              </div>
            </div>
            <div className="mt-2">
              <label className="ml-1 text-sm text-gray-700">
                Pickup Date
                <span className="text-danger font-bold">*</span>
              </label>
              {pickupDate()}
            </div>

            {/* <Grid item xs={6}>
              <span className="text-sm ">Pickup Address</span>
                <span className="text-danger font-bold">*</span>
                <input
                      type="text"
                      className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      {...register("Pickup_Address", {
                        required: "Pickup address is required",
                      })}
                    />
              </Grid> */}
          </div>
          <div className="mt-10">
            <div className="grid grid-cols-1 @xl:grid-cols-2 @xl:gap-4">
              <div className="">
                <AddSalesOrderInvoiceTable
                  defaultList={editableSalesOrder?.Invoice_details}
                  onInvoiceSelectionList={onInvoiceSelectionList}
                />
              </div>
              <div className="">
                <AddSalesOrderTable
                  defaultList={editableSalesOrder?.Sales_Order_details}
                  onSelectionList={onSelectionList}
                />
              </div>
            </div>
            <div className="mt-8 px-8 py-6 bg-white border-[1px] border-gray-200 rounded-2xl max-w-2xl">
              <div className="flex items-end justify-between">
                <div className="text-base font-semibold text-gray-700">
                  Sub Total
                </div>
                <div className="text-[#476ef8] text-xl font-semibold">
                  <CurrencyFormat
                    value={parseFloat(
                      calculateSubTotal(selectedSalesList)
                    )?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </div>
              </div>

              <div className="mt-3 flex items-end justify-between">
                <div className="w-full max-w-[260px] mr-2">
                  <label className="text-sm font-semibold text-gray-600">
                    Discount
                  </label>
                  <div className="relative w-full">
                    <input
                      className="bg-white rounded pl-3 h-[7vh] border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
                      type="number"
                      placeholder=""
                      name="discount_type"
                      key={discountValue}
                      autoFocus="autoFocus"
                      onChange={(e) => setDiscountValue(Number(e.target.value))}
                      defaultValue={discountValue}
                    />
                    <div className="absolute border-l-[1px] border-l-gray-200 top-[1px] bottom-[1px] right-[1px] rounded-r overflow-hidden">
                      <SalesOrderDiscountType
                        defaultVal={editableSalesOrder?.Discount_Type_Id}
                        onDiscountTypeSelect={onDiscountTypeIDSelect}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-[#476ef8] text-xl font-semibold">
                  <CurrencyFormat
                    value={parseFloat(discountValue)?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                  {/* ₦ */}
                </div>
              </div>

              <div className="mt-3 flex items-end justify-between">
                <div className="w-full max-w-[260px] mr-2">
                  <label className="text-sm font-semibold text-gray-600">
                    Shipping Charges <br></br>(
                    <span className="text-[#476EF8] text-xs italic">
                      Apply Tax on shipping Charge
                    </span>
                    )
                  </label>
                  <input
                    className="bg-white rounded pl-3 h-[7vh] border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
                    type="number"
                    name="shipping_amount"
                    defaultValue={shippingFee}
                    autoFocus="autoFocus"
                    onChange={(e) => setShippingFee(Number(e.target.value))}
                    key={shippingFee}
                  />
                </div>
                <div className="text-[#476ef8] text-xl font-semibold">
                  <CurrencyFormat
                    className={"text-xl"}
                    value={parseFloat(shippingFee)?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </div>
              </div>
              <div className="mt-3 flex items-end justify-between">
                <div className="w-full max-w-[260px] mr-2">
                  <label className="text-sm font-semibold text-gray-600">
                    Adjustment
                  </label>
                  <input
                    className="bg-white rounded pl-3 h-[7vh] border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
                    type="number"
                    step="any"
                    defaultValue={adjustmentValue}
                    autoFocus="autoFocus"
                    onChange={(e) => setAdjustmentValue(Number(e.target.value))}
                    key={adjustmentValue}
                    name="adjustment_value"
                  />
                </div>
                <div className="text-[#476ef8] text-xl font-semibold">
                  <CurrencyFormat
                    className={
                      "text-xl " +
                      (adjustmentValue < 0 ? "text-danger" : "text-success")
                    }
                    value={parseFloat(adjustmentValue)?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </div>
              </div>
              <div className="mt-5 flex items-center justify-between">
                <div className="text-xl font-bold text-gray-600">Total (₦)</div>
                <div className=" text-xl rounded-md font-bold px-6 py-2 bg-gray-700">
                  <CurrencyFormat
                    className="text-light "
                    value={parseFloat(
                      calculateGrandTotal(selectedSalesList)
                    )?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10 grid grid-auto-fit-xl gap-6">
              <div className="">
                <label className="ml-1 text-sm text-gray-700">
                  Customer Notes
                </label>
                <textarea
                  rows={4}
                  {...register("Customer_Notes", {})}
                  className="-mt-1 bg-white text-sm rounded-md placeholder:text-sm placeholder:text-gray-400 border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
                  type="text"
                />
              </div>
              <div className="">
                <label className="ml-1 text-sm text-gray-700">
                  Terms and Conditions
                </label>
                <div className="">
                  <textarea
                    rows={4}
                    readOnly={true}
                    type="readOnly"
                    className="-mt-1 bg-white text-sm rounded-md placeholder:text-sm placeholder:text-gray-400 border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
                    placeholder="Enter the terms and conditions of your business to be displayed in your transaction"
                  />
                </div>
              </div>
              <div className="">
                <label className="ml-1 text-sm text-gray-700">
                  Order Remark
                </label>
                <textarea
                  rows={4}
                  placeholder="enter remark"
                  {...register("Order_Remark", {})}
                  className="-mt-1 bg-white text-sm rounded-md placeholder:text-sm placeholder:text-gray-400 border-[1px] border-gray-200 w-full z-10 bottom-0 outline-none"
                  type="text"
                />
              </div>
            </div>
            <div className="mt-8">
              <label className="ml-1 text-sm text-gray-700">Attach File</label>
              <div className="-mt-1">
                <GeneralFileUploadPreview
                  defaultFileUrl={
                    editableSalesOrder?.Sales_Order_Attachement_URL
                  }
                  onFileSelected={onFileSelected}
                />
              </div>
              <i className="mt-2"> maximum file size 2MB </i>
            </div>
            <div>
              <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                <div
                  type="cancel"
                  className="text-sm text-[#F17E7E] bg-white btn btn-danger rounded"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </div>

                <div>
                  <span
                    type="submit"
                    className="text-sm text-[#476EF8] px-3 rounded mr-10 py-2"
                  >
                    Save As Draft
                  </span>
                  <button
                    type="submit"
                    className="text-sm bg-[#476EF8] text-white px-3 rounded py-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddSalesOrderPage;
