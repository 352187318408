import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {  message, Upload } from 'antd';
import { itemsService } from '../../services/items.service';
import { SetGroupImageEdit, GetGroupImageEdit  } from "../../store/itemGroupAtom";
import { BASE_WIDTH, BASE_HEIGHT } from '../../helpers/constantsFile';


const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };



function ItemGroupPrimaryImageUpload(props) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const getGroupImageEdit = GetGroupImageEdit();
    const setGroupImageEdit = SetGroupImageEdit();


  useEffect(()=> {
    if(props?.defaultImage){
      setImageUrl(props.defaultImage);
    }
  },[props?.defaultImage]);



  
  // Handle Image dimension.
  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
  
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
  
        img.onerror = reject;
      };
  
      reader.onerror = reject;
    });
  };
  

       
        const customUpload = async  ({ file, onSuccess, onError }) => {
           await beforeUpload(file); // check file size and type if valid
            getImageDimensions(file).then((dim)=> {
              if(dim?.height !== BASE_HEIGHT && dim.width !== BASE_WIDTH){
              message.error(`REJECTED! The image dimension should be ${BASE_HEIGHT}H and ${BASE_WIDTH}W`);
              return;
              }else{
                // image is good for upload
                message.info('Uploading image...');
                const formData = new FormData();
                formData.append('file', file);
        const formObject = {item_group_id: props?.Item_Id, picture_id: props?.Picture_Id || 0 ,  option: props?.Picture_Id ? 4 : 1}
        formData.append('formObject', JSON.stringify(formObject));
        itemsService.submitItemGroupImage(formData).then(res => {
          setLoading(false);
          const newUrl =  res.data?.file_link;
          setImageUrl(newUrl);
          const newImageList =  getGroupImageEdit.map((img)=> {
            if(img.Picture_Id === props?.Picture_Id){
              return {...img, Picture_URL : newUrl, Picture_Id: props?.Picture_Id}
            }else{
              return img;
            }
          });
          setGroupImageEdit(newImageList);
  
        }).catch((err)=> {
          setLoading(false);
        })
        
        }
    
            }).catch((err)=> {
              console.log('dim_error ', err);
            })
    
         
          
          
        };
    
  

  const uploadButton = (
    <button  style={{  border: 0,  background: 'none'  }}type="button" >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{  marginTop: 8,  }}  >
        Upload
      </div>
    </button>
  );

    return ( 
        <>
           <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={customUpload}  // Use manual upload

        multiple={false}
      >
        {imageUrl ? (
          <>
          <img   src={imageUrl}  alt="avatar"   style={{   width: '100%',  }}  />
         
          </>
          
        ) : (    uploadButton  )}
      </Upload>

      
        </>
     );
}

export default ItemGroupPrimaryImageUpload;