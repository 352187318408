/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../pages/NewItemGroup.css";
import plus from "../assets/Plus.svg";
import { DataGrid ,  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector} from "@mui/x-data-grid";
import { itemsGroupService } from "../services/itemGroup.service";
import { SetGroupList, GetGroupList } from "../store/itemGroupAtom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DeleteIcon from '@mui/icons-material/Delete';

import Userlayout from "../layout/Userlayout";
import AlertConfirm from "../helpers/AlertConfirm";
import NewItemGroupSearch from "./item_group/NewItemGroupSearch";
import IconButton from "@material-ui/core/IconButton";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { formatCustomNumber } from "../helpers/formatCustomNumber";


const columns = [
  {
    field: "id",
    headerName: "Id",
    width: 160,
    // editable: true,
    headerClassName: "bg-gray-200",
  },
  {
    field: "Item_Name",
    headerName: "Name",
    width: 160,
    // editable: true,
    headerClassName: "bg-gray-200",
  },
  {
    field: "Item_Sku",
    headerName: "Sku",
    // type: "date",
    width: 160,
    // editable: true,
    headerClassName: "bg-gray-200",
  },

  {
    field: "Item_Category_Name",
    headerName: "Category",
    // type: "dateTime",
    width: 160,
    // editable: true,
    headerClassName: "bg-gray-200",
  },
  {
    field: "Sales_Rate",
    headerName: "Sales Rate",
    // type: "dateTime",
    width: 160,
    // editable: true,
    headerClassName: "bg-gray-200",
  },
  {
    field: "Reorder_Quantity",
    headerName: "Current Stock",
    // type: "dateTime",
    width: 160,
    // editable: true,
    headerClassName: "bg-gray-200",
  },

  {
    field: "action",
    headerName: "Action",
    // type: "dateTime",
    width: 160,
    // editable: true,
    cellClassName:'text-danger font-weight-bold show-cursor',
    headerClassName: "bg-gray-200",
  },
];


function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}


function NewItemGroupInfoPage(props) {
  const [alertState, setAlertState] = useState(false);
  const setGroup = SetGroupList();
  const navigate = useNavigate();


  const groupListDropDown = GetGroupList();
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    itemsGroupService.getItemGroupDropDown().then((res) => {
      var temp = res?.data?.map((item, i) => {
        return { ...item, value: item, label: item.Item_Name };
      });
      setGroup(temp);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    itemsGroupService.getGroupTabledata(props.item_group_id).then((res) => {
      var temp = res?.data?.map((item, i) => {
        return { ...item, id: item.Item_Id, action: 'Delete'};
      });
      setTableList(temp);
    });
  }, [props.item_group_id]);




  function addItemToTable(selectedItem) {
    setTableList((prev) => [
      ...prev, { id: selectedItem.Item_Id , action: "Delete", ...selectedItem } ]);
    
  }


  function deleteFromTable(item) {
    setTableList((prev) => {
      return prev.filter((data) => data.id !== item.id);
    });
  }

  
  function submitTableItem() {
    setAlertState(true)
  }


  function closeAlert (response){
    setAlertState(false)
    if(response === true){
      finalSubmit();
    }
  }

  function finalSubmit(){
    let xml_string_item = "";
    let temp = "";
    tableList.map((item, i) => {
      temp += `<a><item_id>${item.Item_Id} </item_id></a>`;
    });
    xml_string_item = `<root> ${temp} </root>`;
    props.submitGroupInfo({ xml_string_item: xml_string_item, item_group_id: props.item_group_id });
  }

  return (
    <section>
      {alertState ?  <AlertConfirm title={'Save group items(s)'} body={'The following item(s) will saved as a group of items'}
      closeAlert={closeAlert}></AlertConfirm> : null }
     
      <section className="info">
        <div className="">
          
          {/* start search */}

         <div className="mt-3">
         <NewItemGroupSearch addItemToTable={addItemToTable} list={groupListDropDown} />
         </div>
        </div>

     
      </section>

      <section className="mt-10 ">
        <div
         
        >
          <DataGrid
            columns={columns}
         
            rows={tableList?.map((item)=>{ return {...item, Sales_Rate: formatCustomNumber(item.Sales_Rate)}} )}
            rowsPerPageOptions={[3]}
            onCellClick={deleteFromTable}
            experimentalFeatures={{ newEditingApi: true }}
            sx={{
              // boxShadow: 2,
              // border: 2,
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
            }}
            components={{
              Pagination: CustomPagination,
            }}
         
          />
          {/* </div> */}
        </div>
      </section>

      <section className="flex my-5 justify-between   ">
        <button
          type="cancel"
          onClick={() => navigate(-1)}
          style={{ color: "#F17E7E" }}
          className="py-2 px-4 text-sm rounded "
        >
          Cancel
        </button>

        <button
          onClick={submitTableItem}
          style={{ backgroundColor: "#476EF8" }}
          className="py-2 px-4  text-sm  rounded text-white"
        >
          Save
        </button>
      </section>
    </section>
  );
}

export default NewItemGroupInfoPage;
