import Select from 'react-select';
import {GetSubList} from "../../store/subscriptionAtom";


function AddSubCoupon(props) {
  const subentryData = GetSubList();




  const customOption = subentryData?.coupon_info?.map((item)=> {
    return ({...item, label:`${item.Discount_Description}  ${item.Discount_Type_Name}`})
  })


  
  const customStyles = {
    control: base => ({
      ...base,
      height: 50,
      backgroundColor: '#F1F1F1',
      width: 450,
      // zIndex: -9999,
  
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),

  
    
  }

    const handleChange = (value) => {
      props.onCouponSelect(value)
      };


    return ( 
        <>
   
         <Select placeholder={"Enter at leat 3 characters to search"}    styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal}
          options={customOption}
         />


        </>
     );
}

export default AddSubCoupon;
