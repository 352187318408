import React, { useState } from 'react';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Space, Table } from 'antd';
import { useNavigate,  useLocation } from "react-router-dom";


type OnChange = NonNullable<TableProps<TableDataType>['onChange']>;
type Filters = Parameters<OnChange>[1];

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface TableDataType {
  Ad_Description: string;
  Ad_End_Date: string;
  Ad_Image_URL: string;
  Ad_Start_Date: string;
  Ad_Status_Id: string;
  Ad_Status_Name: string;
  Ad_Title: string;
  Encoded_Date: string;
  Ref_No: string;
}


interface MyComponentProps {
    tableList:TableDataType[] |  any[] ;
    // count: number;
    // isActive: boolean;
    // onClick: () => void;
  }

const AddsSettingTable:  React.FC<MyComponentProps>  = ({tableList}) => {
  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as Sorts);
  };

//   const clearFilters = () => {
//     setFilteredInfo({});
//   };

//   const clearAll = () => {
//     setFilteredInfo({});
//     setSortedInfo({});
//   };

//   const setAgeSort = () => {
//     setSortedInfo({
//       order: 'descend',
//       columnKey: 'age',
//     });
//   };


const handleRowClick = (record: TableDataType) => {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${record?.Ref_No}`);
  };



  const columns: TableColumnsType<TableDataType> = [
    {
      title: 'Title',
      dataIndex: 'Ad_Title',
      key: 'Ref_No',
      filters: [
        { text: 'Joe', value: 'Joe' },
        { text: 'Jim', value: 'Jim' },
      ],
      filteredValue: filteredInfo.Ad_Title || null,
      onFilter: (value, record) => record.Ad_Title.includes(value as string),
      sorter: (a, b) => a.Ad_Title.length - b.Ad_Title.length,
      sortOrder: sortedInfo.columnKey === 'Ref_No' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'Ad_Status_Name',
      key:'Ad_Status_Name',
      sorter: (a, b) => a.Ad_Status_Id.length - b.Ad_Status_Id.length,
      sortOrder: sortedInfo.columnKey === 'Ad_Status_Name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Start Date',
      dataIndex: 'Ad_Start_Date',
      key:'Ad_Start_Date',
      filteredValue: filteredInfo.Ad_Start_Date || null,
      onFilter: (value, record) => record.Ad_Start_Date.includes(value as string),
      sorter: (a, b) => a.Ad_Start_Date.length - b.Ad_Start_Date.length,
      sortOrder: sortedInfo.columnKey === 'Ad_Start_Date' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'End Date',
      dataIndex: 'Ad_End_Date',
      key:'Ad_End_Date',
    //   filters: [
    //     { text: 'London', value: 'London' },
    //     { text: 'New York', value: 'New York' },
    //   ],
      filteredValue: filteredInfo.Ad_End_Date || null,
      onFilter: (value, record) => record.Ad_End_Date.includes(value as string),
      sorter: (a, b) => a.Ad_End_Date.length - b.Ad_End_Date.length,
      sortOrder: sortedInfo.columnKey === 'Ad_End_Date' ? sortedInfo.order : null,
      ellipsis: true,
    },
  ];

  return (
    <>
    
      <Table columns={columns} dataSource={tableList} onChange={handleChange}
        pagination={{ pageSize: 10 }}
        onRow={(record) => {
            return {
              onClick: () => {
                handleRowClick(record);
              },
            };
          }}
         />
    </>
  );
};

export default AddsSettingTable;