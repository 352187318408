import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {  message, Upload } from 'antd';
import { itemsService } from '../services/items.service';
import { productService } from '../services/product.service';
// import { itemsService } from '../../../services/items.service';
// import {  GetItemImageToEdit, SetItemImageToEdit } from '../../../store/itemAtom';
import { BASE_WIDTH, BASE_HEIGHT } from '../helpers/constantsFile';


const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };



function ProductImageUpload(props) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
//   const getItemImageToEdit = GetItemImageToEdit();
//   const setItemImageToEdit = SetItemImageToEdit();

  useEffect(()=> {
    if(props?.defaultImage){
      setImageUrl(props.defaultImage);
    }
  },[props?.defaultImage]);

  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
  
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
  
        img.onerror = reject;
      };
  
      reader.onerror = reject;
    });
  };
  

    const customUpload = async  ({ file, onSuccess, onError }) => {
         await beforeUpload(file); // check file size and type if valid
          getImageDimensions(file).then((dim)=> {
            if(dim?.height !== BASE_HEIGHT && dim.width !== BASE_WIDTH){
            message.error(`REJECTED! The image dimension should be ${BASE_HEIGHT}H and ${BASE_WIDTH}W`);
            return;
            }else{
              // image is good for upload
              message.info('Uploading image...');
              const formData = new FormData();
              formData.append('file', file);

          const formObject = {Item_Id: props?.Item_Id,plan_id: props.plan_id, image_id: props?.Image_Id || 0 ,  option: props?.Image_Id ? 4 : 1}
      formData.append('formObject', JSON.stringify(formObject));
    
      productService.submitProductPLanImage(formData).then(res => {
        onSuccess(res.data);
        const newUrl =  res.data?.file_link;
        setImageUrl(newUrl);
      message.success('Upload successful');
        setImageUrl(newUrl);
        }).catch(err => {
          onError(err);
          setLoading(false);
          window.alert('Image upload failed!');
        }) }
  
          }).catch((err)=> {
            console.log('dim_error ', err);
          })
  
       
        
        
      };

  
    const handleFileChange = (info) => {
      const { file } = info;

      if (file.status === 'error') { // Since our is dynamic upload, we use this status
        const formData = new FormData();
        const myFile = file.originFileObj;
        formData.append('file', myFile);
            const formObject = {plan_id: props?.plan_id, image_id: props?.Image_Id || 0 ,  option: props?.Image_Id ? 4 : 1}
        formData.append('formObject', JSON.stringify(formObject));
        setLoading(true);
        productService.submitProductPLanImage(formData).then(res => {
          setLoading(false);
          const newUrl =  res.data?.file_link;
          setImageUrl(newUrl);
        //   const newList =  getItemImageToEdit.map((img)=> {
        //     if(img.Image_Id === props?.Item_Id){
        //       return {...img, Image_URL : newUrl}
        //     }else{
        //       return img;
        //     }
        //   });
        //   setItemImageToEdit(newList);
        }).catch((err)=> {
          setLoading(false);
        })

      }
  
   
  
    };
  

  const uploadButton = (
    <button  style={{  border: 0,  background: 'none'  }}type="button" >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{  marginTop: 8,  }}  >
        Upload
      </div>
    </button>
  );

    return ( 
        <>
           <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={customUpload}  // Use manual upload

        multiple={false}
      >
        {imageUrl ? (
          <>
          <img   src={imageUrl}  alt="avatar"   style={{   width: '100%',  }}  />
         
          </>
          
        ) : (    uploadButton  )}
      </Upload>

      
        </>
     );
}

export default ProductImageUpload;