import { useEffect, useState } from "react";
import { GetEntryData } from "../../store/salesOrderAtom";

function SalesOrderDiscountType(props) {
  const masterData = GetEntryData();
  const [customOption, setCustomOption] = useState([]);

  useEffect(() => {
    let topElement = {};
    const tempData = masterData["Discount_Type"]?.map((element, index) => {
      if (props?.defaultVal === element?.Discount_Type_Id) {
        topElement = { ...element, label: `${element?.Discount_Type_Name}` };
      }

      return { ...element, label: `${element?.Discount_Type_Name}` };
    });
    if (topElement?.label) {
      tempData.unshift(topElement);
    }
    setCustomOption(tempData);
  }, [masterData, props]);

  const createSelectItems = () => {
    let items = [];
    customOption?.forEach((discount, i) => {
      items.push(
        <option key={i} value={discount.Discount_Type_Id}>
          {discount.Discount_Type_Name}
        </option>
      );
    });

    if (props?.defaultVal === 2) {
      return items.reverse();
    } else {
      return items;
    }
  };

  return (
    <>
      <select
        className=" bg-filterInput"
        onChange={(e) => props.onDiscountTypeSelect(e.target.value)}
      >
        {createSelectItems()}{" "}
      </select>
    </>
  );
}

export default SalesOrderDiscountType;
