import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function SubscriptionGiftDetails(props) {
    const {giftPerson} = props;

    return ( 

    <div>
    <Accordion style={{width:'350px'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="text-muted">Gifted Contacts  <br />
        <div>Name: <span className='font-bold'>{giftPerson?.gift_person_name} </span></div>
         </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        <div className="">
                     
                        <div className="pl-3" >
                          
                          <div>Email: <span className='font-bold'> {giftPerson?.gift_person_email_address}</span> </div>
                          <hr />
                          <div>Phone: <span className='font-bold'>{giftPerson?.gift_person_mobile_number}</span> </div>
                          <hr />
                          <div>Address: <span className='font-bold'>{giftPerson?.gift_person_full_address} </span> </div>
                          <hr />
                          <div>Area: <span className='font-bold'>{giftPerson?.gift_person_area_name} </span>  </div>
                          <hr />
                          <div>City: <span className='font-bold'>{giftPerson?.gift_person_city_name}  </span> </div>
                          <hr />
                          <div>Country: <span className='font-bold'>{giftPerson?.gift_person_country_name} </span> </div>
                          <hr />
                          
                        </div> 
                      </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
  
  </div>
     );
}

export default SubscriptionGiftDetails;