
import { notificationService } from '../../services/notification.service';
import {  SetIVRHistoryStore, GetIVRHistoryStore } from '../../store/IVRAtom';

import React,{ useEffect, useContext } from "react";
import 'antd/dist/antd.min.css';
import {Table} from 'antd';
import { useNavigate,  useLocation } from "react-router-dom";
import moment from "moment/moment";
import SearchIcon from '@mui/icons-material/Search';
import LogicContext from '../../Context/LogicContext';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;





function DelgroHistory() {
    const setIVRHistoryList = SetIVRHistoryStore();
    const getIVRHistoryList = GetIVRHistoryStore();
    // const myPromiseModal = createModal(GeneralModal);
  const logicContext = useContext(LogicContext);

    const [fromDate, setFromDate] = React.useState(new Date());
    const [toDate, setToDate] = React.useState(new Date());
  const navigate = useNavigate();
    const { pathname } = useLocation();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];

    const defaultStartDate = moment().startOf('month');
    const defaultEndDate = moment().endOf('month');

    // getSurveyList
    useEffect(()=> {
    if(getIVRHistoryList.length < 1){
      
        // loadHistory();
     
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getIVRHistoryList.length ]);



    const loadHistory = () => {
      notificationService.historyFromDelgro({fromDate: moment(fromDate).format('YYYY-MM-DD'),toDate: moment(toDate).format('YYYY-MM-DD'), template_id:1}).then((res)=> {
        if(res?.data?.length){
          const data =  res.data?.map((item)=> {
            return { ...item, Call_Date : moment(item.Call_Date).format(" DD-MM-YYYY:HH:mm:ss")}
          })
          setIVRHistoryList(data);
        }else{
          setIVRHistoryList([]);
          logicContext.showToast('History not found!','info');
        }
      });
 
    }



    const handleRowClick = (record) => {
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${record?.IVR_Id}`);
    };

    const paginationOptions = {
        pageSize: 10,
        showSizeChanger: true,
        total: getIVRHistoryList.length,
        // onChange: (page, pageSize) => {
        //   const newList = tableDataList.slice(0, pageSize);
        //   setTableList(newList);
        // },
      };

    const columns = [
        {
          title: 'IVR Id',
          key: 'id',
          dataIndex: 'IVR_Id',
        //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
        },
     
        {
          title: "Template Name",
          dataIndex: "Template_Name",
          // sorter: (a, b) => a.Ref_No.localeCompare(b.Ref_No)
        },
      
        {
          title: "Total Client",
          dataIndex: "Total_Client",
        //   sorter: (a, b) => a.Earn_Title.localeCompare(b.Earn_Title)
        },
        {
          title: "Call Date",
          dataIndex: "Call_Date",
        //   sorter: (a, b) => a.Earn_Title.localeCompare(b.Earn_Title)
        },
        {
          title: "Created By",
          dataIndex: "Encoded_By_Name",
        //   sorter: (a, b) => a.Earn_Title.localeCompare(b.Earn_Title)
        },
   
    
      
      ];


      const onOk = (value) => {
      };


    return ( 
        <>
    
        <div>
          <div className="row  mb-4" >
            <div className="col"  >
            {/* <ReloadOutlined onClick={()=> loadHistory()} style={{ fontSize: '30px', color:'blue' }} /> */}
            </div>
            <div className="col" >
                
            </div>
            <div className="col font-bold" >
              Total :<span className="badge badge-primary p-2">{getIVRHistoryList?.length}</span>
            </div>

          </div>
        </div>

        {/* start */}
        <div className="d-flex flex-row bd-highlight mb-3">
          <div className="p-2 bd-highlight">
       

            <Space direction="vertical" size={5}>
           
                <RangePicker
               showTime={false}
                  format="YYYY-MM-DD"
                  // defaultValue={[defaultStartDate, defaultEndDate]}
                  defaultValue={[defaultStartDate, defaultEndDate]}
                  onChange={(value, dateStringList) => {
                    setFromDate(dateStringList[0]);
                    setToDate(dateStringList[1]);
                  }}
                  onOk={onOk}
                />
              </Space>

          </div>
          <div className="p-2 bd-highlight">
            <button className='btn btn-light' onClick={()=> loadHistory()} > <SearchIcon  fontSize='40' color='primary' /> </button>
          </div>
      </div>
  


        {/* end */}
         <Table key={getIVRHistoryList?.length} columns={columns} dataSource={getIVRHistoryList}
        pagination={paginationOptions}
         style={{ background: '#f0f2f5', borderRadius: '8px' }}
         rowClassName="show-cursor"
         onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
          />

        </>
     );
}

export default DelgroHistory;