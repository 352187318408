import Item from "antd/lib/list/Item";
import {Fragment, useEffect, useRef, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";

function NewItemGroupSearch(props) {

    const [searchResultList, setSearchResultList] = useState([]); 
    const [textInput, setTextInput] = useState('');

    function onItemSearchChange (search) {
      setTextInput(search)
        if (search.length <  2) {
          setSearchResultList([])
        }else{
          var tempResult = props.list.filter((item)=>{
         
            return item?.Item_Name.toLowerCase().indexOf(search.toLowerCase()) >= 0 
            || item?.Item_Sku?.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0 
            || item?.Item_EAN?.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0 
            || item?.Item_UPC?.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0 
            || item?.Item_Brand_Name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
            ||  item?.Item_Id.toString()?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
          });
          setSearchResultList(tempResult);
        }
     

    }


    function clearInput(){
        setSearchResultList([])
    }




    return ( 
        <>

<Combobox  >
        <div className="relative">
          <div className="relative border-[1px] border-gray-200 rounded-md">
            <Combobox.Input
              className="w-full z-40 border-none py-2 pl-10 text-sm leading-5 text-gray-700 max-w-[350px] h-[40px] bg-white rounded-md overflow-hidden ring-0 focus:ring-1 focus:ring-[#1976D2]"
              // displayValue={(person) => person.name}
              placeholder="Search and add item"
              onChange={(event) => onItemSearchChange(event.target.value)}
            />
            <Combobox.Button className="absolute top-2 left-2.5 z-50">
              {/* <img
                src={MagnifyingGlass}
                alt="search_icon"
                className="h-6 w-6"
              /> */}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setTextInput("")}
          >
            <Combobox.Options
             style={{
               zIndex: 10
            }}
             className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {searchResultList.length === 0 && textInput !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                searchResultList.map((item,  i) => (
                  <Combobox.Option
                    key={i}
                    onClick={()=> {props.addItemToTable(item); clearInput()}}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active ? "bg-gray-100 text-white" : "text-gray-700"
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span  className={` ${ selected ? "font-medium" : "font-normal" }`} >
                          {item.Item_Name} |{item?.Item_Type_Name}| {formatCustomNumber(item?.Sales_Rate)}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>



        </>
     );
}

export default NewItemGroupSearch;