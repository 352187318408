import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PlanUnitNameSelect from "../PlanUnitNameSelect";
import PlanSelect from "../PlanSelect";
import PlanBillSelection from "../PlanBillSelection";

import ProductPlanImage from "../ProductPlanImage";
import { productService } from "../../services/product.service";
import { useContext } from "react";
import { GetPlanEdit, ResetPlanEdit } from "../../store/productAtom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { useParams } from "react-router-dom";
import underscore from "underscore";
import LogicContext from "../../Context/LogicContext";
import {
  SetProductMasterData,
  GetProductMasterData,
} from "../../store/productAtom";
import { useForm } from "react-hook-form";
import PlanUnitSelect from "../PlanUnitSelect";
import PlanTaxSelect from "../PlanTaxSelect";
import PlanMinimumFeeCurrency from "../PlanMinimumFeeCurrency";
import PlanAccountSelect from "../PlanAccountSelect";
import PlanFeeAccountSelect from "../PlanFeeAccountSelect";
import PlanDepartmentSelect from "../PlanDepartmentSelect";
import PlanDelivarySelect from "../PlanDeliverySelect";
import PlanVehicleSelect from "../PlanVehicleSelect";
import PlanBranchSelect from "../PlanBranchSelect";
import PlanPlatformSelect from "../PlanPlatformSelect";
import PlanSubscribersSelect from "../PlanSubscribersSelect";
import PlanLineSelect from "../PlanLineSelect";
import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import PlanBillCycleTypeSelect from "../PlanBillCycleTypeSelect";
import { styled } from "@mui/material/styles";
import { propTypes } from "react-bootstrap/esm/Image";
import { useEffect } from "react";
import { moment } from "moment";
import AddProductPlanWidget from "./AddProductPlanWidget";
import AllowCustomerPlanSwitch from "./AllowCustomerPlanSwitch";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddProductPlanPage() {
  const setMasterData = SetProductMasterData();
  const masterData = GetProductMasterData();
  const navigate = useNavigate();
  const planEditable = GetPlanEdit();
  const resetPlanEdit = ResetPlanEdit();
  const myPromiseModal = createModal(GeneralModal);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const logicContext = useContext(LogicContext);

  const { product_id } = useParams();
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };



  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setValue("Product_Id", product_id);
      setValue("Bill_Duration", 1);
      setValue("Bill_Cycle_Type_Id", 1);
      if (planEditable?.Product_Id) {
        setValue("Plan_Id", planEditable?.Plan_Id);
        setValue("Plan_Code", planEditable?.Item_Sku);
        setValue("Plan_Unit_Id", planEditable?.Plan_Unit_Id);
        setValue("Bill_Duration_Type_Id", planEditable?.Bill_Duration_Type_Id);
        setValue("Tax_Id", planEditable?.Tax_Id);
        setValue("Plan_Account_Id", planEditable?.Plan_Account_Id);
        setValue("Setup_Account_Id", planEditable?.Setup_Account_Id);
        setValue("Department_Id", planEditable?.Department_Id);
        setValue("Delivery_Id", planEditable?.Delivery_Id);
        setValue("Vehicle_Id", planEditable?.Vehicle_Id);
        setValue("Branch_Id", planEditable?.Branch_Id);
        setValue("Platform_Id", planEditable?.Platform_Id);
        setValue("Subscriber_Id", planEditable?.Subscriber_Id);
        setValue("Unit_Id", planEditable?.Unit_Id);
        setValue("Product_Line_Id", planEditable?.Product_Line_Id);
        setValue("Bill_Cycle_Type_Id", planEditable?.Bill_Cycle_Type_Id);
        setValue("Manual_Bill_Duration", planEditable?.Manual_Bill_Duration);
        setValue("Plan_Name", planEditable?.Plan_Name);
        setValue("Free_Trails", planEditable?.Free_Trails);
        setValue("Plan_Features", planEditable?.Plan_Features);
        setValue("Plan_Description", planEditable?.Plan_Description);
        setValue("market_price", planEditable?.Market_Price);
        setValue("Plan_Price", planEditable?.Plan_Price);
        setValue("Item_Id", planEditable?.Item_Id);
        setValue("Plan_Minimum_Payment", planEditable?.Plan_Minimum_Payment);
        setValue("Allow_Switch_Id", planEditable?.Allow_Switch_Id);
        setValue("Widget_Id", planEditable?.Widget_Id);
       
      }
    }

    return () => {
      resetPlanEdit();
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id]);

  useEffect(() => {
    if (masterData[0] === undefined) {
      productService.getProductMasterData(product_id).then((res) => {
        if (res?.data !== undefined) {
          // setMasterData(res.data);

          const groupedMasterData = underscore.groupBy(res.data, "Master_Type");
          setMasterData(groupedMasterData);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id]);

  function onCompositeItemSelect(value) {
    setValue("Item_Id", value.Code);
    setValue("Plan_Code", value.Parent_Name1);
    setValue("Plan_Features", value.Parent_Name2);
  }

  function onUnitNameSelect(value) {
    setValue("Plan_Unit_Id", value.Code, "Unit is required");
  }

  const onSubmit = async () => {
    const formData = getValues();
    await myPromiseModal({
      title: "Confirmation", body: " Do you want to save this record?",  showInput: false,
      placeHolder: "", }).then((res) => {
      if (res.decision === true) {
        let option = 1; // set option base on server requirements
        if (planEditable?.Product_Id) {
          option = 2;
        }



        productService.submitProductPlan({ option: option, ...formData })
          .then((res) => {
            if (res?.data !== undefined) {
              const { message_text } = res.data[0];
              logicContext.showToast(message_text, "success");
              // navigate(-1);
              propTypes.goToTab(1);
            }
          });
      }
    });
  };

  function onErrors(error) {
    console.error(errors);
    logicContext.showToast("Form error", "error");
  }

  function onSelectBillDuration(val) {
    setValue("Bill_Duration_Type_Id", val.Code);
  }

  function onTaxSelect(val) {
    setValue("Tax_Id", val.Code);
  }

  function onPlanAccountSelect(val) {
    setValue("Plan_Account_Id", val.Code);
  }

  function onPlanFeeAccountSelect(val) {
    setValue("Setup_Account_Id", val.Code);
  }

  function onDepartmentSelect(val) {
    setValue("Department_Id", val.Code);
  }

  function onDeliverySelect(val) {
    setValue("Delivery_Id", val.Code);
  }

  function onVehicleSelect(val) {
    setValue("Vehicle_Id", val.Code);
  }

  function onBranchSelect(val) {
    setValue("Branch_Id", val.Code);
  }

  function onPlatformSelect(val) {
    setValue("Platform_Id", val.Code);
  }

  function onSubscribersSelect(val) {
    setValue("Subscriber_Id", val.Code);
  }

  function onUnitSelect(val) {
    setValue("Unit_Id", val.Code);
  }

  function onLineSelect(val) {
    setValue("Product_Line_Id", val.Code);
  }

  function onBillCycleSelect(number) {
    setValue("Bill_Cycle_Type_Id", number);
  }

  function onManualBillDurationSelect(number) {
    setValue("Manual_Bill_Duration", number);
  }

  function onSelectMinimumCurrency(val) {
    // setValue('Tax_Id', val.Code)
  }


  const includeWidget = (checked) => {
    if(checked){
      setValue("Widget_Id", 1);
    }else{
      setValue("Widget_Id", 2);
    }

  }
  const allowPlanSwitch = (checked) => {
    if(checked){
      setValue("Allow_Switch_Id", 1);
    }else{
      setValue("Allow_Switch_Id", 2);
    }

  }



  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Product Plan" {...a11yProps(0)} />
            <Tab label="Product Plan Image" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          {/* <ProductPlanAdd  goToTab={goToTab} /> */}
          <form onSubmit={handleSubmit(onSubmit, onErrors)}>
            <section className="container max-auto   ">
              <div className="mb-1 ml-3">
                <h6 className="font-weight-bold ">Add Plan</h6>
                <p>
                  Required{" "}
                  <span className="font-weight-bold text-danger ml-3">*</span>{" "}
                </p>
              </div>

              <Grid
                container
                rowSpacing={5}
                columnSpacing={{ xs: 1, sm: 2, md: 1 }}
              >
                <Grid item xs={8}>
                  <Item>
                    <Grid
                      container
                      columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                      columns={16}
                    >
                      <Grid item xs={8}>
                        <Item>
                          {" "}
                          <div className="">
                            <div className="text-sm  w-full ">
                              Plan Name
                              <span className="ml-2 text-danger font-weight-bold">
                                *
                              </span>{" "}
                            </div>
                            <div className="name_input">
                              <input
                                className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                type="text"
                                {...register("Plan_Name", {
                                  required: "Plan name is required",
                                })}
                              />
                            </div>
                            <small className="text-danger">
                              {errors?.Plan_Name && errors.Plan_Name.message}
                            </small>
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>
                          {" "}
                          <div className="">
                            <span className="text-sm">
                              Unit Name
                              <span className=" font-weight-bold text-danger ml-2">
                                *
                              </span>{" "}
                            </span>
                            <div className="">
                              <PlanUnitNameSelect
                                defaultVal={{
                                  Code: planEditable?.Plan_Unit_Id,
                                  label: planEditable?.Plan_Unit_Name,
                                }}
                                onUnitNameSelect={onUnitNameSelect}
                              ></PlanUnitNameSelect>
                            </div>
                            <small className="text-danger">
                              {errors?.Plan_Unit_Id &&
                                errors.Plan_Unit_Id.message}
                            </small>
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>
                          <div className="">
                            <span className="text-sm">
                              Composite Item
                              <span className="font-weight-bold text-danger ml-2">
                                *
                              </span>{" "}
                            </span>
                            <div className="name">
                              <PlanSelect
                                defaultVal={{
                                  Code: planEditable?.Item_Id,
                                  label: planEditable?.Item_Name,
                                }}
                                selectCompositeItems={onCompositeItemSelect}
                              ></PlanSelect>
                            </div>
                            <small className="text-danger">
                              {errors?.Plan_Code && errors.Plan_Code.message}
                            </small>
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>
                          <div className="">
                            <span className="text-sm">
                              Free Trial in day(s)
                            </span>
                            <div className="name_input ">
                              <input
                                className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                type="number"
                                {...register("Free_Trails", {})}
                              />
                            </div>
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>
                          <div className="">
                            <span className="text-sm">Plan Code</span>
                            <div className="name_input">
                              <input
                                className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                type="text"
                                readOnly={true}
                                {...register("Plan_Code", {
                                  required: "Plan Code is required",
                                })}
                              />
                            </div>
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>
                          <div className="">
                            <span className="text-sm">Market Price</span>
                            <div className=" name_input">
                              <input
                                className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                type="text"
                                {...register("market_price", { required: "Market Price is required!",})}
                              />
                            </div>
                            <small className="text-danger">
                                {errors?.market_price &&  errors.market_price.message}
                              </small>
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>
                          <div className="">
                            <span className="text-sm">Plan Price</span>
                            <div className=" name_input">
                              <div className="">
                                <label className="relative">
                                  <span className="other_dimension_input">
                                    <input
                                      className="bg-filterInput  placeHolder_input z-50 bottom-0 pl-[6vw]
                        outline-none border-transparent focus:border-transparent focus:ring-0"
                                      type="number"
                                      placeholder=""
                                      {...register("Plan_Price", {
                                        required:
                                          "Plan Price is required!",
                                      })}
                                    />
                                    <span className="dimenSelect_input ">
                                      <PlanMinimumFeeCurrency
                                        onSelectMinimumCurrency={
                                          onSelectMinimumCurrency
                                        }
                                      />
                                    </span>
                                  </span>
                                </label>
                              </div>
                              <small className="text-danger">
                                {errors?.Plan_Price &&  errors.Plan_Price.message}
                              </small>
                            </div>
                          </div>
                        </Item>
                      </Grid>

                      <Grid item xs={8}>
                        {/* <Item>
                          <div className="">
                            <span className="text-sm">Plan Minimum Payment</span>
                            <div className="name_input">
                              <div className="">
                                <label className="relative">
                                  <span className="other_dimension_input">
                                    <input
                                      className="bg-filterInput  placeHolder_input z-50 bottom-0 pl-[6vw]
                        outline-none border-transparent focus:border-transparent focus:ring-0"
                                      type="number"
                                      placeholder=""
                                      {...register("Plan_Minimum_Payment", {
                                        required:
                                          "Minimum payment is required!",
                                      })}
                                    />
                                    <span className="dimenSelect_input ">
                                      <PlanMinimumFeeCurrency
                                        onSelectMinimumCurrency={
                                          onSelectMinimumCurrency
                                        }
                                      />
                                    </span>
                                  </span>
                                </label>
                              </div>
                              <small className="text-danger">
                                {errors?.Plan_Minimum_payment &&
                                  errors.Plan_Minimum_payment.message}
                              </small>
                            </div>
                          </div>
                        </Item> */}
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Item>
                              {" "}
                              <div className=" ">
                                <div className=" input_3 ">
                                  <span className="text-sm ">Bill Every</span>
                                  <input
                                    className="bg-filterInput  outline-none border-transparent focus:border-transparent focus:ring-0"
                                    type="number"
                                    {...register("Bill_Duration", {})}
                                  />
                                </div>
                              </div>
                            </Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>
                              <div className=" ">
                                <br></br>
                                <PlanBillSelection
                                  onSelectBillDuration={onSelectBillDuration}
                                ></PlanBillSelection>
                              </div>
                            </Item>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>
                          <div className="tax">
                            <div>
                              <span className="text-sm">Tax</span>
                            </div>

                            <div className="name">
                              <PlanTaxSelect
                                defaultVal={{
                                  Code: planEditable?.Tax_Id,
                                  label: planEditable?.Tax_Name,
                                }}
                                onTaxSelect={onTaxSelect}
                              ></PlanTaxSelect>
                            </div>
                          </div>
                        </Item>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>

                <Grid item xs={4}>
                  <Item>
                    <section className=" mt-2">
                      <div>
                        <span className="text-sm">Plan Description</span>{" "}
                        <div className="name  ">
                          <textarea
                            rows="4"
                            cols="28"
                            type="text"
                            {...register("Plan_Description", {})}
                            className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          ></textarea>
                        </div>
                      </div>
                      <div className=" col-end-7 col-span-2">
                        <span className="text-sm">Basket Contents</span>
                        <div className="name ">
                          <textarea
                            rows="10"
                            cols="28"
                            type="text"
                            readOnly={true}
                            {...register("Plan_Features", {})}
                            className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          ></textarea>
                        </div>
                      </div>
                    </section>
                  </Item>
                </Grid>
              </Grid>

              <section className="">
                <span className="text-sm ml-3">Bill Cycle</span>
                <Grid container>
                  <Grid item xs={6}>
                    <Item>
                      <PlanBillCycleTypeSelect
                        val={planEditable?.Bill_Cycle_Type_Id}
                        manual_value={planEditable?.Manual_Bill_Duration}
                        onManualBillDurationSelect={onManualBillDurationSelect}
                        onBillCycleSelect={onBillCycleSelect}
                      />
                    </Item>
                  </Grid>

                  <Grid item xs={6}>
                    <Item>
                      <div className="flex justify-end">
                        <div className=" text-sm">
                          <AddProductPlanWidget defaultVal={planEditable?.Widget_Id} includeWidget={includeWidget} />
                      
                            <AllowCustomerPlanSwitch defaultVal={planEditable?.Allow_Switch_Id} allowPlanSwitch={allowPlanSwitch} />
                        </div>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </section>
              <section className="">
                <span className="text-md font-bold mb-5 ml-2">
                  Account Details
                </span>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Item>
                      <div className="">
                        <span className="text-sm">Plan Account</span>
                        <div className="name">
                          <PlanAccountSelect
                            defaultVal={planEditable?.Plan_Account_Name}
                            onPlanAccountSelect={onPlanAccountSelect}
                          />
                        </div>
                        <p>
                          {" "}
                          <i className="text-md">
                            Revenue generated by this plan will be assigned to
                            the specified account
                          </i>{" "}
                        </p>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <div className=" ">
                        <span className="text-sm">Setup Fee Account</span>
                        <div className="name">
                          <PlanFeeAccountSelect
                            defaultVal={planEditable?.Setup_Account_Name}
                            onPlanFeeAccountSelect={onPlanFeeAccountSelect}
                          />
                        </div>
                        <p>
                          {" "}
                          <i className="text-md">
                            Revenue generated by this setup fee will be assigned
                            to the specified account
                          </i>{" "}
                        </p>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </section>

              <section className="mt-2">
                <span className="text-md font-bold mb-5 mt-5 ml-2">
                  Reporting Tags
                </span>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Item>
                      <div className="">
                        <span className="text-sm">Department</span>
                        <div className="name">
                          <PlanDepartmentSelect
                            defaultVal={planEditable?.Deapartment_Name}
                            onDepartmentSelect={onDepartmentSelect}
                          />
                        </div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>
                      <div className="">
                        <span className="text-sm ">Delivery</span>
                        <div className="name">
                          <PlanDelivarySelect
                            defaultVal={{
                              Code: planEditable?.Delivery_Id,
                              label: planEditable?.Delivery_Name,
                            }}
                            onDeliverySelect={onDeliverySelect}
                          />
                        </div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>
                      <div className="">
                        <span className="text-sm">Vehicle</span>
                        <div className="name">
                          <PlanVehicleSelect
                            defaultVal={{
                              Code: planEditable?.Vehicle_Id,
                              label: planEditable?.Vehicle_Name,
                            }}
                            onVehicleSelect={onVehicleSelect}
                          />
                        </div>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Item>
                      <div className="">
                        <span className="text-sm">Branch</span>
                        <div className="name">
                          <PlanBranchSelect
                            defaultVal={{
                              Code: planEditable?.Branch_Id,
                              label: planEditable?.Branch_Name,
                            }}
                            onBranchSelect={onBranchSelect}
                          />
                        </div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>
                      <div className="">
                        <span className="text-sm">Platforms</span>
                        <div className="name">
                          <PlanPlatformSelect
                            defaultVal={{
                              Code: planEditable?.Platform_Id,
                              label: planEditable?.Platform_Name,
                            }}
                            onPlatformSelect={onPlatformSelect}
                          />
                        </div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>
                      <div className="">
                        <span className="text-sm">Subscribers</span>
                        <div className="name">
                          <PlanSubscribersSelect
                            defaultVal={{
                              Code: planEditable?.Subscriber_Id,
                              label: planEditable?.Subscriber_Name,
                            }}
                            onSubscribersSelect={onSubscribersSelect}
                          />
                        </div>
                      </div>
                    </Item>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Item>
                      <div className="">
                        <span className="text-sm">Location Unit</span>
                        <div className="name">
                          <PlanUnitSelect
                            defaultVal={{
                              Code: planEditable?.Unit_Id,
                              label: planEditable?.Unit_Name,
                            }}
                            onUnitSelect={onUnitSelect}
                          />
                        </div>
                      </div>
                    </Item>
                  </Grid>

                  <Grid item xs={4}>
                    <Item>
                      <div className="">
                        <span className="text-sm">Product Line</span>
                        <div className="name">
                          <PlanLineSelect
                            defaultVal={{
                              Code: planEditable?.Product_Line_Id,
                              label: planEditable?.Product_Line_Name,
                            }}
                            onLineSelect={onLineSelect}
                          ></PlanLineSelect>
                        </div>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </section>

              <section>
                <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                  <button
                    style={{ color: "#F17E7E", backgroundColor: "white" }}
                    className="text-sm rounded"
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    style={{ backgroundColor: "#476EF8" }}
                    className="text-sm text-white px-3 rounded py-2"
                  >
                    SAVE
                  </button>
                </div>
              </section>
            </section>
          </form>
        </TabPanel>
        {/* Image tab */}
        <TabPanel value={tabValue} index={1}>
          <ProductPlanImage
            plan={planEditable}
          />
        </TabPanel>
      </Box>
    </>
  );
}
