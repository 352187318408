
import { notificationService } from '../../services/notification.service';

import React,{ useEffect, useContext, useState } from "react";
import 'antd/dist/antd.min.css';
import {Table} from 'antd';
import { useNavigate,  useLocation } from "react-router-dom";
import moment from "moment/moment";
import SearchIcon from '@mui/icons-material/Search';
import LogicContext from '../../Context/LogicContext';
import { DatePicker, Space } from 'antd';
import GeneralModal from '../../helpers/GeneralModal';
import { createModal } from "react-modal-promise";
const { RangePicker } = DatePicker;





function IVRSchedule() {
    const [myList, setMyList] = useState([]);
    // const myPromiseModal = createModal(GeneralModal);
  const logicContext = useContext(LogicContext);
  const myPromiseModal = createModal(GeneralModal);
  const navigate = useNavigate();
    const { pathname } = useLocation();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];



  
    useEffect(()=> {
        loadHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ ]);



    const loadHistory = () => {
    
      notificationService.ivrScheduleList().then((res)=> {
        if(!res.data?.list?.length){
            return logicContext.showToast('No available schedule record!','info');
        }
        setMyList(res.data?.list);
    
      });
 
    }

    const deleteSchedule = async(schedule) => {
      await myPromiseModal({ title: "Delete Scheduled list",
         body: `Your scheduled processed ${schedule?.dateValue}:${schedule?.timeValue} will be cancelled and deleted!`,
        showInput:false, placeHolder:'',showInput:false, placeHolder:''}).then((modalResult)=> {
          const {decision, dateValue, timeValue } =  modalResult;
          if (decision === true) {
              notificationService.deleteSchedule(schedule?.key).then((res)=> {
                loadHistory();
                logicContext.showToast("Delete was successful!","");
              });
          }
        });
    

    }



    // const handleRowClick = (record) => {
    //   navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${record?.IVR_Id}`);
    // };

    const paginationOptions = {
        pageSize: 10,
        showSizeChanger: true,
        total: myList.length,
        // onChange: (page, pageSize) => {
        //   const newList = tableDataList.slice(0, pageSize);
        //   setTableList(newList);
        // },
      };

    const columns = [
        {
          title: 'Template Name',
          key: 'key',
          dataIndex: 'name',
        //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
        },
     
        {
          title: "Date",
          dataIndex: "dateValue",
          // sorter: (a, b) => a.Ref_No.localeCompare(b.Ref_No)
        },
      
        {
          title: "Exact Time",
          dataIndex: "timeValue",
        //   sorter: (a, b) => a.Earn_Title.localeCompare(b.Earn_Title)
        },
        {
          title: "Numbers(s)",
          dataIndex: "numbers"
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
              <div>
                {/* <button type="link" icon={<EditOutlined style={{ fontSize: '20px' }} />} onClick={() => handleEdit(record)} /> */}
                <button className='btn btn-danger'  onClick={() => deleteSchedule(record)} >DELETE </button>
            
              </div>
            ),
          },
     
   
    
      
      ];



    return ( 
        <>
    
   

    

        {/* end */}
         <Table key={myList.length} columns={columns} dataSource={myList}
        pagination={paginationOptions}
         style={{ background: '#f0f2f5', borderRadius: '8px' }}
         onRow={(record) => {
          return {
            // onClick: () => {
            //   handleRowClick(record);
            // },
          };
        }}
          />

        </>
     );
}

export default IVRSchedule;