import React, { useEffect, useState, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import "./SalesOrderTable.css";
import Select from "react-select";
import plus from "../../assets/Plus.svg";
import LogicContext from "../../Context/LogicContext";
import { NumericFormat } from "react-number-format";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SalesOrderSearchItem from "./SalesOrderSalesItem";
import SalesOrderTax from "./SalesOrderTax";
import SalesOrderAmount from "./SalesOrderAmount";
import { GetEntryData } from "../../store/salesOrderAtom";
import { salesService } from "../../services/sales.service";
import AddBoxIcon from "@mui/icons-material/AddBox";
import moment from "moment";
import { useNavigate, Link, useLocation } from "react-router-dom";

const AddSalesOrderInvoiceTable = (props) => {
  const [invoiceTableList, setIInvoiceTableList] = useState([]);
  const [tempSelection, setTempSelection] = useState({});
  const masterData = GetEntryData();
  const [customOption, setCustomOption] = useState([]);
  const logicContext = useContext(LogicContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const customStyles = {
    // Existing control styles
    control: (base) => ({
      ...base,
      border: "1.5px solid #EEEEEE", // Tailwind gray-300
      height: 44, // h-11
      borderRadius: "0.375rem", // rounded-lg
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #3f83f8",
        borderColor: "#3f83f8 ", // Tailwind blue-500
      },
    }),

    // New styles for the dropdown menu
    menu: (base) => ({
      ...base,
      border: "1.5px solid #e5e7eb", // Tailwind gray-300
      borderRadius: "0.5rem", // rounded-md
      marginTop: "4px", // mt-1 (spacing from control)
      // boxShadow: "none",
      // "&:hover": {
      //   borderColor: "#EEEEEE", // Tailwind blue-500
      // },
      boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)", // shadow-lg
      backgroundColor: "white", // bg-white
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    // Style individual options in the dropdown
    option: (base, state) => ({
      ...base,
      padding: "8px 12px", // p-2 (assuming 1 unit = 4px)
      backgroundColor: state.isFocused
        ? "#f3f4f6" // bg-blue-100 (focused state)
        : "white",
      color: state.isSelected ? "#4b5563" : "#374151", // text-white/text-gray-900
      "&:active": {
        backgroundColor: "#93c5fd", // bg-blue-300 (active state)
      },
    }),

    // Optional: Style the dropdown indicator
    dropdownIndicator: (base) => ({
      ...base,
      color: "#6b7280", // text-gray-400
      "&:hover": {
        color: "#6b7280", // text-gray-500 (hover state)
      },
    }),
  };

  useEffect(() => {
    salesService.getSalesOrderInvoiceList().then((res) => {
      if (res?.data !== undefined) {
        let temList = res?.data.map((element) => {
          return { ...element, label: element.Invoice_No };
        });
        setCustomOption(temList);
      }
    });
  }, []);

  const viewDetails = (id) => {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
  };

  useEffect(() => {
    if (props?.defaultList?.length > 0) {
      setIInvoiceTableList(props?.defaultList);

      setTimeout(() => {
        props?.onInvoiceSelectionList([...props?.defaultList]);
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.defaultList?.length]);

  const AddToTable = () => {
    if (tempSelection?.Invoice_Ref_No) {
      const found = invoiceTableList.find(
        (element) => element.Invoice_Ref_No === tempSelection.Invoice_Ref_No
      );
      if (found) {
        return logicContext.showToast(
          "Selected Invoice already in selection",
          "error"
        );
      }
      setIInvoiceTableList((prev) => {
        return [...prev, { ...tempSelection }];
      });
      props?.onInvoiceSelectionList([
        ...invoiceTableList,
        { ...tempSelection },
      ]);
    } else {
      return logicContext.showToast("Please select an Invoice", "info");
    }
  };

  function deleteItemFromTable(removedItem) {
    const newList = invoiceTableList.filter(
      (item) => item.Invoice_Ref_No !== removedItem.Invoice_Ref_No
    );
    setIInvoiceTableList(newList);
    props?.onInvoiceSelectionList(newList);
  }

  const onchangeInvoiceSelection = (item) => {
    setTempSelection(item);
  };

  return (
    <>
      <div className="w-full p-6 bg-white border-[1px] border-gray-200 rounded-xl ">
        <div className="flex items-center justify-between">
          {/*  search invoice */}
          <div className="flex-1">
            <Select
              styles={customStyles}
              placeholder="Select invoice"
              className=""
              options={customOption}
              onChange={onchangeInvoiceSelection}
            />
          </div>
          <div onClick={AddToTable} className="ml-4 cursor-pointer">
            <AddBoxIcon fontSize="large" className="text-success" />
          </div>
        </div>

        {/* end */}

        <div className="mt-6">
          {/*  start table */}
          <div className=" ">
            <TableContainer className="mt-3 h-[250px]">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <colgroup>
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                </colgroup>
                <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                  <TableRow>
                    <TableCell className="subheading text-filterTextColor">
                      Reference{" "}
                    </TableCell>
                    <TableCell className="subheading text-filterTextColor">
                      {" "}
                      NO
                    </TableCell>
                    <TableCell className="subheading text-filterTextColor">
                      {" "}
                      Date
                    </TableCell>
                    <TableCell className="subheading text-filterTextColor">
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceTableList?.map((invoice, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className="Table2"
                          style={{ width: "120px " }}
                          align="left"
                        >
                          <Link
                            target="_blank"
                            to={`/${currModuleId}/${currModuleName}/invoices/details/${invoice.Invoice_Ref_No}`}
                          >
                            <span className="text-primary cursor">
                              {" "}
                              {invoice.Invoice_Ref_No}
                            </span>
                          </Link>
                        </TableCell>

                        <TableCell align="left">
                          <div>{invoice.Invoice_No} </div>
                        </TableCell>

                        <TableCell align="left">
                          <div>
                            {" "}
                            {moment(invoice.Invoice_Date).format(
                              "DD-MM-YYYY"
                            )}{" "}
                          </div>
                        </TableCell>

                        <TableCell align="left">
                          <CurrencyFormat
                            className={"font-bold "}
                            value={invoice?.Invoice_Amount?.toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₦"}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <DeleteIcon
                            color="error"
                            onClick={() => deleteItemFromTable(invoice)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {/* end table */}
        </div>
      </div>
    </>
  );
};

export default AddSalesOrderInvoiceTable;
