import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Table } from "react-bootstrap";
import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CurrencyFormat from "react-currency-format";
import { NumericFormat } from 'react-number-format';
import { GetSubEntryMasterdata } from "../../store/subscriptionAtom";
import Select from 'react-select';
import DeleteIcon from '@mui/icons-material/Delete';
import PlanNameSelect from "./PlanNameSelect";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";





function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};





function AddSubscriptionTable(props) {
    const { productPlanList, discountAmount } = props;
    const getEntryMasterdata = GetSubEntryMasterdata();

  

    const customTaxOption = getEntryMasterdata?.Tax?.map((item) => {
        return ({ ...item, label: `${item.Tax_Name}` })
    })

    const customStyles = {
        control: base => ({
            ...base,
            height: 44,
            backgroundColor: '#F1F1F1',
            width: 300,
        }),
        indicatorSeparator: styles => ({ ...styles, display: 'none' }),
        // placeholder: styles => ({ ...styles, display : 'none' }),
        valueContainer: styles => ({ ...styles, backgroundColor: '', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
        option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color: "black" }),
    }


    const getSubTotal = (list) => {
        let temp = 0;
        list.forEach((product) => {
            temp +=  (product.Rate  * product.Quantity)

        });
        return temp;
    }


    return (
        <>


            <TableContainer className="mt-3 w-full bg-light">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <colgroup>
                        <col style={{ width: '40%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                    </colgroup>
                    <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                        <TableRow>
                            <TableCell className="subheading">Name</TableCell>
                            <TableCell className="subheading"> Qty </TableCell>
                            <TableCell className="subheading"> Unit Price </TableCell>
                            <TableCell className="subheading"> Billing Cycle </TableCell>
                            <TableCell className="subheading"> tax </TableCell>
                            <TableCell className="subheading"> Amount </TableCell>
                            <TableCell className="subheading">  </TableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody className=" mb-3">
                        {productPlanList?.map((product, i) => {
                            return (
                                <TableRow className="border-1"
                                    key={i}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell className="subheading" align="left">
                                        {product?.Item_Name}
                                    </TableCell>


                                    <TableCell className="subheading" align="left">
                                        <NumericFormat
                                            value={product?.Quantity}
                                            style={{ width: "90px" }}
                                            thousandSeparator=","
                                            decimalSeparator="."
                                            allowedDecimalSeparators={['%']}
                                            onValueChange={(values, sourceInfo) => {
                                                props?.onAddonQuantityChange(parseFloat(Number(values.value)), i)
                                            }}
                                        />
                                    </TableCell>

                                    <TableCell className="subheading" align="left">
                                        <span>₦ {formatCustomNumber(product?.Rate)}</span>
                                    </TableCell>

                                    <TableCell className="subheading" align="left">
                                        <NumericFormat
                                            value={product?.Billing_Cycle}
                                            style={{ width: "90px" }}
                                            thousandSeparator=","
                                            decimalSeparator="."
                                            allowedDecimalSeparators={['%']}
                                            onValueChange={(values, sourceInfo) => {
                                                props?.onBillingCycleChange(parseFloat(Number(values.value)), i)
                                            }}
                                        />
                                    </TableCell>

                                    <TableCell className="subheading" align="left">

                                        <Select styles={customStyles} onChange={(val) => props?.onAddonTaxSelect(val, i)}
                                            defaultValue={product?.tax_name}
                                            options={customTaxOption} />

                                    </TableCell>
                                    <TableCell className="subheading" align="left">
                                        <CurrencyFormat value={formatCustomNumber(product.Rate * product.Quantity)}
                                            displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                                    </TableCell>
                                    <TableCell className="subheading" align="left">
                                        <DeleteIcon onClick={() => props?.removeOldAddon(i)} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <div className="flex justify-end">
                    <ol className="text-right">
                        <li className="font-bold"> SubTotal: ₦{formatCustomNumber(getSubTotal(productPlanList))} </li>
                        <li className="font-bold"> Discount: ₦{formatCustomNumber(discountAmount)} </li>
                        <li className="font-bold"> Net: ₦{formatCustomNumber(getSubTotal(productPlanList) - discountAmount)} </li>
                    </ol>
                </div>
            </TableContainer>


        </>
    );
}

export default AddSubscriptionTable;