import React, { useState } from 'react';
import { Table } from 'antd';
import './ChartStyle.css';
import { useLocation, useNavigate } from "react-router-dom";




// Function to structure data with parent-child relationships
const buildHierarchy = (items)=> {
  const map = {};
  const roots  = [];

  items.forEach(item => {
    map[item.Account_Id] = { ...item, children: [] };
  });

  items.forEach(item => {
    if (item.Parent_Account_Id &&  item.Parent_Account_Id !== 0) {
      map[item.Parent_Account_Id]?.children?.push(map[item.Account_Id]);
    } else {
      roots.push(map[item.Account_Id]);
    }
  });

  return roots;
};

const SampleChartTable= (props) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
      const navigate = useNavigate();
      const { pathname } = useLocation();  
      var url = pathname.split('/')
      const currModuleId = url[1];
      const currModuleName = url[2];
      const currUserMenu = url[3];
  const columns = [
    {
      title: 'Account Id',
      dataIndex: 'Account_Id',
      key: 'Account_Id',
      sorter: (a, b) => a.Account_Id - b.Account_Id
    },
    {
      title: 'Account Name',
      dataIndex: 'Account_Name',
      key: 'Account_Id',
      sorter: (a, b) => a.Account_Name.localeCompare(b.Account_Name)

    },
    {
      title: 'Account Number',
      dataIndex: 'Account_Number',
      key: 'Account_Id',
      sorter: (a, b) => a.Account_Number.localeCompare(b.Account_Number)

    },
    {
      title: 'Type',
      dataIndex: 'Account_Type_Name',
      key: 'Account_Id',
      sorter: (a, b) => a.Account_Type_Name.localeCompare(b.Account_Type_Name)


    },
    {
      title: 'Currency',
      dataIndex: 'Currency_Name_Short',
      key: 'Account_Id',
      sorter: (a, b) => a.Currency_Name_Short.localeCompare(b.Currency_Name_Short)

    },
    {
      title: 'Status',
      dataIndex: 'Account_Status_Name',
      key: 'Account_Id',
      sorter: (a, b) => a.Account_Status_Name.localeCompare(b.Account_Status_Name)

    },
  ];

  const structuredData = buildHierarchy(props?.tableData);

  const rowClassName = (record) => {
    return record.Parent_Account_Id ? 'child-row show-cursor' : 'parent-row show-cursor';
  };


  const rowSelection = {  selectedRowKeys, onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
      props?.selectedTableItems(selectedKeys);
    },
  };

  return (
    <div className="p-5">
      <Table defaultExpandAllRows
        columns={columns}
        dataSource={structuredData}
        // expandable={{defaultExpandAllRows: true}}
        rowKey={record => record.Account_Id}
        expandable={{
          defaultExpandAllRows: true, // Automatically expand all rows
          rowExpandable: record => record.children && record.children.length > 0, // Mark rows expandable only if they have children
        }}

        // expandable={{
        //   expandedRowKeys: structuredData.map(item => item.Account_Id), // Force expand all rows
        // }} 
             pagination={false} 
            onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${record?.Account_Id}`)
            },
        
           
          };
        }}
        
        rowSelection={rowSelection}
        rowClassName={rowClassName}
     
        bordered
      />

    </div>
  );
};

export default SampleChartTable;
