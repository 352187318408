import { useEffect, useState } from "react";
import Select from "react-select";
import { GetEntryData } from "../../store/salesOrderAtom";

function SalesOrderSalesPerson(props) {
  const masterData = GetEntryData();

  const customOption = masterData["Sales_Officer"]?.map((item) => {
    console.log("OBS ", item);
    return { ...item, label: `${item.Column1}` };
  });

  const customStyles = {
    // Existing control styles
    control: (base) => ({
      ...base,
      border: "1.5px solid #EEEEEE", // Tailwind gray-300
      height: 44, // h-11
      borderRadius: "0.375rem", // rounded-lg
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #3f83f8",
        borderColor: "#3f83f8 ", // Tailwind blue-500
      },
    }),

    // New styles for the dropdown menu
    menu: (base) => ({
      ...base,
      border: "1.5px solid #e5e7eb", // Tailwind gray-300
      borderRadius: "0.5rem", // rounded-md
      marginTop: "4px", // mt-1 (spacing from control)
      // boxShadow: "none",
      // "&:hover": {
      //   borderColor: "#EEEEEE", // Tailwind blue-500
      // },
      boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)", // shadow-lg
      backgroundColor: "white", // bg-white
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    // Style individual options in the dropdown
    option: (base, state) => ({
      ...base,
      padding: "8px 12px", // p-2 (assuming 1 unit = 4px)
      backgroundColor: state.isFocused
        ? "#f3f4f6" // bg-blue-100 (focused state)
        : "white",
      color: state.isSelected ? "#4b5563" : "#374151", // text-white/text-gray-900
      "&:active": {
        backgroundColor: "#93c5fd", // bg-blue-300 (active state)
      },
    }),

    // Optional: Style the dropdown indicator
    dropdownIndicator: (base) => ({
      ...base,
      color: "#6b7280", // text-gray-400
      "&:hover": {
        color: "#6b7280", // text-gray-500 (hover state)
      },
    }),
  };

  const handleChange = (value) => {
    props.onSalesPersonSelect(value);
  };

  return (
    <>
      <Select
        placeholder={"Select sales person...."}
        styles={customStyles}
        onChange={handleChange}
        defaultValue={props?.defaultVal}
        options={customOption}
      />
    </>
  );
}

export default SalesOrderSalesPerson;
