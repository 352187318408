import { useForm } from "react-hook-form";
import GeneralSelect from "../GeneralSelect";
import { useEffect, useState } from "react";
import { customerService } from "../../services/customer.service";
import underscore from "underscore";


const OverviewShippingAddressForm = ({customerData, closeShippingAddressModal, submitShippingAddress}) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
        const [masterData, setMasterData] = useState({});
          const [countryList, setCountryList] = useState([]);
          const [stateList, setStateList] = useState([]);
          const [areaList, setAreaList] = useState([]);

          const [stateDisplay, setStateDisplay] = useState([]);
            const [displayArea, setDisplayArea] = useState([]);
       

      useEffect(() => {
       if(customerData?.Customer_Id){
      

           setValue("Shipping_Address1", customerData?.Shipping_Address1);
           setValue("Shipping_Address2", customerData?.Shipping_Address2);
           setValue("Shipping_Area_Id", customerData?.Shipping_Area_Id);
           setValue("Shipping_Fax_Number", customerData?.Shipping_Fax_Number);
           setValue("Shipping_Zip_Code", customerData?.Shipping_Zip_Code);
           setValue("Shipping_Fax_Number", customerData?.Shipping_Fax_Number);
           
           // Added this so it wont erase when it submitted to the server
        setValue("Customer_Id", customerData?.Customer_Id);
        setValue("Billing_Address1", customerData?.Billing_Address1);
        setValue("Billing_Address2", customerData?.Billing_Address2);
        setValue("Billing_Attention", customerData?.Billing_Attention);
        setValue("Billing_Zip_Code", customerData?.Billing_Zip_Code);
        setValue("Billing_Fax_Number", customerData?.Billing_Fax_Number);
        setValue("Billing_Area_Id", customerData?.Billing_Area_Id);
      }
    
        
        }, [customerData?.Customer_Id]);


        useEffect(()=> {
          try {
            customerService.getMasterData().then((res) => { 
           
              setMasterData(res?.data);
  
              var masterDataGroup = underscore.groupBy(res?.data, "Master_Type");
      
             
              setCountryList(masterDataGroup[1] || []);
              setStateList(masterDataGroup[2] || []);
              setAreaList(masterDataGroup[3] || []);
              setStateDisplay(masterDataGroup[2] || []);
              setDisplayArea(masterDataGroup[3] || []);
        
            });
            
          } catch (error) {
            console.log('Error ', error);
            
          }
    
        },[]);


    


  const onSubmit = (data) => {
    submitShippingAddress(data);
  };

  const selectedBillCountry = (selection) => {
    var temp = stateList.filter((city) => {
      return city.Parent_Code1 === selection.Code;
    });
    setStateDisplay(temp);
  };


  const onSelectShippingState = (selection) => {
    var temp = areaList.filter((city) => {
      return city.Parent_Code1 === selection.Code;
    });
    setDisplayArea(temp);
  };


  const onShippingAreaSelect = (selection) => {
    // setbill_area(selection);
    setValue("Shipping_Area_Id",selection.Code);
  };

  return (
    <>
      <h2 className="text-lg font-semibold mb-4">Shipping Address</h2>
   <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-2 gap-4">
  {/* Attention - Full Width */}
  <div className="col-span-2">
    <label className="text-sm font-medium text-gray-700">Attention</label>
    <input
      {...register("Shipping_Attention", { required: "Attention is required" })}
      className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
    />
    <small className="text-red-500">
      {errors?.Shipping_Attention && errors.Shipping_Attention.message}
    </small>
  </div>

  {/* Country/Region */}
  <div>
    <label className="text-sm font-medium text-gray-700">Country/Region</label>
    <GeneralSelect
      list={countryList}
      defaultVal={customerData?.Shipping_Country_Name}
      onOptionSelect={selectedBillCountry}
    />
  </div>

  {/* State */}
  <div>
    <label className="text-sm font-medium text-gray-700">State</label>
    <GeneralSelect
      list={stateDisplay}
      defaultVal={customerData?.Shipping_City_Name}
      onOptionSelect={onSelectShippingState}
    />
  </div>

  {/* Shipping Address 1 - Full Width */}
  <div className="col-span-2">
    <label className="text-sm font-medium text-gray-700">Shipping Address 1</label>
    <input
      {...register("Shipping_Address1")}
      className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
    />
  </div>

  {/* Shipping Address 2 - Full Width */}
  <div className="col-span-2">
    <label className="text-sm font-medium text-gray-700">Shipping Address 2</label>
    <input
      {...register("Shipping_Address2")}
      className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
    />
  </div>

  {/* Area */}
  <div>
    <label className="text-sm font-medium text-gray-700">Area</label>
    <GeneralSelect
      list={displayArea}
      defaultVal={customerData?.Shipping_Area_Name}
      onOptionSelect={onShippingAreaSelect}
    />
  </div>

  {/* ZIP Code */}
  <div>
    <label className="text-sm font-medium text-gray-700">ZIP Code</label>
    <input
      {...register("Shipping_Zip_Code")}
      className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
    />
  </div>

  {/* Fax Number */}
  <div className="col-span-2">
    <label className="text-sm font-medium text-gray-700">Fax Number</label>
    <input
      {...register("Shipping_Fax_Number")}
      className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
    />
  </div>

  {/* Action Buttons - Full Width, Right Aligned */}
  <div className="col-span-2 flex justify-end gap-2">
    <button
      type="button"
      onClick={closeShippingAddressModal}
      className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
    >
      Cancel
    </button>
    <button
      type="submit"
      className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
    >
      Save
    </button>
  </div>
</form>

    </>
  );
};

export default OverviewShippingAddressForm;
