/* eslint-disable jsx-a11y/anchor-is-valid */
import { itemsGroupService } from "../../services/itemGroup.service";
import { useContext, useEffect, useState } from "react";
import underscore from "underscore";
import { useForm } from "react-hook-form";
// import "../../../pages/ItemInformation.css";

import { SetMasterData, GetMasterData, GetGroupEdit  } from "../../store/itemGroupAtom";
import { useNavigate } from "react-router-dom";
import TypeSelect from "../item-page/AddItemFormProps/TypeSelect";
import AlertConfirm from "../../helpers/AlertConfirm";
import PlanFeatureSelect from "./PlanFeatureSelect";

function AddItemGroupForm(props) {
  const navigate = useNavigate();
  const setMasterData = SetMasterData();
  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const masterDataList = GetMasterData();
  let editableGroup = GetGroupEdit()

  const [alertState, setAlertState] = useState(false);

  




  const { register,setValue, handleSubmit,getValues,  reset, formState: { errors } } = useForm();

  useEffect(() => {
    if (editableGroup?.Item_Group_Id) {
      setValue("Item_Group_Id", editableGroup?.Item_Group_Id);
      setValue("Item_Group_Type_Id", editableGroup?.Item_Group_Type_Id);
      setValue("Item_Group_Name", editableGroup?.Item_Group_Name);
      setValue("Item_Group_Category_Id", editableGroup?.Item_Group_Category_Id);
      setValue("Item_Group_Unit_Id", editableGroup?.Item_Group_Unit_Id);
      setValue("Item_Group_Brand_Id", editableGroup?.Item_Group_Brand_Id);
      setValue("Item_Group_Manufacturer_Id", editableGroup?.Item_Group_Manufacturer_Id);
      setValue("Product_Feature_Id", editableGroup?.Product_Feature_Id);
      setValue("Item_Group_Description", editableGroup?.Item_Group_Description);
      setValue("background_color", editableGroup?.Item_Group_Background_Color);
      setBackgroundColor(editableGroup.Item_Group_Background_Color);
     
  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableGroup]);

  useEffect(() => {
    itemsGroupService.getMasterData().then((res) => {
      if(res.data.length > 0){
        var masterDataGroup = underscore.groupBy(res?.data, "Master_Type");
        setMasterData(res?.data);


      }
  
  
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function selectGroupType(val) {
    setValue("Item_Group_Type_Id", val);
  }


  const onSubmit = async (data) => {
    setAlertState(true)
  };


  function closeAlert(response){
    setAlertState(false)
        if(response === true){
          props.submitForm( getValues());
        }
  }



  function onErrors(error) {
    console.error(errors);
  }


  const onFeatureSelect = (sel) => {
    setValue("product_feature_id", sel?.Product_Id);
  }


  const onBackgroundColorChange = (event) => {
    const selectedColor = event.target.value; 
    setValue('background_color',  selectedColor);
    setBackgroundColor(selectedColor);
  };



  return (
    <>
    {alertState  ? <AlertConfirm title={'Do you want to save it?'} 
     body={'Please check information before submitting.'} closeAlert={closeAlert}></AlertConfirm>  : null}
    

      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <section className="">
          {/* TYPE SECTION */}
          <section className="flex mt-2 justify-between w-45 ">
            {/* here */}
           
            <TypeSelect defaultVal={editableGroup?.Item_Group_Type_Id}
              addItemType={selectGroupType}
            ></TypeSelect>

           
          </section>
          {/* ITEM INFORMATION SECTION  */}
          <div class="grid grid-cols-2 gap-4 mt-3">
          <div>
          <span className="text-xs">Item Group Name</span> <br></br>
                <input
                  className="bg-filterInput bottom-0 outline-none h-[6.5vh] rounded border-transparent focus:border-transparent focus:ring-0"
                  type="text"
                  placeholder="Beans"
                  {...register("Item_Group_Name", {
                    required: "Item group name is required",
                  })}
                />
                      <small className="text-danger">
                {errors?.Item_Name && errors.Item_Name.message}
              </small>
          </div>

          <div>
          <span className="text-xs">Plan Feature </span> <br/>
          <PlanFeatureSelect defaultVal={editableGroup?.Product_Feature_Name} onFeatureSelect={onFeatureSelect} />
          </div>

          <div>
          <div>
              <label className="mt-3" for="favcolor">Select Background Color:</label> <br/>
              <input  onChange={onBackgroundColorChange} type="color" id="favcolor" 
                style={{ width: '50px',  }} value={backgroundColor} />
            </div>
                      <small className="text-danger">
                {errors?.Item_Name && errors.Item_Name.message}
              </small>
          </div>

     
          <div>
            
          <div className="">
                <span className="text-xs">Description <i>(Optional)</i></span> <br></br>
                <input
                  {...register("Item_Group_Description")}
                  className="bg-filterInput bottom-0 h-[20vh] outline-none border-transparent focus:border-transparent focus:ring-0"
                  type="text"
                  placeholder="Write a Description"
                />
              </div>
          </div>

        </div>

     
     

          <section className="flex my-5 justify-between   ">
            <a
             
              onClick={() => navigate(-1)}
              style={{ color: "#F17E7E" ,  cursor: 'pointer'}}
              className="py-2 px-4 text-sm rounded "
            >
              Cancel
            </a>

            <button
              type="submit"
              style={{ backgroundColor: "#476EF8" }}
              className="py-2 px-4  text-sm  rounded text-white"
            >
              Save
            </button>
          </section>
        </section>
      </form>
    </>
  );
}

export default AddItemGroupForm;
