import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { broadCastService } from '../../services/broadcast.service';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { List } from "react-virtualized";
import { SetRecoilBroadcastReport } from '../../store/broadcastreportAtom';
import { GetRecoilBroadcastReport } from '../../store/broadcastreportAtom';

const broadCastUrl = process.env.REACT_APP_PUBLIC_SERVER_URL;




const BroadcastPageReport = () => {
   const [dataList, setDataList] = React.useState({});
   const [filterItem, setFilterItem] = React.useState('');
   const setRecoilReport = SetRecoilBroadcastReport();
   const getRecoilReport = GetRecoilBroadcastReport();



   const displayList  = (listArr)=> {
      const tempObj = [];
      Object.fromEntries(Object.entries(listArr)
     .filter(([key, val]) => {
           tempObj.push(val);
          } ));
  
  
          const renderRow = ({ index, key, style }) => (
           <div>
            <div key={key}  className="mb-1">
            <li key={key} className="list-group-item bg-light">
               <div className='row' >
                  <div className='col-4' >
                  {index + 1} <span className='text-primary font-weight-bold m-3'> {tempObj[index].name} </span> 
                  </div>
                  <div className='col-3' >
                  <span  className='ml-4 p-1 font-weight-bold' > {tempObj[index]?.statuses[0]?.status ? tempObj[index]?.statuses[0]?.status : 'processing'} </span> 
                  </div>
                  <div className='col-4' >
                  <span className='ml-4 p-1 font-weight-bold'>{moment(Date(tempObj[index]?.statuses[0]?.timestamp)).format("LLLL")} </span>
                  </div>
                  <div className='col-1' >
                  </div>
               </div>
            
            </li>
            </div>
           </div>
          )
          return (
            <List
              width={1200}
              height={700}
              rowRenderer={renderRow}
              rowCount={tempObj.length}
              rowHeight={120}
            />
          );

   }



   React.useEffect(()=> {
      broadCastService.getBroadcastStatus(broadCastUrl).then(res => {
         if(res?.list){
            setDataList(res?.list);
            setRecoilReport(res?.list);
         }
     });
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[]);


   const handleChange = (event) => {
      setFilterItem(event.target.value);
      const option = event.target.value;

      
    
       
    };



    return (
    <>

  {/* row */}
   <div className='row'>

      {/* column two  */}
      <div className='col-6'>
      <h2 className='p-3 m-3 bg-light text-uppercase'>Broadcast status</h2>
      </div>

      {/* column two  */}
      <div className='col-6'> 
      <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">filter</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filterItem}
          label="filter"
          onChange={handleChange}
        >
          <MenuItem value={1}>All</MenuItem>
          <MenuItem value={2}>Processing</MenuItem>
          <MenuItem value={3}>Send</MenuItem>
          <MenuItem value={4}>Delivered</MenuItem>
        </Select>
      </FormControl>
    </Box>
      </div>

   </div>
   {/* end of row */}
   {displayList(getRecoilReport)}

    </>  );
}
 
export default BroadcastPageReport;