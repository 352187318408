import Select from 'react-select';
import { GetSubEntryMasterdata} from "../../store/subscriptionAtom";
import { useContext, useEffect, useState } from 'react';
import { subscriptionService } from '../../services/subscription.service';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import GiftBeneficiaryForm from './GiftBeneficiaryForm';
import LogicContext from "../../Context/LogicContext";

  
const customStyles = {
  control: base => ({
    ...base,
    height: 50,
    backgroundColor: '#F1F1F1',
    width: 450,
  }),
  indicatorSeparator: styles => ({ ...styles, display : 'none' }),
  // placeholder: styles => ({ ...styles, display : 'none' }),
  valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
  option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"})
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};




function GiftBeneficiary(props) {
  
  const [dataList, setDataList] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const logicContext = useContext(LogicContext);

  

  useEffect(()=> {

    if(!dataList?.length  && props?.Customer_Id){
      loadBeneficiaryList( props?.Customer_Id);
    }

    if(!props?.Customer_Id){
      logicContext.showToast(`You need to select a Customer before beneficiary list`, "error");
    }

  },[props.isAGift]);



  const loadBeneficiaryList = (id)=> {
    subscriptionService.beneficiaryList(id).then((res)=> {
      if(res?.data?.length){
        setDataList(res.data);
      }
    });
  }

    const onSelectBeneficiary = (beneficiary) => {
      props.onGiftPersonSelect(beneficiary)
    };



    const processNewGiftPerson = (form) => {
      subscriptionService.createNewBeneficiary({...form,Customer_Id: props?.Customer_Id }).then((res)=> {
        if(res.data?.[0]?.message_id === 1){
          logicContext.showToast(`${res?.data[0]?.message_text}`, "success");
          loadBeneficiaryList( props?.Customer_Id);
          setOpenForm(false);
        }
      });
    };


    return ( 
        <>
         <Select    styles={customStyles} onChange={onSelectBeneficiary} 
          defaultValue={props?.defaultVal}
          options={dataList?.map((giftPerson)=> {
            return ({...giftPerson, label:`${giftPerson?.Recipient_Full_Name} ${giftPerson?.Recipient_Phone_Number}`})
          })}
         />
         <br/>
         <div className="col-span-6 mt-5">
              <button onClick={()=> setOpenForm(true)} type='button' class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
              New Beneficiary
                </button>
              </div>
              {/* Modal Select */}
              <>
              <Modal
        open={openForm}
        onClose={()=> setOpenForm(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        
           <div class="bg-gray-100  rounded-md">
                <button onClick={()=> setOpenForm(false)} class="text-red-500  focus:outline-none" aria-label="Close">
                X
                </button>
              </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div className="max-w mx-auto bg-white shadow-lg rounded-lg p-6 h-96 overflow-y-auto">
    
            <GiftBeneficiaryForm setOpenForm={setOpenForm}  processNewGiftPerson={(form)=> processNewGiftPerson(form) 
          }   />
          {/* Shipping */}
    </div>
          </Typography>
        </Box>
      </Modal>

              </>
        </>
     );
}

export default GiftBeneficiary;
