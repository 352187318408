import { useEffect, useState } from "react";
import ProductImageUpload from "./ProductImageUpload";
import { productService } from "../services/product.service";




const AddProductImageUpload = (props) => {
    const [imageList, setImageList] = useState([]);
  
  
  const   {plan} = props;


  useEffect(()=> {
    if(plan?.Plan_Id  && plan?.Product_Id){
      productService.getPlanImageList(plan?.Plan_Id,  plan?.Product_Id).then((response)=> {
        if(response?.data?.Plan_Image?.length){
          setImageList(response?.data?.Plan_Image);

        }
      });
    }


  },[plan?.Plan_Id ,plan?.Product_Id]);
  

  return (
    <>
    <div className="flex flex-col items-center justify-center">
      <div className="mb-4 ">
  
      <ProductImageUpload defaultImage={imageList[0]?.Image_URL} plan_id={plan?.Plan_Id}  Image_Id={imageList[0]?.Image_Id} />
      </div>

      <div className="grid grid-cols-4 gap-4">
      <ProductImageUpload defaultImage={imageList[1]?.Image_URL} plan_id={plan?.Plan_Id}  Image_Id={imageList[1]?.Image_Id} />
      <ProductImageUpload defaultImage={imageList[2]?.Image_URL} plan_id={plan?.Plan_Id}  Image_Id={imageList[2]?.Image_Id} />
      <ProductImageUpload defaultImage={imageList[3]?.Image_URL} plan_id={plan?.Plan_Id}  Image_Id={imageList[3]?.Image_Id} />
      <ProductImageUpload defaultImage={imageList[4]?.Image_URL} plan_id={plan?.Plan_Id}  Image_Id={imageList[4]?.Image_Id} />
      </div>
      <small>NB - You can replace an image by clicking on it</small>
    </div>
    </>
   

  );
};
export default AddProductImageUpload;