import React, { useRef, useContext } from "react";
import moment from "moment/moment";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import LogicContext from "../../Context/LogicContext";
import AddsSettingsScroll from "./AdsSettingsSroller";
import { settingsService } from "../../services/settingsService";
import { MdArrowDropDown, MdDelete, MdEdit } from "react-icons/md";
import { SetEditAdsStore } from "../../store/AddsStoreAtom";
import { createModal } from "react-modal-promise";
import GeneralModal from "../../helpers/GeneralModal";




const AddsSettingDetails = () => {
  const [details, setDetails] = useState({});
  const {  id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const setEditAds = SetEditAdsStore();
  const { pathname } = useLocation();
  const myPromiseModal = createModal(GeneralModal);
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
  
       document.title = 'Adds setting details';
    getAddSettingsDetails(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  
  const getAddSettingsDetails = async (id) => {
    settingsService.getAdsDetails(id).then((response) => {
          setDetails(response.data[0]);
    });
    // notificationService.getIVRDetails(id).then((res)=> {
    //     if(res.data?.length){
    //       setDetails(res.data[0]);
    //     }
    // });

  };


  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getAddSettingsDetails(id);
  }



  const modifyExistingAds = async () => {
    await myPromiseModal({
        title: "Please confirm!", body: `Modify feature.`,
        showInput: false, placeHolder: ''
    }).then((res) => {
        if (res.decision === true) {
          setEditAds(details);
            navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
        }
    });
}


  return (
    <>
      <section className="  mt-2 ">
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <section className="mt-4 max-auto max-w-6xl d-flex flex-row-reverse ">
              <div className="flex flex-wrap justify-between md:">


          <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
            <MdEdit onClick={()=> modifyExistingAds()} className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
          </div>

          <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
            <MdDelete className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
          </div>


            </div>
            </section>
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
              <AddsSettingsScroll
               viewItemDetails={viewItemDetails}>

              </AddsSettingsScroll>
           

              <section className="w-full z-50  mt-5 ">
              

             <div className="row" >
              <div className="col-8" >
              <ul className="list-group">
                  <li className="list-group-item active"> Title : <span className="text-white ml-3 font-bold" >{details?.Ad_Title} </span> </li>
                  <li className="list-group-item"> Description : <span className="text-slate-600 ml-3 font-bold"  >{details?.Ad_Description} </span> </li>
                  <li className="list-group-item"> Status : <span className="text-slate-600 ml-3 font-bold"  >{details?.Ad_Status_Name} </span> </li>
                  <li className="list-group-item"> Start Date: <span className="text-slate-600 ml-3 font-bold"  >{details?.Ad_Start_Date} </span> </li>
                  <li className="list-group-item"> End Date: <span className="text-slate-600 ml-3 font-bold"  >{details?.Ad_End_Date} </span> </li>
                  <li className="list-group-item"> Mobile Url: <span className="text-slate-600 ml-3 font-bold"  >{details?.App_Action_Page} </span> </li>
                  <li className="list-group-item"> web Url: <span className="text-slate-600 ml-3 font-bold"  >{details?.Web_Action_Page} </span> </li>
                </ul>

              </div>

              <div className="col-4" >
                <img src={details?.Ad_Image_URL}  width="400"  height="" alt="preview" />

              </div>
             </div>
             
                            
        
              </section>
            </section>
          </Grid>
        </Grid>
     
      </section>
    </>
  );
};

export default AddsSettingDetails;
