import React, { useEffect } from 'react'
import {  useLocation, useNavigate } from "react-router-dom";
import {  useParams } from "react-router-dom";
import { createModal , } from 'react-modal-promise';
import EditProductConfirm from '../../helpers/EditProductConfirm';
import GeneralModal from '../../helpers/GeneralModal';
import { TableBody,TableCell, TableContainer, TableHead,TableRow} from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { Table } from "react-bootstrap";
  import CurrencyFormat from 'react-currency-format';
import { formatCustomNumber } from '../../helpers/formatCustomNumber';
import { formatCustomDate } from '../../helpers/formatCustomDate';


 
function SubscriptionInvoiceHistory(props) {
  const {invoiceList} = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const { item_id } = useParams();
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const myPromiseModal = createModal(EditProductConfirm);
  const generalPromiseModal = createModal(GeneralModal);
  


 async function AddNewClick(ev){
    if(ev.colDef.field === 'action'){
      myPromiseModal({title:'Confirmation', body:'Add or edit record'}).then(response => {
        if(response === 1){
          navigate(
            `/${currModuleId}/${currModuleName}/production/details/${ev.row.Bundle_No + '_' + 0}`
          );
        }
        if(response === 2){
          navigate(
            `/${currModuleId}/${currModuleName}/production/details/${ev.row.Bundle_No + '_' + 1}`
          );
        }
      })

    }
    if(ev.colDef.field === 'Bundle_No'){
      generalPromiseModal({title:'Please confirm', body:'Do you want to go to the production entry?'}).then(res => {
        if(res === true){
          navigate(
            `/${currModuleId}/${currModuleName}/production/details/${ev.row.Bundle_No + '_' + 1}`
          );
        }
      });
    }
  }

  const viewInvoice = (invoiceId)=> {
    const url = `/${currModuleId}/${currModuleName}/invoices/details/${invoiceId}`;
    window.open(url, '_blank');
  }



  return (
    <>
   <TableContainer className="mt-3 w-full">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <colgroup>
                    <col style={{width:'30%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                </colgroup>
              <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                <TableRow>
                  <TableCell className="subheading">Invoice Number</TableCell>
                  <TableCell className="subheading">Invoice Ref</TableCell>
                  <TableCell className="subheading">Invoice Amount</TableCell>
                  <TableCell className="subheading"> Status </TableCell>
                  <TableCell className="subheading">Date</TableCell>
     
                </TableRow>
              </TableHead>
  
              <TableBody className=" mb-3">
                {invoiceList?.map((invoice, i) => {
                  return (
                    <TableRow className="border-1"
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell onClick={()=> viewInvoice(invoice.invoice_ref_no)} className="subheading text-primary cursor"  align="left">{invoice.invoice_no} </TableCell>
                      <TableCell onClick={()=> viewInvoice(invoice.invoice_ref_no)}  className="subheading text-primary cursor"  align="left">{invoice.invoice_ref_no} </TableCell>
                  <TableCell  className="subheading" align="left">
                        <CurrencyFormat value={parseFloat(formatCustomNumber(invoice?.invoice_amount))}
                         displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                       </TableCell>
                      <TableCell  className="subheading" align="left">{invoice?.invoice_status_name}  </TableCell>
                      <TableCell  className="subheading" align="left">{formatCustomDate(invoice?.invoice_date)} </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
 
    </>
  )
}

export default SubscriptionInvoiceHistory;