import React, { useContext } from "react";

import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import dollar from "../../assets/dollar.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

import "./ItemDetailsPage.css";
import ToggleSwitch from "../../components/ToggleSwitch";
import ItemBundleHistory from "../../components/ItemBundleHistory";
import RateHistory from "../../components/RateHistory";
import History from "../../components/History";
import ItemOverviewPage from "../../components/ItemOverviePage";
import ItemDetailsSideScroll from "./ItemDetailsSideScroll";
import ItemTransaction from "../../components/ItemTransaction";
import { itemsService } from "../../services/items.service";
import { SetItemToEdit, SetItemImageToEdit, ResetItemToEdit } from "../../store/itemAtom";
import LogicContext from "../../Context/LogicContext";
import { Grid, Tooltip } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { BsCurrencyDollar } from "react-icons/bs";
import { MdAdd, MdEdit } from "react-icons/md";
import { FaPlus, FaTrashAlt } from "react-icons/fa";

const TAB_SECTION = {
  OVERVIEW: "OVERVIEW",
  BUNDLEHISTORY: "BUNDLEHISTORY",
  PRODUCTION_ENTRY: "PRODUCTION_ENTRY",
  TRANSACTIONS: "TRANSACTIONS",
  RATEHISTORY: "RATEHISTORY",
  HISTORY: "HISTORY",
};

function ItemDetailsPage() {
  const [selectedTab, setSelectedTab] = useState(TAB_SECTION.OVERVIEW);
  const setItemToEdit = SetItemToEdit();
  const myPromiseModal = createModal(GeneralModal);
    const resetItemToEdit = ResetItemToEdit();
  
  const setItemImageToEdit = SetItemImageToEdit();
  const [itemRecord, setItemRecord] = useState({});
  const [imageList, setImageList] = useState([]);
  const logicContext = useContext(LogicContext);

  const { item_id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    resetItemToEdit();
    if(item_id != undefined){
      getItemDetailFromServer(item_id);
    }
  }, [item_id]);

  function getItemDetailFromServer(id) {
    if(!id){
      return ;
    }
    itemsService.GetItemDetails(id).then((res) => {
      if(res?.data?.item_info){
        setItemRecord(res?.data.item_info[0]);
      }else{
        setItemRecord({});
      }
      if(res?.data?.item_picture_info?.length){
        setImageList(res?.data.item_picture_info);
      }else{
        setImageList([]);
      }
    })
      .catch((err) => {
      });
  }

  function viewItemDetails(id) {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/item-details/${id}`
    );
    getItemDetailFromServer(id);
  }

  const selectTab = (section) => {
    setSelectedTab(section);
  };

  const editCurrentItem = async () => {
    const response = await myPromiseModal({
      title: "Modify Item Group",
      body: " Do you want to modify this record?",
      showInput: false,
      placeHolder: "",
    });

    if (response.decision === true) {
      setItemToEdit(itemRecord);
      setItemImageToEdit(imageList);
      navigate(
        `/${currModuleId}/${currModuleName}/${currUserMenu}/add-item/${1}`
      );
    }
  };

  const addNewItemHandler = () => {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/add-item/${1}`
    );
  };

  // This method is for updating item status base on toggle response TRUE/FALSE
  const toogleItemStatus = (val) => {
    if (val === true) {
      setItemRecord({
        ...itemRecord,
        Item_Status_Id: 1,
        Item_Status_Name: "Active",
      });

      let xmlString = `<a><refno>${itemRecord?.Item_Id} </refno></a>`;
      itemsService
        .setItemActive(`<root> ${xmlString} </root>`)
        .then((res) => {
          logicContext.showToast(res.data[0]["message_text"], "success");
        })
        .catch((err) => {
          logicContext.showToast("Connection error", "error");
          setItemRecord({
            ...itemRecord,
            Item_Status_Id: 2,
            Item_Status_Name: "Inactive",
          });
        });
    }

    if (val === false) {
      setItemRecord({
        ...itemRecord,
        Item_Status_Id: 2,
        Item_Status_Name: "Inactive",
      });
      let xmlString = `<a><refno>${itemRecord?.Item_Id} </refno></a>`;

      itemsService
        .setIteminActive(`<root> ${xmlString} </root>`)
        .then((res) => {
          logicContext.showToast(res.data[0]["message_text"], "success");
        })
        .catch((err) => {
          logicContext.showToast("Connection error", "error");
          setItemRecord({
            ...itemRecord,
            Item_Status_Id: 1,
            Item_Status_Name: "Active",
          });
        });
    }
  };

  return (
    <div className="@container">
      <div className="mt-2 flex flex-col @xs:flex-row @xs:items-center @xs:justify-end">
        <div className="mr-6 @xs:mt-2 w-fit flex flex-row items-center px-2.5 py-[2px] border-[1px] border-gray-200 bg-white rounded-full">
          {itemRecord.Item_Status_Id === 1 ? (
            <span className="text-sm font-medium text-green-500">ACTIVE</span>
          ) : (
            <span className="text-sm font-medium text-gray-600">INACTIVE</span>
          )}
          <ToggleSwitch
            toogleItemStatus={toogleItemStatus}
            Item_Status_Id={itemRecord?.Item_Status_Id}
          ></ToggleSwitch>
        </div>

        {/* ADD NEW TRANSACTION SECTION */}

        {/* paper, edit and pencil section */}

        <div className="mt-2 flex flex-row items-center">
          <Tooltip title="Delete">
            <button className="mr-2 px-3 py-2.5 bg-gray-200 border-[1px] border-gray-300 rounded-md text-white focus:outline-none font-medium hover:bg-gray-100 active:bg-gray-200 transition-all duration-200">
              <FaTrashAlt className="h-[16px] w-[16px] text-gray-600" />
            </button>
          </Tooltip>
          <Tooltip title="Edit">
            <button
              onClick={editCurrentItem}
              className="mr-2 px-3 py-2.5 bg-gray-200 border-[1px] border-gray-300 rounded-md text-white focus:outline-none font-medium hover:bg-gray-100 active:bg-gray-200 transition-all duration-200"
            >
              <MdEdit className="h-[16px] w-[16px] text-gray-600" />
            </button>
          </Tooltip>
          {/* <button className="mr-4 px-3 py-2.5 bg-gray-200 border-[1px] border-gray-300 rounded-md text-white focus:outline-none font-medium hover:bg-gray-100 active:bg-gray-200 transition-all duration-200">
            <BsCurrencyDollar className="h-[16px] w-[16px] text-gray-600" />
          </button> */}
          <Tooltip title="Add New">
            <button
              onClick={addNewItemHandler}
              className="px-3 py-2.5 bg-gray-200 border-[1px] border-gray-300 rounded-md text-white focus:outline-none font-medium hover:bg-gray-100 active:bg-gray-200 transition-all duration-200"
            >
              <FaPlus className="h-[16px] w-[16px] text-gray-600" />
            </button>
          </Tooltip>
          {/* <button
            onClick={addNewItemHandler}
            className="px-4 py-2.5 bg-blue-500 rounded-md text-white focus:outline-none font-medium hover:bg-blue-400 active:bg-blue-500 transition-all duration-200"
          >
            ADD NEW
          </button> */}
        </div>
      </div>

      <div className="mt-6 w-full flex flex-col @xl:flex-row">
        <div className="my-1 mx-1 w-full @xl:w-[210px] flex-shrink-0 bg-white">
          <ItemDetailsSideScroll viewItemDetails={viewItemDetails} />
        </div>
        <div className="mt-10 @xl:mt-0 w-full @xl:ml-4">
          {/* overview and comment */}
          <div className="pb-2 border-b-[1.5px] border-b-gray-200 @xl:px-4 flex flex-row flex-wrap @md:justify-between max-w-3xl">
            <div
              onClick={() => selectTab(TAB_SECTION.OVERVIEW)}
              className={
                "mt-2 mr-2 text-sm text-gray-600 font-medium cursor-pointer " +
                (selectedTab === TAB_SECTION.OVERVIEW ? " text-primary " : null)
              }
            >
              Overview
            </div>

            <div
              onClick={() => selectTab(TAB_SECTION.TRANSACTIONS)}
              className={
                "mt-2 mr-2 text-sm text-gray-600 font-medium cursor-pointer " +
                (selectedTab === TAB_SECTION.TRANSACTIONS
                  ? " text-primary "
                  : null)
              }
            >
              Transactions
            </div>
            {/* commented out for temprarrary purpose , we want item type to display first */}

            <div
              onClick={() => selectTab(TAB_SECTION.BUNDLEHISTORY)}
              className={
                "mt-2 mr-2 text-sm text-gray-600 font-medium cursor-pointer " +
                (selectedTab === TAB_SECTION.BUNDLEHISTORY
                  ? "text-primary "
                  : null)
              }
            >
              Production History
            </div>

            <div
              onClick={() => selectTab(TAB_SECTION.RATEHISTORY)}
              className={
                "mt-2 mr-2 text-sm text-gray-600 font-medium cursor-pointer " +
                (selectedTab === TAB_SECTION.RATEHISTORY
                  ? "text-primary "
                  : null)
              }
            >
              Rate History
            </div>

            <div
              onClick={() => selectTab(TAB_SECTION.HISTORY)}
              className={
                "mt-2 mr-2 text-sm text-gray-600 font-medium cursor-pointer " +
                (selectedTab === TAB_SECTION.HISTORY ? "text-primary " : null)
              }
            >
              History
            </div>
          </div>

          <div className=" mt-2 max-auto max-w-6xl text-filterTextColor">
            {/* 
               DETAILS SECTION */}

            {/* CUSTOMER DETAILS INFORMATION */}
            {selectedTab === TAB_SECTION.OVERVIEW ? (
              <ItemOverviewPage
                viewCustomer={viewItemDetails}
                imageList={imageList}
                itemDetails={itemRecord}
              />
            ) : null}

            {selectedTab === TAB_SECTION.BUNDLEHISTORY ? (
              <ItemBundleHistory item_id={item_id} />
            ) : null}

            {selectedTab === TAB_SECTION.TRANSACTIONS ? (
              <ItemTransaction item_id={item_id} />
            ) : null}

            {selectedTab === TAB_SECTION.RATEHISTORY ? (
              <RateHistory item_id={item_id} />
            ) : null}

            {selectedTab === TAB_SECTION.HISTORY ? (
              <History item_id={item_id} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetailsPage;
