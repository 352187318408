/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useContext, useRef, useCallback } from "react";
import { accountService } from "../../services/accountService";
import { Dropdown } from "react-bootstrap";
import LogicContext from "../../Context/LogicContext";
import {
  GetPurchaseOrderMasterData,
  SetPurchaseOrderMasterData,
} from "../../store/purchaseOrderAtom";
// import CurrencyFormat from 'react-currency-format';
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import { useState } from "react";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { useLocation, useNavigate } from "react-router-dom";
import PurchaseOrderTable from "./PurchaseOrderTable";
import { FaPlus } from "react-icons/fa";
import { IoMdRefresh } from "react-icons/io";

export const SalesTable = ({ tableData }) => {
  // const config = {
  //     sheetName: 'Sales Data',
  //     hostClass: ' spreadsheet',
  //     autoGenerateColumns: false,
  //     width: 200,
  //     visible: true,
  //     resizable: true,
  //     priceFormatter: '$ #.00',
  //     chartKey: 1
  // }
};

function PurchaseOrderPage() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const logicContext = useContext(LogicContext);
  const setMasterData = SetPurchaseOrderMasterData();
  const getMasterData = GetPurchaseOrderMasterData();
  const myPromiseModal = createModal(GeneralModal);

  const [tableData, setTableData] = useState([]);
  const [itemSelected, setItemSelected] = useState([]);
  const mountRef = useRef(false);

  useEffect(() => {
    document.title = "Purchase Order";
    if (getMasterData["Purchase_Order_Info"]?.length > 0) {
      setTableData(getMasterData["Purchase_Order_Info"]);
    }
  }, [getMasterData]);

  const getPurchaseOrderListApi = async () => {
    accountService.getPurchaseOrderList().then((response) => {
      if (response?.data !== undefined) {
        setMasterData(response.data);
      }
    });
  };

  const onActionFilterPerform = (action) => {
    const option = action.target.value;
    if (Number(option) === 0) {
      return setTableData(getMasterData["Purchase_Order_Info"]);
    }
    var newList = getMasterData["Purchase_Order_Info"].filter(
      (item) => item.Purchase_Order_Status_Id === Number(option)
    );
    if (newList.length) {
      setTableData(newList);
    } else {
      setTableData([]);
    }
  };

  function selectedTableItems(itemList) {
    setItemSelected(itemList);
  }

  function addNewInvoice() {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }

  async function bulkActions(action) {
    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        let temp = "";
        const option = action.Procedure_Option;
        itemSelected.map((item) => {
          temp += `<a> <refno> ${item.Purchase_Order_Ref_No} </refno> </a>`;
        });

        let xml_string_refno = `<root> ${temp} </root>`;
        accountService
          .setPurchaseBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { message_text } = res.data[0];
              logicContext.showToast(message_text, "success");
              return getPurchaseOrderListApi();
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  }

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center space-x-2">
          {itemSelected.length > 0 ? (
            <div className="col col-md-4 bg-black">
              <Dropdown>
                <Dropdown.Toggle
                  className="border-[1px] border-gray-200"
                  style={{
                    backgroundColor: "#E5E5E5",
                    border: "none",
                    color: "#45444B",
                    fontSize: "12px",
                    height: "45px",
                  }}
                  id="dropdown-basic"
                >
                  Bulk Actions
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {getMasterData["Bulk_Action"]?.map((action, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        onClick={() => bulkActions(action)}
                      >
                        {action.Action_Name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <ActionPaneComponent
              bulkActionData={getMasterData["Filtering_Criteria"]}
              onSelectBulkAction={onActionFilterPerform}
            />
          )}

          <Tooltip title="Reload">
            <div
              onClick={() => getPurchaseOrderListApi()}
              className="h-fit w-fit group flex items-center justify-center px-2.5 py-2.5 border-[1px] border-gray-200 bg-white rounded-md hover:bg-gray-200 cursor-pointer"
            >
              <IoMdRefresh className="h-6 w-6 text-gray-600 group-active:rotate-45 transition-all duration-300" />
            </div>
          </Tooltip>
        </div>

        <Tooltip title="Add New">
          <button
            onClick={addNewInvoice}
            className="h-fit w-fit px-3 py-2.5 bg-gray-200 border-[1px] border-gray-300 rounded-md text-white focus:outline-none font-medium hover:bg-gray-100 active:bg-gray-200 transition-all duration-200"
          >
            <FaPlus className="h-[16px] w-[16px] text-gray-600" />
          </button>
        </Tooltip>
      </div>
      <div className="mt-5">
        <PurchaseOrderTable
          tableData={tableData}
          selectedTableItems={selectedTableItems}
        />
      </div>
    </>
  );
}

export default PurchaseOrderPage;
