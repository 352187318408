import { Grid } from "@mui/material";
import React, { useEffect, useContext, useState } from "react";
import AddSubCoupon from "./AddSubCoupon";
import AddSubCustomer from "./AddSubCustomer";
import AddSubtypeSelect from "./AddSubtypeSelect";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  SetSubEntryMasterdata,
  GetSubEntryMasterdata,
} from "../../store/subscriptionAtom";
import { subscriptionService } from "../../services/subscription.service";
import underscore from "underscore";
import { useForm, useFormState } from "react-hook-form";
import LogicContext from "../../Context/LogicContext";
import AddSubscriptionadminSelect from "./AddSubscriptionAdminSelect";
import SubscriptionGiftSelect from "./SubscritionGiftSelect";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { GetSubscriptionEdit, ResetSubscriptionEdit } from "../../store/subscriptionAtom";
import { GetEditSalesOrder, ResetEditSalesOrder } from "../../store/salesOrderAtom";

import '../../pages/Table.css';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useRef } from "react";
import AddSubscriptionTable from "./AddSubscriptionTable";
import AddSubProduct from "./AddsubProduct";
import AddSubSelectItem from "./AddSubSelectItem";
import GiftBeneficiary from "./GiftBeneficiary";






const AddSubscriptionPage = () => {
  const setEntryMasterdata = SetSubEntryMasterdata();
  const getSubscriptionEdit = GetSubscriptionEdit();

  const editableSalesOrder = GetEditSalesOrder();
  const resetSalesOrderEdit = ResetEditSalesOrder();

  const resetSubscriptionEdit = ResetSubscriptionEdit();
  const myPromiseModal = createModal(GeneralModal);
  const { register, setValue, getValues, handleSubmit, control, reset,watch, formState: { errors } } = useForm();
  const { dirtyFields } = useFormState({ control });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const logicContext = useContext(LogicContext);
  const [productFeature, setProductFeature] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [productPlanList, setProductPlanList] = useState([]);

  const [couponValue, setCouponValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const external_Customer_Id = searchParams.get('Customer_Id');
  const Customer_Full_Name = searchParams.get('Customer_Full_Name');
  const Sales_Order_Ref_No = searchParams.get('Sales_Order_Ref_No');
  const [isAGift, setIsAGift] = useState(null);


  const discountAmount = watch("discount_amount", 0); // Default to 0


  useEffect(() => {
    if (external_Customer_Id) {
      setValue("Customer_Id", external_Customer_Id);
      setValue("Sales_Order_Ref_No", Sales_Order_Ref_No);

    }
    document.title = 'Add Subscription';

    setValue("Gift_Id", 2);
    setValue("Email_Send_Allow_Id", 2);

    let isMounted = true

    if (isMounted) {
      if (getSubscriptionEdit?.details?.subscription_ref_no) {

        setProductPlanList(getSubscriptionEdit?.itemList.map((item)=> {
          return {Item_Name: item?.product_feature_name, Quantity: item?.quantity, Rate: item.rate,
            Billing_Cycle: item?.recuring_cycle, Item_Sku: item?.item_sku, tax_id:item?.tax_id, Item_Id: item?.item_id
          }
        }));
        setValue("Gift_Id", getSubscriptionEdit.details?.gift_id);
        setValue("subscription_ref_no", getSubscriptionEdit?.details?.subscription_ref_no);
        setValue("Customer_Id", getSubscriptionEdit?.details?.customer_id);
        setValue("Discount_Coupon_Id", getSubscriptionEdit?.details?.discount_coupon_id);
        setValue("discount_amount", getSubscriptionEdit?.details?.discount_amount);


        if( getSubscriptionEdit.details?.gift_id === 1){
          setValue("gift_recipient_ref_no", getSubscriptionEdit?.details?.gift_recipient_ref_no);
          
        }
        if(getSubscriptionEdit?.details?.discount_value >  0){
          setCouponValue(getSubscriptionEdit?.details?.discount_value);
          
        }

        // setValue("Recuring_Subscription_Id", getSubscriptionEdit?.details?.Recuring_Subscription_Id);
        setValue("Email_Send_Allow_Id", getSubscriptionEdit?.details?.email_send_allow_id)
    


      }
    }

    //  REMOVE THIS WHEN YOU ARE DONE 
    return () => {
      resetSubscriptionEdit();
      resetSalesOrderEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  useEffect(() => {
    subscriptionService.getEntryMasterData().then((res) => {
      if (res.data !== undefined) {
        setEntryMasterdata(res.data);
      }
    });
  }, []);



  useEffect(() => {

    if (external_Customer_Id) {
      subscriptionService.getCustomer(external_Customer_Id).then(res => {
        if (res?.data !== undefined) {
          const customer = res?.data;

          setSelectedCustomer(customer);
   
        }

      })
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [external_Customer_Id]);





  function onCustomerSelect(customer) {
    setValue("Customer_Id", customer.Customer_Id);
    setSelectedCustomer(customer);
  }


  function onEmailSendChecked(val) {
    setValue("Email_Send_Allow_Id", val);
  }




  const onSubmit = async () => {
    const formValues = getValues();
    const uniqList = await underscore.uniq(productPlanList, false, (item) => {
      return item?.Item_Id;
    });


    if (!formValues?.Customer_Id) {
      return logicContext.showToast('Please select a customer!', 'error');
    }

    if(!productPlanList?.length){
      return logicContext.showToast('At least one item must be selected!', 'error');
    }

    await myPromiseModal({
      title: "Confirmation", body:  getSubscriptionEdit?.details?.subscription_ref_no ? "Update subscription" : " Do you want to save this record?",
      showInput: false, placeHolder: ''
    }).then((res) => {
      if (res.decision === true) {

        // Updating subscription 
     
          //  creating new subscription
          if (!productPlanList?.length) {
            return logicContext.showToast('At least one product must be selected!', 'error')
          }

          if (productPlanList?.length !== uniqList.length) {
            return logicContext.showToast('Multiple Product selection, Product must be unique!', 'error')
          }


          var xmlplan = "";
          var tempString = "";
          let xml_plan_complete = "";
          productPlanList?.forEach((productItem) => {
            if (productItem.Item_Sku !== undefined) {
              tempString += `<a> 
                    <item_sku>${productItem?.Item_Sku  }</item_sku> 
                    <price>${productItem?.Rate ||  0} </price>
                    <quantity>${productItem?.Quantity ||  0} </quantity>
                    <tax_id>${productItem?.tax_id ||  0} </tax_id>
                    <billing_cycles>${productItem?.Billing_Cycle} </billing_cycles>
                      </a>`;

            }
          });
          xmlplan += tempString;




          xml_plan_complete = `<root>${xmlplan} </root>`;
          let option = 1;
          if (getSubscriptionEdit?.details?.subscription_ref_no) {
            option = 2
          }

          subscriptionService.submitSubscription({ ...formValues, option: option, xml_string_plan: xml_plan_complete }).then((res) => {
            if (res?.data !== undefined) {
              const { message_id, message_text } = res.data[0];
              if (message_id == 1) {
                logicContext.showToast(message_text, "success");
                // sessionStorage.setItem("reloadPage", "true");
                // navigate(-1);
              } else {
                logicContext.showToast(message_text, "error");
              }
            }
          }).catch(err => {
            logicContext.showToast('form submission failed!', "error");
          });

        

      }
    });
  };



  function onErrors(error) {
    logicContext.showToast("Form error", "error");
  }



  function onGiftChecked(val) {
    setIsAGift(val);
    setValue("Gift_Id", val);

  }



  function onGiftPersonSelect(giftPerson) {
    setValue("gift_recipient_ref_no", giftPerson?.Recipient_Ref_No);

  }


  function onAddonQuantityChange(value, index) {
    const newQuantity = value;
    if (newQuantity > 0) {
      const newList = productPlanList.map((item, i) => {
        if (i === index) {
          return ({ ...item, Quantity: parseInt(newQuantity) })
        } else {
          return (item)
        }
      });
      setProductPlanList((prev) => { return ([...newList]) });
    }
  }



  function removeOldAddon(index) {
    const newList = productPlanList.map((item, i) => { return (item) });

    if (newList[index]) {
      newList?.splice(index, 1);
      setProductPlanList((prev) => {
        return ([...newList]);
      });
    }
  }



  function onAddonTaxSelect(val, index) {
    let temAddon = productPlanList[index];
    productPlanList[index]['tax_id'] = parseInt(val?.Tax_Id);
    setProductPlanList((prev) => {
      return ([...productPlanList]);
    });
  }

  function onAdminSelect(val) {
    setValue("User_Ref_No", val.User_Id);
  }
  
  function onCouponSelect(val) {
    setValue("Discount_Coupon_Id", val.Discount_Coupon_Id);
    setValue("discount_amount", val.Discount_Value);
    setCouponValue(val?.Discount_Value);
  }

  function onPlanFeatureSelect(feature) {
    setProductFeature(feature);
   
  }



  const onItemSelect = (item) => {

    const firstItem =  productPlanList[0];
    if(firstItem?.Item_Id  && firstItem?.Product_Feature_Id !== item?.Product_Feature_Id){
      return logicContext.showToast('Multiple basket type is not allowed!', "error");
    }

    console.log('item... ', item);
    setProductPlanList((prev) => {
      return ([...prev, {
        ...item, label: item.Item_Name
        , Sales_Rate: item?.Sales_Rate, Quantity: 1,
        tax_id: 0, Billing_Cycle: 1
      }]);
    })
  }

  const onBillingCycleChange = (cycle, index) => {
    
    if (cycle > 0) {
      const newList = productPlanList.map((item, i) => {
        if (i === index) {
          return ({ ...item, Billing_Cycle: parseInt(cycle) })
        } else {
          return (item)
        }
      });
      setProductPlanList((prev) => { return ([...newList]) });
    }
  }


    function activationDate() {
      let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
      if (getSubscriptionEdit?.details?.subscription_activated_date_string !== undefined) {
        defaultDate = moment(getSubscriptionEdit?.details?.subscription_activated_date_string).format("YYYY-MM-DD");
      }
  
      return (
        <div className="">
          <input
            defaultValue={defaultDate}
            // key={defaultDate}
            className="bg-white pl-3 rounded w-full bottom-0 outline-none border-transparent focus:border-transparent ring-1 ring-gray-200"
            type="date"
            {...register("Subscription_Activated_Date", {
              required: "activation date is required",
            })}
          />
          <small className="text-danger">
            {errors?.Subscription_Activated_Date && errors.Subscription_Activated_Date.message}
          </small>
        </div>
      );
    }

  return (
    <>
    <section className="mt-5 p-5 bg-white shadow-md rounded-lg border border-gray-200">
  <form onSubmit={handleSubmit(onSubmit, onErrors)}>
    {/* Customer & Activation Date */}
    <section className="grid grid-cols-2 gap-4 p-4 border-2 rounded-lg">
      <div>
        <label className="text-sm font-semibold">Customer <span className="text-red-500">(Please select a customer)</span></label>
        <AddSubCustomer
          defaultVal={{
            label: getSubscriptionEdit?.details?.customer_full_name || Customer_Full_Name,
          }}
          onCustomerSelect={onCustomerSelect}
        />
      </div>
      <div>
        <label className="text-sm font-semibold">Activation Date</label>
        {activationDate()}
      </div>
    </section>

    {/* Customer Information */}
    <section className="mt-4 grid grid-cols-2 gap-4 p-4 border-2 rounded-lg">
      <div>
        <p className="text-red-500 text-xs">Customer's information</p>
        <div className="mt-3">
          <p className="text-sm font-bold">Billing Address</p>
          <p className="text-xs text-gray-700">{selectedCustomer?.Customer_Id || getSubscriptionEdit?.details?.customer_id}</p>
          <p className="text-xs text-gray-700">{selectedCustomer?.Billing_Address1 || getSubscriptionEdit?.details?.billing_address1 }</p>
          <p className="text-sm font-bold mt-2">Shipping Address</p>
          <p className="text-xs text-gray-700">{selectedCustomer?.Shipping_Address1 || getSubscriptionEdit?.details?.shipping_address1}</p>
        </div>
        <p className="text-sm font-bold mt-3">Email to <span className="text-red-500">(Email will be sent to customer)</span></p>
        <AddSubtypeSelect
          onEmailSendSelect={onEmailSendChecked}
          val={getSubscriptionEdit?.details?.email_send_allow_id}
          email={selectedCustomer?.Customer_Email_Address || getSubscriptionEdit?.details?.customer_email_address}
        />
      </div>

      <div>
        <label className="text-sm">Sales Order Reference No <span className="text-red-500">(Read only field)</span></label>
        <input
          type="text"
          readOnly
          className="bg-gray-200 w-full p-2 rounded outline-none border-transparent focus:ring-0"
          {...register("Sales_Order_Ref_No")}
        />
      </div>
    </section>

    {/* Product & Item Selection */}
    <section className="mt-4 grid grid-cols-2 gap-4 p-4 border-2 rounded-lg">
      <div>
        <label className="text-sm font-bold">Product <span className="text-red-500">(Select product)</span></label>
        <AddSubProduct 
          defaultVal={{ label: getSubscriptionEdit?.plan?.Product_Name }}
          onProductSelect={onPlanFeatureSelect}
        />
      </div>
      <div>
        <label className="text-sm font-bold">Item <span className="text-red-500">(Select product before choosing item)</span></label>
        <AddSubSelectItem onItemSelect={onItemSelect} productFeature={productFeature} />
      </div>
    </section>

    {/* Product Table */}
    <section className="mt-5 p-4 border-2 rounded-lg bg-gray-50">
      <p className="text-sm font-bold">Product Table <span className="text-red-500">(Selected item details)</span></p>
      <AddSubscriptionTable
        onAddonTaxSelect={onAddonTaxSelect}
        onAddonQuantityChange={onAddonQuantityChange}
        productPlanList={productPlanList}
        discountAmount={discountAmount}
        removeOldAddon={removeOldAddon}
        onBillingCycleChange={onBillingCycleChange}
      />
    </section>

    {/* Additional Fields */}
    <section className="mt-4 grid grid-cols-2 gap-4 p-4 border-2 rounded-lg">
      <div>
        <label className="text-sm font-bold">Select Coupon</label>
        <AddSubCoupon
          defaultVal={{ label: getSubscriptionEdit?.details?.discount_description }}
          onCouponSelect={onCouponSelect}
        />
      </div>
          
          <div>
        <label className="text-md font-bold">Discount</label>
        <p className="text-sm">Discount Amount</p>
        <input
          className="bg-gray-200 w-full p-2 rounded outline-none border-transparent focus:ring-0"
          type="number" 
          {...register("discount_amount")}
        />
      </div>

    </section>

    {/* Subscription Term */}
    <section className="mt-4 grid grid-cols-2 gap-4 p-4 border-2 rounded-lg">
      <div>
        <label className="text-sm">Is this a gift? <span className="text-red-500">(Select YES if applicable)</span></label>
        <SubscriptionGiftSelect
          val={getSubscriptionEdit.details?.gift_id}
          onGiftChecked={onGiftChecked}
        />
        {isAGift === 1 && (
          <GiftBeneficiary
            isAGift={isAGift}
            Customer_Id={selectedCustomer?.Customer_Id || getSubscriptionEdit?.customer_id }
            onGiftPersonSelect={onGiftPersonSelect}
          />
        )}
      </div>
     
    </section>

    {/* Created By */}
    <section className="mt-4 p-4 border-2 rounded-lg">
      <label className="text-sm text-red-500">Created by</label>
      <AddSubscriptionadminSelect
        defaultVal={{ label: getSubscriptionEdit?.details?.encoded_by_name}}
        onAdminSelect={onAdminSelect}
      />
    </section>

    {/* Buttons */}
    <section className="mt-6 flex justify-between">
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 border border-gray-300 rounded"
      >
        CANCEL
      </button>
      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        SAVE AND CREATE SUBSCRIPTION
      </button>
    </section>
  </form>
</section>

    </>
  );
};

export default AddSubscriptionPage;
