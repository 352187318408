

import React,{Fragment, useEffect, useRef, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { SetRecoilCorporateApprovedList, GetRecoilCorporateApprovedList  } from "../../store/corporateApproveList";
import { corporateService } from "../../services/corporation.service";
import { debounce } from "lodash";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";





function CorApprovalSearchBox() {
const getRecoilCorporateData = GetRecoilCorporateApprovedList();
const setRecoilCorporateData = SetRecoilCorporateApprovedList();
    const [searchResult, setSearchResult] = useState([]);
    const [textInput, setTextInput] = useState('');
    const navigate = useNavigate();
    const mountedRef = useRef(true);

    const { pathname } = useLocation(); 
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];



    useEffect(()=> {
      mountedRef.current =  true;
      if(!getRecoilCorporateData.Corporate_Info?.length){
        corporateService.getCorporateApprovedList().then( response => {
            if(response?.data.Corporate_Info){
                setRecoilCorporateData(response.data);
            }
    
        });

      }else{
      }

      return ()=> { 
        mountedRef.current = false;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

 
    const debouncedSearch = React.useRef(
      debounce(async (event) => {
        processSearch(event);
      }, 2000)
    ).current;


    function processSearch(search: string){
      if(search?.length > 2){
        
        var tempResult = getRecoilCorporateData.Corporate_Info?.filter((corp: any)=>{
          return   corp?.Corporate_Email_Address?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
          ||  corp?.Corporate_Contact_Person_Mobile?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
          ||  corp?.Area_Name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
          ||  corp?.Corporate_Short_Code?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
          ||  corp?.Corporate_Name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
          ||  corp?.Corporate_Adddress?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
       
        });
      
            setSearchResult(tempResult);
      
      }else{
        setSearchResult([]);
      }
   
    }


    function selectedOption(corp: any){
        const { Corporate_Id} = corp;
        setSearchResult([]);
        setTextInput('')
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${Corporate_Id}`);
    }


    return (  

        <>
            <Combobox  >
        <div className="relative">
          <div className="relative border-[1px] border-gray-200 rounded-md">
            <Combobox.Input
              className="w-full z-40 border-none py-2 pl-10 text-sm leading-5 text-gray-700 max-w-[350px] h-[40px] bg-white rounded-md overflow-hidden ring-0 focus:ring-1 focus:ring-[#1976D2]"
              // displayValue={(person) => person.name}
              placeholder="Search in Corporate Approval"
              onChange={(event) => processSearch(event.target.value)}
              // onChange={(event) => setTextInput(event.target.value)}
            />
            <Combobox.Button className="absolute top-2 left-2.5 z-50">
              {/* <img
                src={MagnifyingGlass}
                alt="search_icon"
                className="h-6 w-6"
              /> */}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setTextInput("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {searchResult.length === 0 && textInput !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                searchResult.map((invoice: any,  i: number) => (
                  <Combobox.Option
                    key={i}
                    onClick={()=> selectedOption(invoice)}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active ? "bg-gray-100 text-white" : "text-gray-700"
                      }`
                    }
                    value={invoice}
                  >
                    {({ selected, active }) => (
                      <>
                        <span  className={` ${ selected ? "font-medium" : "font-normal" }`} >
                          {invoice.Corporate_Name} |{invoice?.Corporate_Email_Address}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-gray-700"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      
        </>
    );
}

export default CorApprovalSearchBox;