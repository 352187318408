import React, { useRef, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import moment from "moment/moment";
import message from "../../assets/message.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { invoiceService } from "../../services/invoice.service";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
// import InvoicePaymentTable from "./InvoicePaymentTable";
// import InvoiceDetailsTable from "./InvoiceDetailsTable";
import {
  SetCorporateEdit,
} from "../../store/corporateAtom";
import { corporateService } from "../../services/corporation.service";
import LogicContext from "../../Context/LogicContext";
import CorporateScroll from "./CorporateScroll";
import SampleFileViewerModal from "../customer_approval/SampleFileViewerModal";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";

// import { useReactToPrint } from 'react-to-print';



const CorporateDetailsPage = () => {
  const setEdit = SetCorporateEdit();
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  // const getBulkActions = GetInvoiceBulkAction();

  const { corporate_id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [corporateData, setCorporateData] = useState({});
  // const [documentInfoList, setDocumentInfoList] = useState([]);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
       document.title = 'Corporate details';
    getInvoiceDetails(corporate_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corporate_id]);


  
  const getInvoiceDetails = async (id) => {
    corporateService.getCorporateDetails(corporate_id).then((response) => {
      if (response.data?.Corporate_Info) {
        setCorporateData(response.data?.Corporate_Info[0]);
        // setDocumentInfoList(response.data?.Document_Info);
      }
    });
  };


  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getInvoiceDetails(id);
  }

  function viewPaymentRecord(id) {
    navigate(
      `/${currModuleId}/${currModuleName}/payment-received/details/${id}`
    );
    getInvoiceDetails(id);
  }

  const editCurrentData = async () => {
    const response = await myPromiseModal({title: "Modify Corporate Data",
      body: " Do you want to modify this record?",showInput: false,
      placeHolder: ""});
    if (response.decision === true) {
      setEdit(corporateData);
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`
      );
    }
  };

  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }




  async function bulkActions(action) {
    var currDisplayItem = false;
    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        let temp = "";
        const option = action.Procedure_Option;
        selectedList.map((item) => {
          if (item.Corporate_Id == corporate_id) {
            currDisplayItem = true;
          } else {
            currDisplayItem = false;
          }
          temp += `<a> <refno> ${item.Invoice_Ref_No} </refno> </a>`;
        });

        let xml_string_refno = `<root> ${temp} </root>`;
        invoiceService
          .invoiceBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { message_text } = res.data[0];
              logicContext.showToast(message_text, "success");
              if (currDisplayItem === true) {
                getInvoiceDetails(corporate_id);
              }

              return getInvoiceData();
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  }

  const getInvoiceData = async () => {
    try {
      const response = await invoiceService.getInvoiceData();

      // if (response?.data !== undefined) {
      //   setInvoiceList(response.data["Invoice Info"]);
      //   setInvoiceSummary(response.data["Amount Summary"][0]);
      //   setBulkFilter(response.data["Filtering Criteria"]);
      //   setInvoiceTableHeader(response.data["Display Column"]);
      //   setBulkActions(response.data["Bulk Action"]);
      // }
    } catch (error) {
      logicContext.showToast("connection error getting invoice data", "error");
    }
  };

  const calculateSubTotal = (list) => {
    let result = 0;
    list?.map((item) => {
      result += item.Plan_Sales_Rate * item?.Plan_Sales_Quantity;
    });

    return result;
  };

  const calculateGrandTotal = (list) => {
    let grandTotal = 0;
    list?.map((item) => {
      grandTotal += item.Plan_Sales_Rate * item?.Plan_Sales_Quantity;
    });

    grandTotal = grandTotal - corporateData?.Discount_Amount;
    grandTotal = grandTotal + corporateData?.Shipping_Amount;
    grandTotal = grandTotal + corporateData?.Adjustment_Amount;
    return grandTotal;
  };

  const calculatePayment = (list) => {
    let grandTotal = 0;
    list?.map((item) => {
      grandTotal += item.Payment_Amount;
    });

    return grandTotal;
  };



  const manageStatus = (statusCode, statusName) =>{
    let defaultClass = 'bg-primary text-white';

    switch (statusCode) {
      case 1:
      defaultClass = 'color_1';
        break;
      case  2:
      defaultClass = 'color_2';
        break;
      case 3:
      defaultClass = 'color_3';
        break;
      case 4:
      defaultClass = 'color_4';
        break;
      case 5:
      defaultClass = 'color_5';
        break;
    
      case 6:
      defaultClass = 'color_6';
        break;
    
      default:
        break;
    }

        return (
          <span className={` py-2 px-4 h5 ${defaultClass} rounded`}>
          {statusName}
        </span>
        )

    
      
  }



  return (
    <>
      <section className="  mt-2 ">
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <section className="mt-4 max-auto max-w-6xl d-flex flex-row-reverse ">
              <div className="flex flex-wrap justify-between md:">
                <div>
             
                  <div className="flex ">
                    {/* <SampleFileViewerModal customerData={corporateData} /> */}
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={paper} alt="" className="" />
                    </button>
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={pdf} alt="" className="" />
                    </button>
                    <button
                      style={{ borderRadius: "5px" }}
                      className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                    >
                      <img src={printer} alt="" className="" />
                    </button>

                    <button
                      style={{ borderRadius: "5px" }}
                      onClick={editCurrentData}
                      className="px-3 mr-2 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 "
                    >
                      <img src={pencil} alt="" />
                    </button>
                    <button
                      // onClick={deletePaymentReceive}
                      style={{ borderRadius: "5px" }}
                      className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2"
                    >
                      <img src={trash} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
              <CorporateScroll
                onSelection={onSelection}
                viewItemDetails={viewItemDetails}
              ></CorporateScroll>

              <section className="w-full z-50  ">
                <section className=" ml-[2vw] mt-2 max-auto max-w-6xl text-filterTextColor">
                  {/* Package page  */}

                  <Grid className="mt-2 border-2 p-2" container spacing={2}>
                    <Grid item xs={8} className=" my-2">
                      <div>
                        <span className="#476ef8 font-bold text-primary">{corporateData?.Corporate_Name} </span>
                      </div>
                      <div>
                        <div>Corporate ID :
                          <span className="text-sm font-bold">
                             {corporateData?.Corporate_Short_Code}
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4} className=" my-2">
                    
                        <div>
                          {/* Status */}
                          <span className="text-sm">
                             {manageStatus(corporateData?.Corporate_Approval_Status_Id, corporateData?.Corporate_Approval_Status_Name)}
                          </span>
                        </div>
                   
                    </Grid>
                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div> Activation Date :
                          <span className="text-sm font-bold">
                             {moment(corporateData?.Agreement_Date).format("DD-MM-Y")}
                          </span>
                        </div>
                        <div> No of Employee : 
                          <span className="text-sm font-bold"> {corporateData?.Number_Of_Officer_Name} </span>
                        </div>
                        <div>  Created Time :
                          <span className="text-sm font-bold">
                            {moment(corporateData?.Agreement_Date).format("hh:mm:ss a")}
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div>
                          <span className="text-sm">
                            Email Address : {corporateData?.Corporate_Email_Address}
                          </span>
                        </div>
                        <div>  Contact Person :
                          <span className="text-sm font-bold">
                            {corporateData?.Corporate_Contact_Person}
                          </span>
                        </div>
                        <div> Contact Number :
                          <span className="text-sm font-bold">
                             {corporateData?.Corporate_Contact_Person_Mobile}
                          </span>
                        </div>

                        <div> Address :
                          <span className="text-sm font-bold"> {corporateData?.Corporate_Adddress} </span>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div>
                        Subscription : 
                          <span className="text-sm font-bold">
                            {corporateData?.Subscription_Name}
                          </span>
                        </div>
                        <div>
                        Subscription Desp. : 
                          <span className="text-sm font-bold">
                            {corporateData?.Subscription_Description}
                          </span>
                        </div>
                        <div>
                        Subscription Period:
                          <span className="text-sm font-bold">
                             {corporateData?.Subscription_Period}
                          </span>
                        </div>
                        <div>
                        Default Bank:
                          <span className="text-sm font-bold">
                             {corporateData?.Default_Bank_Transaction_Name}
                          </span>
                        </div>

                        </div>
                        <div>
                        Number of Employee : 
                          <span className="text-sm font-bold">
                            {corporateData?.Number_Of_Officer_Name}
                          </span>
                        <div>
                        Customer(s) :
                          <span className="text-sm font-bold">
                            {corporateData?.Total_Client}
                          </span>
                        </div>
                        <div>
                        Type :
                          <span className="text-sm font-bold"> {corporateData?.Corporate_Type_Name} </span>
                        </div>
                        <div>
                        Remark : 
                          <span className="text-sm font-bold"> </span>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} className="border-2 my-2">
                      <div>
                        <div>Maximum discount  :
                          <span className="text- font-bold">
                             {corporateData?.maximum_discount_percentage}%
                          </span>
                        </div>
                        <div>  Subscription fee(s) :
                          <span className="text-sm  font-bold">
                            <CurrencyFormat value={formatCustomNumber(corporateData?.subscription_fees)}
                         displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                          </span>
                        </div>
                        <div> Subscription activation date :
                          <span className="text-sm font-bold">
                             { moment(corporateData?.corporateData?.subscription_activation_date).format("DD-MM-Y")}
                          </span>
                        </div>
                        <div> Trial : 
                          <span className="text-sm font-bold">{corporateData?.trail_period} {corporateData?.trail_period_type_name} </span>
                        </div>
                        <div>Remark :
                          <span className="text-sm">  </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </section>
              </section>
            </section>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default CorporateDetailsPage;
