

import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";

const subListAtom = atom({key:'subListAtom', default:{item_info:[], production_type:[], 
    subscription_info:[], coupon_info:[]}});
const subHeadTableheader = atom({key:'subHeadTableheader', default:[]});
const submasterdataAtom = atom({key:'submasterdataAtom', default:{}});
const subdetailsAtom = atom({key:'subdetailsAtom', default:{}});
const subEntrymasterData = atom({key:'subEntrymasterData', default:[]});
const subscriptionEditAtom = atom({key:'subscriptionEditAtom', default:{}});

export  const SetSubList = () => useSetRecoilState(subListAtom);
export  const GetSubList = () => useRecoilValue(subListAtom);
export const ResetSubList = ()=> useResetRecoilState(subListAtom);

export  const SetSubScriptionEdit = () => useSetRecoilState(subscriptionEditAtom);
export  const GetSubscriptionEdit = () => useRecoilValue(subscriptionEditAtom);
export const ResetSubscriptionEdit = ()=> useResetRecoilState(subscriptionEditAtom);

export  const SetSubMasterData = () => useSetRecoilState(submasterdataAtom);
export  const GetSubMasterData = () => useRecoilValue(submasterdataAtom);

export  const SetTableHeader = () => useSetRecoilState(subHeadTableheader);
export  const GetTableHeader = () => useRecoilValue(subHeadTableheader);

export  const SetSubEntryMasterdata = () => useSetRecoilState(subEntrymasterData);
export  const GetSubEntryMasterdata = () => useRecoilValue(subEntrymasterData);

export  const SetSubDetails = () => useSetRecoilState(subdetailsAtom);
export  const GetSubDetails = () => useRecoilValue(subdetailsAtom);


