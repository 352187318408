import React, { useEffect, useState, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
// import "./SalesOrderTable.css"
import plus from "../../assets/Plus.svg";
import LogicContext from "../../Context/LogicContext";
import { NumericFormat } from "react-number-format";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SalesOrderSearchItem from "./SalesOrderSalesItem";
import SalesOrderTax from "./SalesOrderTax";
import SalesOrderAmount from "./SalesOrderAmount";
import AddBoxIcon from "@mui/icons-material/AddBox";

function AddSalesOrderTable(props) {
  const [itemTableList, setItemTableList] = useState([]);
  const [tempSelection, setTempSelection] = useState({});
  const logicContext = useContext(LogicContext);

  const currentSelectedItem = (item) => {
    setTempSelection({
      ...item,
      Item_Sales_Quantity: 1,
      Item_Sales_Rate: parseFloat(item.Sales_Rate),
      Account_Id: 0,
      Purchase_Amount: 0,
      Item_Tax_Id: 0,
    });
  };

  const AddToTable = () => {
    if (tempSelection?.Item_Id) {
      const found = itemTableList.find(
        (element) => element.Item_Id === tempSelection.Item_Id
      );
      if (found) {
        return logicContext.showToast(
          "Selected item already in selection",
          "error"
        );
      }
      setItemTableList((prev) => {
        return [...prev, { ...tempSelection }];
      });
      props?.onSelectionList([...itemTableList, { ...tempSelection }]);
    } else {
      return logicContext.showToast("Please select an item", "info");
    }
  };

  useEffect(() => {
    if (props?.defaultList?.length > 0) {
      setItemTableList(props?.defaultList);

      setTimeout(() => {
        props?.onSelectionList([...props?.defaultList]);
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.defaultList?.length]);

  const onQuantityChange = (qty, index) => {
    const newQty = parseInt(qty);
    if (newQty > 0) {
      const currentItem = itemTableList[index];
      const newList = itemTableList?.map((item) => {
        if (item.Item_Id === currentItem.Item_Id) {
          return { ...item, Item_Sales_Quantity: parseFloat(newQty) };
        } else {
          return item;
        }
      });
      setItemTableList([...newList]);
      props?.onSelectionList([...newList]);
    } else {
      setItemTableList([...itemTableList]);
    }
  };

  const onRateChange = (qty, index) => {
    const newQty = parseInt(qty);
    if (newQty > 0) {
      const currentItem = itemTableList[index];
      const newList = itemTableList?.map((item) => {
        if (item.Item_Id === currentItem.Item_Id) {
          return { ...item, Item_Sales_Rate: parseFloat(newQty) };
        } else {
          return item;
        }
      });
      setItemTableList([...newList]);
      props?.onSelectionList([...newList]);
    } else {
      setItemTableList([...itemTableList]);
    }
  };

  function deleteItemFromTable(removedItem) {
    const newList = itemTableList.filter(
      (item) => item.Item_Id !== removedItem.Item_Id
    );
    setItemTableList(newList);
    props?.onSelectionList(newList);
  }

  function onTaxSelect(data, index) {
    const currentItem = itemTableList[index];
    const newList = itemTableList?.map((item) => {
      if (item.Item_Id === currentItem.Item_Id) {
        return { ...item, Item_Tax_Id: data.Tax_Id };
      } else {
        return item;
      }
    });
    setItemTableList(newList);
    props?.onSelectionList(newList);
  }

  function onTaxAccountSelect(data, index) {
    const currentItem = itemTableList[index];
    const newList = itemTableList?.map((item) => {
      if (item.Item_Id === currentItem.Item_Id) {
        return { ...item, Account_Id: data.Account_Id };
      } else {
        return item;
      }
    });
    setItemTableList(newList);
    props?.onSelectionList(newList);
  }

  return (
    <div className="w-full p-6 bg-white border-[1px] border-gray-200 rounded-xl">
      <div className="flex items-center justify-between">
        <div className="w-full">
          <SalesOrderSearchItem currentSelectedItem={currentSelectedItem} />
        </div>
        <div onClick={AddToTable} className="ml-4 cursor-pointer">
          <AddBoxIcon fontSize="large" className="text-success " />
        </div>
      </div>

      {/* end */}

      <div className="mt-6">
        {/*  start table */}
        <div className=" ">
          <TableContainer className="mt-3 h-[250px]">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <colgroup>
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
              <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                <TableRow>
                  <TableCell className="subheading text-filterTextColor">
                    Item Details
                  </TableCell>
                  <TableCell className="subheading text-filterTextColor">
                    Account
                  </TableCell>
                  <TableCell className="subheading text-filterTextColor">
                    Quantity
                  </TableCell>
                  <TableCell className="subheading text-filterTextColor">
                    Rate
                  </TableCell>
                  <TableCell className="subheading text-filterTextColor">
                    Tax
                  </TableCell>
                  <TableCell className="subheading text-filterTextColor">
                    Amount
                  </TableCell>
                  <TableCell className="subheading text-filterTextColor"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemTableList?.map((item, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        className="Table2"
                        style={{ width: "120px " }}
                        align="left"
                      >
                        <span> {item.Item_Name}</span>
                      </TableCell>

                      <TableCell align="left">
                        <SalesOrderAmount
                          defaultVal={{ label: item.Account_Name }}
                          onTaxAccountSelect={(data) =>
                            onTaxAccountSelect(data, i)
                          }
                        />
                      </TableCell>

                      <TableCell align="left">
                        <NumericFormat
                          // value={item.Item_Quantity?.toFixed(2)}
                          value={item.Item_Sales_Quantity}
                          style={{ width: "120px" }}
                          thousandSeparator=","
                          decimalSeparator="."
                          allowedDecimalSeparators={["%"]}
                          onValueChange={(values, sourceInfo) => {
                            onQuantityChange(
                              parseFloat(Number(values.value)),
                              i
                            );
                          }}
                        />
                      </TableCell>

                      <TableCell align="left">
                        <NumericFormat
                          value={item.Item_Sales_Rate?.toFixed(2)}
                          style={{ width: "120px" }}
                          thousandSeparator=","
                          decimalSeparator="."
                          // allowedDecimalSeparators={['.']}
                          onValueChange={(values, sourceInfo) => {
                            onRateChange(values.value, i);
                          }}
                        />
                      </TableCell>

                      <TableCell align="left">
                        <SalesOrderTax
                          defaultVal={{ label: item?.Tax_Name }}
                          onTaxSelect={(data) => onTaxSelect(data, i)}
                        />
                      </TableCell>

                      <TableCell align="left">
                        <CurrencyFormat
                          className={"font-bold "}
                          value={parseFloat(
                            item.Item_Sales_Quantity * item.Item_Sales_Rate
                          )?.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      </TableCell>

                      <TableCell align="left">
                        <DeleteIcon
                          color="error"
                          onClick={() => deleteItemFromTable(item)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* end table */}
      </div>
    </div>
  );
}
export default AddSalesOrderTable;
