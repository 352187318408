import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import rectangle from "../../assets/rectagle.svg";
import { itemsService } from "../../services/items.service";
import ItemTableSelect from "./ItemPageTableSelect";
import {
  SetTItemList,
  GetItemList,
  SetTItemHeaderList,
  GetItemHeaderList,
} from "../../store/itemAtom";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";
// import '../pages/ItemPage.css';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function ItemPageTable(props) {
  const itemListDAta = GetItemList();
  const customHeader = GetItemHeaderList();
  const setItemTableHeader = SetTItemHeaderList();
  const [activeMenu, setActiveMenu] = useState("");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const filterHeader = customHeader?.filter((item) => item.Display_Id === 1);
  const customColumn = filterHeader?.map((item) => {
    return {
      ...item,
      field: item.Item_Column_Value.trim(),
      headerClassName: "bg-gray-200 ",
      headerName: item.Item_Column_Name,
      width: 130,
    };
  });

  useEffect(() => {
    // if(customHeader.length < 1){
    itemsService.getItemTableHeader().then((res) => {
      setItemTableHeader(res?.data);
    });
    // }
  }, []);

  const tableMenuSelect = (option, i) => {
    var choice = option.Display_Id === 1 ? 0 : 1;
    var temp = customHeader?.map((item) => {
      if (item.Ref_No === option.Ref_No) {
        return { ...item, Display_Id: choice };
      }
      return item;
    });
    setItemTableHeader(temp);
  };

  function onSelectChange(selectedList) {
    var selection = selectedList?.map((o) => {
      return itemListDAta?.item_info[o];
    });
    props.selectedItems(selection);
  }

  function OnItemCellClick(event) {
    const itemId = event.row["Item_Id"];
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/item-details/${itemId}`
    );
  }

  function saveCurrentTableHeader() {
    var xmlString = "";
    customHeader?.map((element) => {
      if (element.Display_Id === 1) {
        xmlString += `<a><refno>${element.Ref_No} </refno></a>`;
      }
    });
    itemsService
      .updateItemTableHeader(`<root> ${xmlString} </root>`)
      .then((res) => {});
  }

  return (
    <>
      <div className="dropdown mr-2">
        <button
          className="py-2 btn btn-primary bg-blue-500 hover:bg-blue-400 outline-none active:outline-none focus:outline-none ring-0 active:ring-0 focus:ring-0  dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-toggle="dropdown"
          aria-expanded="false"
        ></button>

        <ul
          className="dropdown-menu  scrollable-menu "
          role="menu"
          aria-labelledby="dropdownMenuButton1"
        >
          {customHeader?.map((val, i) => {
            return (
              <ItemTableSelect
                key={i}
                val={val}
                i={i}
                tableMenuSelect={tableMenuSelect}
              />
            );
          })}

          <div className="row">
            <div className="col">
              {" "}
              <button
                className="btn btn-sm"
                onClick={() => saveCurrentTableHeader()}
              >
                Save
              </button>{" "}
            </div>
            <div className="col">
              {" "}
              <button className="btn btn-sm">Cancel</button>{" "}
            </div>
          </div>
        </ul>
      </div>
      {/* Table data */}

      <div className="mt-4 h-[700px] w-full overflow-x-scroll">
        <DataGrid
          columns={customColumn ? customColumn : []}
          rows={itemListDAta?.item_info.map((item, i) => {
            return {
              ...item,
              Cost_Rate: formatCustomNumber(item.Cost_Rate),
              Sales_Rate: formatCustomNumber(item.Sales_Rate),
              id: i,
            };
          })}
          pageSize={6}
          rowsPerPageOptions={[6]}
          checkboxSelection={true}
          onRowSelectionModelChange={onSelectChange}
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          onCellClick={(event) => {
            event.defaultMuiPrevented = true;
          }}
          onRowClick={(event) => {
            OnItemCellClick(event);
          }}
        />
      </div>
    </>
  );
}

export default ItemPageTable;
