/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useContext, useRef, useCallback } from "react";
import { salesService } from "../../services/sales.service";
import { Dropdown } from "react-bootstrap";
import LogicContext from "../../Context/LogicContext";
import {
  GetSalesOrderList,
  SetSalesOrderList,
} from "../../store/salesOrderAtom";
import CurrencyFormat from "react-currency-format";
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import { useState } from "react";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { useLocation, useNavigate } from "react-router-dom";

import SalesOrderTable from "./SalesOrderTable";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { IoMdRefresh } from "react-icons/io";
import { useForm } from "react-hook-form";
import moment from "moment";
import { FaPlus } from "react-icons/fa";

function SalesOrderPage() {
  const { pathname } = useLocation();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const logicContext = useContext(LogicContext);
  const setSalesOrderData = SetSalesOrderList();
  const salesOrderData = GetSalesOrderList();
  const myPromiseModal = createModal(GeneralModal);

  const [tableData, setTableData] = useState([]);
  const [itemSelected, setItemSelected] = useState([]);
  const [summaryList, setSummaryList] = useState({});
  const mountRef = useRef(false);

  useEffect(() => {
    document.title = "Sales Order ";
    // N:B Sales order data come from salesOrderSearch file
    if (salesOrderData["Sales_Order_Info"]?.length > 0) {
      setTableData(salesOrderData["Sales_Order_Info"]);
    }
  }, [salesOrderData]);

  useEffect(() => {
    if (sessionStorage.getItem("reloadPage") === "true") {
      sessionStorage.removeItem("reloadPage");
      getPurchaseOrderListApi();
    }
  }, []);

  useEffect(() => {
    if (salesOrderData?.Amount_Summary?.length) {
      setSummaryList(salesOrderData?.Amount_Summary[0]);
    }
  }, [salesOrderData]);

  // To reload and  sync sales Order data
  const getPurchaseOrderListApi = async () => {
    salesService.getSalesOrderList().then((response) => {
      if (response?.data !== undefined) {
        setSalesOrderData(response.data);
      }
    });
  };

  const onActionFilterPerform = (action) => {
    const option = action.target.value;
    if (Number(option) === 0) {
      return setTableData(salesOrderData["Sales_Order_Info"]);
    }
    var newList = salesOrderData["Sales_Order_Info"].filter(
      (item) => item.Sales_Order_Status_Id === Number(option)
    );
    setTableData(newList);
  };
  const onButtonFilter = (option) => {
    if (Number(option) === 0) {
      return setTableData(salesOrderData["Sales_Order_Info"]);
    }
    var newList = salesOrderData["Sales_Order_Info"].filter(
      (item) => item.Sales_Order_Status_Id === Number(option)
    );
    setTableData(newList);
  };

  function selectedTableItems(itemList) {
    setItemSelected(itemList);
  }

  function addNewInvoice() {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }

  async function bulkActions(action) {
    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        let temp = "";
        const option = action.Procedure_Option;
        itemSelected.map((item) => {
          temp += `<a> <refno> ${item.Sales_Order_Ref_No} </refno> </a>`;
        });

        let xml_string_refno = `<root> ${temp} </root>`;
        salesService
          .setSalesOrderBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { message_text } = res.data[0];
              logicContext.showToast(message_text, "success");
              return getPurchaseOrderListApi();
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  }

  const searchByDate = async (event) => {
    const formData = getValues();
    const response = await salesService.getSalesOrderListByDate(formData);
    if (response?.data?.length) {
      setTableData(response?.data);
    } else {
      logicContext.showToast("No result found", "");
      setTableData([]);
    }
  };

  return (
    <>
      <div className="grid grid-auto-fit-[180px] gap-3 w-full">
        <div
          onClick={() => onButtonFilter(2)}
          className=" show-cursor bg-white border-[1px] border-gray-200 py-3 px-3 rounded-xl"
        >
          <div className=" text-center text-4xl text-primary font-bold">
            {salesOrderData?.["sales_order_cart_info"]?.[0]?.["Total_Order"] ||
              0}{" "}
          </div>
          <div className="mt-1 text-xs font-medium text-gray-500 text-center">
            Qty
          </div>
          <div className="mt-3 text-sm text-gray-700 text-center font-bold">
            {salesOrderData?.["sales_order_cart_info"]?.[0]?.[
              "Sales_Order_Status_Name"
            ] || ""}
          </div>
        </div>

        <div className="bg-white border-[1px] border-gray-200 py-3 px-3 rounded-xl">
          <div
            onClick={() => onButtonFilter(3)}
            className=" show-cursor text-center text-4xl text-dark font-bold"
          >
            {salesOrderData?.["sales_order_cart_info"]?.[1]?.["Total_Order"] ||
              0}
          </div>
          <div className="mt-1 text-xs font-medium text-gray-500 text-center">
            Qty
          </div>
          <div className="mt-3 text-sm text-gray-700 text-center font-bold">
            {salesOrderData?.["sales_order_cart_info"]?.[1]?.[
              "Sales_Order_Status_Name"
            ] || ""}
          </div>
        </div>

        <div className="bg-white border-[1px] border-gray-200 py-3 px-3 rounded-xl">
          <div
            onClick={() => onButtonFilter(4)}
            className=" text-center text-4xl text-danger font-bold"
          >
            {salesOrderData?.["sales_order_cart_info"]?.[2]?.["Total_Order"] ||
              0}
          </div>
          <div className="mt-1 text-xs font-medium text-gray-500 text-center">
            Qty
          </div>
          <div className="mt-3 text-sm text-gray-700 text-center font-bold">
            {salesOrderData?.["sales_order_cart_info"]?.[2]?.[
              "Sales_Order_Status_Name"
            ] || ""}
          </div>
        </div>

        <div className="bg-white border-[1px] border-gray-200 py-3 px-3 rounded-xl">
          <div
            onClick={() => onButtonFilter(5)}
            className="show-cursor text-center text-4xl text-success font-bold"
          >
            {salesOrderData?.["sales_order_cart_info"]?.[3]?.["Total_Order"] ||
              0}
          </div>
          <div className="mt-1 text-xs font-medium text-gray-500 text-center">
            Qty
          </div>
          <div
            onClick={() => onButtonFilter(6)}
            className="mt-3 text-sm text-gray-700 text-center font-bold"
          >
            {salesOrderData?.["sales_order_cart_info"]?.[3]?.[
              "Sales_Order_Status_Name"
            ] || ""}
          </div>
        </div>

        <div className="bg-white border-[1px] border-gray-200 py-3 px-3 rounded-xl">
          <div
            onClick={() => onButtonFilter(6)}
            className="show-cursor text-center text-4xl text-warning font-bold"
          >
            {salesOrderData?.["sales_order_cart_info"]?.[4]?.["Total_Order"] ||
              0}
          </div>
          <div className="mt-1 text-xs font-medium text-gray-500 text-center">
            Qty
          </div>
          <div className="mt-3 text-sm text-gray-700 text-center font-bold">
            {salesOrderData?.["sales_order_cart_info"]?.[4]?.[
              "Sales_Order_Status_Name"
            ] || ""}
          </div>
        </div>
      </div>

      <div className="mt-6 md:flex items-end justify-between space-y-3 md:space-y-0">
        <div className="flex items-center space-x-2">
          {itemSelected.length > 0 ? (
            <div className="row">
              <div className="">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    Bulk Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {salesOrderData["Bulk_Action"]?.map((action, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          onClick={() => bulkActions(action)}
                        >
                          {action.Action_Name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) : (
            <ActionPaneComponent
              bulkActionData={salesOrderData["Filtering_Criteria"]}
              onSelectBulkAction={onActionFilterPerform}
            />
          )}
          <Tooltip title="Reload">
            <div
              onClick={() => getPurchaseOrderListApi()}
              className="h-fit w-fit p-2 rounded-full cursor-pointer hover:bg-gray-200 active:bg-gray-300 "
            >
              <IoMdRefresh className="h-[20px] w-[20px] text-gray-600 " />
            </div>
          </Tooltip>
        </div>

        {/* date search */}
        <div class="flex flex-row items-end space-x-2">
          <div>
            <span className="ml-1 text-sm ">From Date:</span>
            <input
              defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
              {...register("from_date")}
              className=" bg-white ring-1 ring-gray-200  w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
              type="date"
            />
          </div>

          <div>
            <span className="ml-1 text-sm ">To Date:</span>
            <input
              {...register("to_date")}
              defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
              className=" bg-white ring-1 ring-gray-200  w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
              type="date"
            />
          </div>
          <div>
            <button
              onClick={() => searchByDate()}
              type="button"
              className="text-sm text-white px-3 bg-blue-500 rounded py-2.5"
            >
              Search
            </button>
          </div>
        </div>
        {/* date search end */}

        <div className="flex items-center space-x-4">
          {/* <button className="font-[Poppins] font-semibold py-2 px-3 rounded text-sm">
            IMPORT
          </button>
          <div className="btn-group">
            <button
              className=" font-[Poppins] font-semibold py-2 px-3 rounded text-sm"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              EXPORT
            </button>
          </div> */}
          <Tooltip title="Add New">
            <button
              onClick={addNewInvoice}
              className="ml-2 h-fit w-fit px-3 py-2.5 bg-gray-200 border-[1.5px] border-gray-300 rounded-md text-white focus:outline-none font-medium hover:bg-gray-100 active:bg-gray-200 transition-all duration-200"
            >
              <FaPlus className="h-[16px] w-[16px] text-gray-600" />
            </button>
          </Tooltip>
        </div>
      </div>

      <div className="mt-5">
        <SalesOrderTable
          className="mt-5"
          tableData={tableData}
          selectedTableItems={selectedTableItems}
        />
      </div>
    </>
  );
}

export default SalesOrderPage;
