import { useForm } from "react-hook-form";
import GeneralSelect from "../GeneralSelect";
import underscore from "underscore";
import { useEffect, useState } from "react";
import { customerService } from "../../services/customer.service";

const GiftBeneficiaryForm = ({ processNewGiftPerson, setOpenForm }) => {
  const {
    register, handleSubmit, getValues, setValue, formState: { errors },
  } = useForm();

  const [masterData, setMasterData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const [stateDisplay, setStateDisplay] = useState([]);
  const [displayArea, setDisplayArea] = useState([]);

  useEffect(() => {
    try {
      if(!countryList?.length){
        customerService.getMasterData().then((res) => {
          setMasterData(res?.data);
  
          const masterDataGroup = underscore.groupBy(res?.data, "Master_Type");

          setCountryList(masterDataGroup[1] || []);
          setStateList(masterDataGroup[2] || []);
          setAreaList(masterDataGroup[3] || []);
          setStateDisplay(masterDataGroup[2] || []);
          setDisplayArea(masterDataGroup[3] || []);
        });

      }
      
    } catch (error) {
      console.log("Error ", error);
    }
  }, []);

  const onSubmit = (data) => {
    processNewGiftPerson(data); // This will contain the form values
  };

  const onShippingAreaSelect = (selection) => {
    setValue("shipping_area_id", selection.Code);
  };

  const selectedBillCountry = (selection) => {
    const temp = stateList.filter((city) => city.Parent_Code1 === selection.Code);
    setStateDisplay(temp);
  };

  const onSelectShippingState = (selection) => {
    const temp = areaList.filter((city) => city.Parent_Code1 === selection.Code);
    setDisplayArea(temp);
  };

  return (
    <>
      <h2 className="text-lg font-semibold mb-4">New Beneficiary</h2>
      <form  className="grid grid-cols-2 gap-4">
        {/* First Name */}
        <div className="col-span-2">
          <label className="text-sm font-medium text-gray-700">First Name</label>
          <input
            minLength={3}
            {...register("first_name", { required: "First Name is required" })}
            className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
          />
          <small className="text-red-500">{errors?.first_name?.message}</small>
        </div>

        {/* Last Name */}
        <div className="col-span-2">
          <label className="text-sm font-medium text-gray-700">Last Name</label>
          <input
            minLength={3}
            {...register("last_name", { required: "Last Name is required" })}
            className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
          />
          <small className="text-red-500">{errors?.last_name?.message}</small>
        </div>

        {/* Email Address */}
        <div className="col-span-2">
          <label className="text-sm font-medium text-gray-700">Email</label>
          <input
            {...register("email_address", { required: "Email is required" })}
            className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
          />
          <small className="text-red-500">{errors?.email_address?.message}</small>
        </div>

        {/* Phone Number */}
        <div className="col-span-2">
          <label className="text-sm font-medium text-gray-700">Phone Number</label>
          <input
            minLength={11}
            {...register("phone_number", { required: "Phone No. is required", minLength: { value: 11, message: "Must be 11 numbers" } })}
            className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
          />
          <small className="text-red-500">{errors?.phone_number?.message}</small>
        </div>

        {/* Country/Region */}
        <div>
          <label className="text-sm font-medium text-gray-700">Country/Region *</label>
          <GeneralSelect list={countryList} onOptionSelect={selectedBillCountry} />
        </div>

        {/* State */}
        <div>
          <label className="text-sm font-medium text-gray-700">State *</label>
          <GeneralSelect list={stateDisplay} onOptionSelect={onSelectShippingState} />
        </div>

        {/* Area */}
        <div>
          <label className="text-sm font-medium text-gray-700">Area *</label>
          <GeneralSelect list={displayArea} onOptionSelect={onShippingAreaSelect} />
        </div>

        {/* Address 1 */}
        <div className="col-span-2">
          <label className="text-sm font-medium text-gray-700">Address 1</label>
          <input
            {...register("address_line_one", { required: "Address one is required" })}
            className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
          />
          <small className="text-red-500">{errors?.address_line_one?.message}</small>
        </div>

        {/* Address 2 */}
        <div className="col-span-2">
          <label className="text-sm font-medium text-gray-700">Address 2</label>
          <input
            {...register("address_line_two", { required: "Address two is required" })}
            className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
          />
          <small className="text-red-500">{errors?.address_line_two?.message}</small>
        </div>

        {/* Landmark */}
        <div>
          <label className="text-sm font-medium text-gray-700">Landmark</label>
          <input {...register("landmark")} className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300" />
        </div>

        {/* ZIP Code */}
        <div>
          <label className="text-sm font-medium text-gray-700">ZIP Code</label>
          <input {...register("zip_code")} className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300" />
        </div>

        {/* Shipping Phone Number */}
        <div className="col-span-2">
          <label className="text-sm font-medium text-gray-700">Shipping Phone Number</label>
          <input {...register("shipping_phone_number")} className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300" />
        </div>

        {/* Action Buttons */}
        <div className="col-span-2 flex justify-end gap-2">
          <button type="button" onClick={() => setOpenForm(false)} className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300">
            Cancel
          </button>
          <button  type="button" onClick={handleSubmit(onSubmit)} className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700">
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default GiftBeneficiaryForm;
