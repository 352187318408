import { useEffect } from "react";
import Select from 'react-select';
import {GetSubList} from "../../store/subscriptionAtom";



function AddSubSelectItem(props) {
    const {productFeature} = props;
  const subentryData = GetSubList();

  useEffect(()=> {

  },[props?.defaultVal]);

  useEffect(()=> {

  },[productFeature?.Product_Id]);



  const customOption = subentryData?.item_info?.map((item)=> {
    return ({...item, label:`${item.Item_Name}  ${item.Item_Sku}`})
  })?.filter((item)=> item?.Product_Feature_Id ===  productFeature?.Product_Id );

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 50,
      backgroundColor: '#F1F1F1',
      width: 450,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),

  
    
  }

    const handleChange = (item) => {
      props.onItemSelect(item)
      };


    return ( 
        <>
   
         <Select   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal}
          options={customOption}
         />


        </>
     );
}

export default AddSubSelectItem;
