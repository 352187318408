import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import LogicContext from "../../Context/LogicContext";
import { createModal } from "react-modal-promise";
import GeneralModal from "../../helpers/GeneralModal";
// import SelectSelect from "./StatusSelect";
import { FileUploader } from "react-drag-drop-files";
import { settingsService } from "../../services/settingsService";
import moment from "moment";
import { GetEditAdsStore, SetEditAdsStore } from "../../store/AddsStoreAtom";
// import FeatureSelect from "./FeatureSelect";








function NewAdsPage() {
    const navigate = useNavigate();
    const myPromiseModal = createModal(GeneralModal);
    const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();
    const logicContext = useContext(LogicContext);

    const setEditAds = SetEditAdsStore();
    const getEditAds = GetEditAdsStore();
    const [file, setFile] = useState(null);
    const [imageSource, setImageSource] = useState(null);
    const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];
   



    useEffect(() => {
      
        setValue('ref_no', 0);

        if (getEditAds?.Ref_No) {
            setValue('ad_title', getEditAds.Ad_Title);
            setValue('ad_title', getEditAds.Ad_Description);
            setValue('ad_description', getEditAds.Ad_Description);
            setValue('ad_image_url', getEditAds.Ad_Image_URL);
            setValue('ad_start_date', getEditAds.Ad_Image_URL);
            setValue('ad_end_date', getEditAds.Ad_End_Date);
            setValue('app_action_page',  getEditAds.App_Action_Page);
            setValue('web_action_page', getEditAds.Web_Action_Page);
            setValue('ad_status_id',getEditAds?.Ad_Status_Id);
        
            setImageSource(getEditAds.Ad_Image_URL);
        }

        return () => {
            setEditAds({});
        }
    }, []);


    function startDateFunction() {
        let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
        if (getEditAds?.Ad_Start_Date !== undefined) {
            defaultDate = moment(getEditAds?.Ad_Start_Date).format("YYYY-MM-DD");
        }

        return (
            <>
             <span className="text-sm text-filterTextColor font-bold">
                                        Start Date:
                 </span>
                     <br />
                <input
                    className="bg-inputColor h-[6vh] pl-3  w-[35vw] "
                    placeholder="enter start date"  type="date"
                    defaultValue={defaultDate}
                    {...register("ad_start_date", {
                        required: "Start date is required",
                    })}
                />
            </>

        )
    }



    function endDateFunction() {
        let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
        if (getEditAds?.Ad_End_Date !== undefined) {
            defaultDate = moment(getEditAds?.Ad_End_Date).format("YYYY-MM-DD")
        }

        return (
            <>
             <span className="text-sm text-filterTextColor font-bold">
                    End Date:
                </span>
                <br />
                <input
                    className="bg-inputColor h-[6vh] pl-3  w-[35vw] "
                    placeholder="enter end date" type="date"
                    defaultValue={defaultDate}
                    {...register("ad_end_date", {
                        required: "End date is required",
                    })}
                />
                <br />


            </>

        )
    }

    const onSubmit = async () => {
        const formValues = getValues();
        if (!imageSource) {
            return logicContext.showToast('Please add image', 'error');
        }

        //  Start edit
        if (getEditAds?.Ref_No) {
            await myPromiseModal({ title: "Update record!", body: `Please confirm your action.`,
                showInput: false, placeHolder: ''   }).then((res) => {
                if (res.decision === true) {
                    const formData = new FormData();
               


                    formData.append('file', file);
                 
                    var formObject = JSON.stringify({ ...formValues, ref_no: getEditAds?.Ref_No });
                    formData.append('formObject', formObject);
                    settingsService.updateExistingAds(formData).then(res => {
                        if (res?.data?.length && res?.data[0]?.message_id === 1) {
                            logicContext.showToast(res?.data[0].message_text, 'success');
                            navigate(-1);
                        }
                    });
                }
            });

            // end Edit
        } else {

            //  New record
            await myPromiseModal({
                title: "Save record!", body: `Please confirm your action.`, showInput: false, placeHolder: '' }).then((res) => {
                if (res.decision === true) {

                    const formData = new FormData();
                 
                    formData.append('file', file);
                    var formObject = JSON.stringify(formValues);
                    formData.append('formObject', formObject);
                    for (const [key, value] of formData.entries()) {
                      }
                    settingsService.submitNewAds(formData).then(res => {
                        if (res?.data?.length && res?.data[0]?.message_id === 1) {
                            logicContext.showToast(res?.data[0].message_text, 'success');
                            reset();
                        }
                    });
                }
            });

            // end new record

        }



    }


    function formError(error) {
        console.log('errr ', error);
        logicContext.showToast("Form error", "error");
    }


    const onImageLoad = (event) => {
        const imageWidth = event?.target?.naturalWidth;
        const imageHeight = event?.target?.naturalHeight;
        // setTempHeight(imageHeight);
        // setTempWidth(imageWidth);
        // if (imageHeight !== BASE_HEIGHT){
        //   return logicContext.showToast(`Image height of ${imageHeight}h is unacceptable. Image width and height must be ${BASE_HEIGHT}h and ${BASE_WIDTH}w`, 'error');
        // }else if(imageWidth !== BASE_WIDTH){
        //   return logicContext.showToast(`Image width of ${imageWidth}w is unacceptable. Image width and height must be ${BASE_HEIGHT}h and ${BASE_WIDTH}w`, 'error');
        // }
    }


    const handleImageChange = (imageFile) => {
        setFile(imageFile);
        if (imageFile) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImageSource(reader.result);
            });

            reader.readAsDataURL(imageFile);
        }
    };




    return (
        <>
            <div className="jumbotron bg-light" >

                <div className="row" >
                    <div className="col" >
                        <h6 class="text-lg mb-3 font-bold"> New Ads</h6>
                    </div>
              
                </div>
                <form onSubmit={handleSubmit(onSubmit, formError)} >
                    <section className="">
                        <div  className="row" >

                            <div className="col-6" >
                          <div className="container" >
                          <span className="text-sm text-filterTextColor font-bold ">
                                        Ads title:
                                    </span>
                                    <br />
                                    <input
                                        className="bg-inputColor h-[6vh] pl-3  w-[35vw]"
                                        placeholder="Enter ads title" type="text"
                                        name="feature_name"
                                        {...register("ad_title", {
                                            required: "Ads title is required",
                                        })}
                                    />
                                    <br />
                                    <small className="text-red-500">
                                        {Boolean(errors["ad_title"]?.message) &&
                                            String(errors["ad_title"]?.message)}
                                    </small>

                          </div>

                          <div className="container" >
                          <span className="text-sm text-filterTextColor font-bold ">
                                        Description:
                                    </span>
                                    <br />
                                    <input
                                        className="bg-inputColor h-[6vh] pl-3  w-[35vw]"
                                        placeholder="Enter description" type="text"
                                        name="feature_name"
                                        {...register("ad_description", {
                                            required: "Ads description is required",
                                        })}
                                    />
                                    <br />
                                    <small className="text-red-500">
                                        {Boolean(errors["ad_description"]?.message) &&
                                            String(errors["ad_description"]?.message)}
                                    </small>

                          </div>

                            <div className="container" >
                            {startDateFunction()}
                            </div>

                            <div className="container" >
                            {endDateFunction()}
                            </div>
                            <div className="container" >
                            <span className="text-sm text-filterTextColor font-bold">
                                        Mobile action page:
                                    </span>
                                    <br />
                                    <input
                                        className="bg-inputColor h-[6vh] pl-3  w-[35vw] "
                                        placeholder="Enter mobile action page" type="text"
                                        name="slide_order"
                                        {...register("app_action_page", {
                                            required: "Slide order is required",
                                        })}

                                    />
                                    <br />
                                    <small className="text-red-500">
                                        {Boolean(errors["app_action_page"]?.message) &&
                                            String(errors["app_action_page"]?.message)}
                                    </small>

                            </div>

                            <div className="container" >
                            <span className="text-sm text-filterTextColor font-bold">
                                        Web action page:
                                    </span>
                                    <br />
                                    <input
                                        className="bg-inputColor h-[6vh] pl-3  w-[35vw] "
                                        placeholder="Enter web action page" type="text"
                                        name="slide_order"
                                        {...register("web_action_page", {
                                            required: "Slide order is required",
                                        })}

                                    />
                                    <br />
                                    <small className="text-red-500">
                                        {Boolean(errors["web_action_page"]?.message) &&
                                            String(errors["web_action_page"]?.message)}
                                    </small>

                            </div>


                            <div className="container" >
                            <span className="text-sm text-filterTextColor font-bold">
                                        Button text:
                                    </span>
                                    <br />
                                    <input
                                        className="bg-inputColor h-[6vh] pl-3  w-[35vw] "
                                        placeholder="Enter button text" type="text"
                                        name="ad_button_text"
                                        {...register("ad_button_text", {
                                            required: "Button text is required",
                                        })}

                                    />
                                    <br />
                                    <small className="text-red-500">
                                        {Boolean(errors["ad_button_text"]?.message) &&
                                            String(errors["ad_button_text"]?.message)}
                                    </small>

                            </div>

                            </div>

                            <div className="col-6" >
                            <div style={{ width: '10px' }}>

                                <FileUploader
                                    className="uploader"
                                    id="uploader"
                                    minSize={0.1}
                                    maxSize={3}
                                    handleChange={handleImageChange}
                                    name="file"
                                    types={fileTypes}
                                    multiple={false}
                                />
                            </div>

                            {/* Preview */}
                            <div className="flex justify-left mb-4">
                            <img className="m-1 rounded-full" onLoad={(e) => onImageLoad(e)}
                                src={imageSource} alt="" height="200px" width="200px" />
                        </div>
                            {/* Preview end */}

                            </div>
                        </div>
                 

                        <div className=" flex justify-end ">
                            <button
                                type="submit"
                                className="rounded-lg px-10  hover:bg-sky-700  my-3 py-2 bg-blue-500 text-blue-100"
                            >
                                Submit
                            </button>
                        </div>
                    </section>
                </form>
            </div>
        </>
    );
}

export default NewAdsPage;





