import 'antd/dist/antd.min.css';
import {Table, Switch, Button} from 'antd';
import { EditOutlined , HolderOutlined, SyncOutlined, DeleteOutlined} from '@ant-design/icons';
import {  useLocation, useNavigate } from "react-router-dom";





function IVRCustomerTable({tableList, removeFromSelection}) {
  const navigate = useNavigate();
const { pathname } = useLocation();  
var url = pathname.split('/')
const currModuleId = url[1];
const currModuleName = url[2];
const currUserMenu = url[3];

    const paginationOptions = {
        pageSize: 10,
        showSizeChanger: true,
        total: tableList.length,
        // onChange: (page, pageSize) => {
        //   const newList = tableDataList.slice(0, pageSize);
        //   setTableList(newList);
        // },
      };

    const columns = [
        {
          title: 'Customer Name',
          key: 'Name',
          dataIndex: 'Name',
        //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
        },
        {
          title: 'Customer ID',
          key: 'Customer_Id',
          dataIndex: 'Customer_Id',
        //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
        },
        {
          title: "Mobile Number",
          dataIndex: "Mobile",
          // sorter: (a, b) => a.Ref_No.localeCompare(b.Ref_No)
        },
      
        {
          title: "Type",
          dataIndex: "Customer_Type_Name",
        //   sorter: (a, b) => a.Earn_Title.localeCompare(b.Earn_Title)
        },
      
     
     
        {
            title: 'Delete',
            render: (toggle, data) => (
              <DeleteOutlined  style={{ fontSize: '20px', color:'blue' }}
               onClick={(record) => removeFromSelection(data)} />
            ),
            
          },
    
      
      ];


      const onRowClick = (record) => {
        return {
          onClick: () => {
          },
        };
      };


    
  

    return (
        <>
         <Table key={tableList?.length} columns={columns} dataSource={tableList}
        pagination={paginationOptions}
        onRow={(record) => ({
          onClick: () => {
            onRowClick(record);
          },
          // onMouseEnter: () => handleRowMouseEnter(record.key),
          // onMouseLeave: handleRowMouseLeave,
        })}
         style={{ background: '#f0f2f5', borderRadius: '8px' }} />
        </>
      );
}

export default IVRCustomerTable;