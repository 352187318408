import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
// import "./addSub.css";
import { purchaseService } from "../services/purchase.service";
import { accountService } from "../services/accountService";
import TextareaAutosize from '@mui/material/TextareaAutosize';

import LogicContext from "../Context/LogicContext";
import { NumericFormat } from 'react-number-format';
import {
  GetAddPurchaseReceive, ResetAddPurchaseReceive, SetAddPurchaseReceive,
  GetCustomReceive, ResetCustomReceive} from "../store/purchaseReceiveAtom";
import CancelIcon from '@mui/icons-material/Cancel';

import { useForm, Controller } from "react-hook-form";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import GeneralModal from "../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { useSearchParams } from 'react-router-dom';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap";




function AddPurchaseReceivePage() {
  const editableReceive = GetAddPurchaseReceive();
  const setAddPurchaseList = SetAddPurchaseReceive();
  const getCustomReceive = GetCustomReceive();
  const resetCustomReceive = ResetCustomReceive();
  const resetEdit = ResetAddPurchaseReceive();
  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();
  const logicContext = useContext(LogicContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const purchaseOrderIdModify = searchParams.get('purchase_order_ref');
  const customOption = searchParams.get('option');




  useEffect(()=> {
    if(getCustomReceive?.Purchase_Receive_Ref_No != null ||
       getCustomReceive?.Purchase_Receive_Ref_No !== undefined ){
      setValue('Purchase_Receive_Ref_No', getCustomReceive.Purchase_Receive_Ref_No);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getCustomReceive?.Purchase_Receive_Ref_No])


  
  useEffect(() => {

    return () => {
      resetEdit();
      resetCustomReceive();
    };

  }, [])


  useEffect(() => {

    try {
      if(purchaseOrderIdModify !== null){
        setValue('Purchase_Order_Ref_No', purchaseOrderIdModify);
        accountService.getPurchaseDetails(purchaseOrderIdModify).then(res => {
          let existedList = res.data.Pending_Receive;
   
          var customList = [];
          let customItemList = getCustomReceive?.Purchase_Receive_details;
           existedList?.forEach(element => {
        
              customItemList?.forEach((item, i)=> {
                if (element.Item_Id ===  item.Item_Id){
                  customList.push(element);
                }
              });
            
          });
          setAddPurchaseList({'Purchase_Order_Ref_No':purchaseOrderIdModify, list:customList})
        });
  
      }else{
        if (editableReceive?.list?.length > 0) {
          setValue('Purchase_Order_Ref_No', editableReceive.Purchase_Order_Ref_No)
        }
      }
  
      
    } catch (error) {
      logicContext.showToast('Something went wrong', 'danger')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  const onSubmit = async (option) => {
    const receiveForm = getValues();

    //  validate
    if (editableReceive.list.length === 0) {
      logicContext.showToast("empty list cannot be submitted!", "error");
      return navigate(-1);
    }



    await myPromiseModal({
      title: "Confirmation", body: " Do you want to save this record?",
      showInput:false, placeHolder:''}).then((res) => {
      if (res.decision === true) {
        var option = customOption;
        if(purchaseOrderIdModify !== null){
          option = 3;
        }
        const newData = getValues();
        var tempStringList = editableReceive.list?.map((item) => {
     

          let temp = `<a> <item_id>${item.Item_Id} 
           </item_id> <item_quantity>${item.Remaining_Quantity } </item_quantity> </a>`;
          return temp;
        });

        purchaseService.submitPurchaseReceive({ ...newData, option, xml_string_item: `<root>${tempStringList} </root>` }).then((res) => {
          if (res?.data !== undefined) {
            const { message_text, message_id } = res.data[0];
            if (message_id === 1) {
              logicContext.showToast(message_text, "success");
              navigate(-1);
            } else {
              return logicContext.showToast(message_text, "error");
            }
          }
        });
      }
    }).catch((err) => {
        logicContext.showToast("error submitting form, please try again", "error");
      });
  };



  function onErrors(error) {
    console.error("Errorrrrr ", error);
    logicContext.showToast("Form error", "error");
  }



  function expectedDeliveryDate() {
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
    if (getCustomReceive?.Purchase_Receive_Date_String !== undefined) {
      defaultDate = moment(getCustomReceive?.Purchase_Receive_Date_String, 'DD/MM/YYYY').format("YYYY-MM-DD");
    }
    return (
      
        <input
          defaultValue={defaultDate}
          key={defaultDate}
          className="ml-3 bg-filterInput  pl-3  rounded max-h-full w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
          type="date"
          {...register("Purchase_Receive_Date", {
            required: "purchase receive date is required",
          })}
        />
    
    );
  }


  async function exitPage() {
    await myPromiseModal({
      title: "Exit page", body: "Are you sure you want to leave this page?",
      showInput:false, placeHolder:''}).then((res) => {
      if (res.decision === true) {
        navigate(-1)
      }
    })

  }


  const onRemainingQtyChange = async(qty, index) => {
    const newQty = parseInt(qty)
    let receivedCount = await receivedQty();
    let totalOrderedCount = await orderedQuantity();
    const result =  receivedCount + newQty;

    // if(purchaseOrderIdModify !== null){
    //   if (newQty > 0) {
    //     if(result > totalOrderedCount){
    //        logicContext.showToast('You have exceeding the ordered quantity')
    //     }
    // }

    // }
  
  


      const currentItem = editableReceive.list[index];
      const newList = editableReceive.list?.map((item) => {
        if (item.Item_Id === currentItem.Item_Id) {
          return { ...item, Remaining_Quantity: parseInt(newQty) }
        } else {
          return item;
        }

      });
      let newData = { Purchase_Order_Ref_No: editableReceive?.Purchase_Order_Ref_No, list: newList }
      setAddPurchaseList(newData);
  }


  function deleteItemFromTable(removedItem) {
    const newList = editableReceive.list.filter((item) => item.Item_Id !== removedItem.Item_Id);
    let newData = { Purchase_Order_Ref_No: editableReceive?.Purchase_Order_Ref_No, list: newList }
    setAddPurchaseList(newData);
  }


  function orderedQuantity() {
    let result = 0;
    editableReceive?.list?.forEach(element => {
      result += element.Purcahse_Order_Quantity;
    });
    return result;
  }


  function remainingQty() {
    let orderedQty = 0;
    let received = 0;
    editableReceive?.list?.forEach(element => {
      orderedQty += element.Purcahse_Order_Quantity;
      received += element.Purcahse_Receive_Quantity;
    });

    let result = orderedQty - received ;
    return result;
  }


  function receivedQty() {
    let result = 0;
    editableReceive?.list?.forEach(element => {
      result += element.Purcahse_Receive_Quantity;
    });
    return result;
  }


  function receivingQty() {
    let result = 0;
    editableReceive?.list?.forEach(element => {
      result += element.Remaining_Quantity;
    });
    return result;
  }



  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <section className="container  max-auto  ">

          <div className="cleartext" >
            <div className="float-right" >
              <CancelIcon onClick={() => exitPage()} fontSize="large" className=" cursor" />
            </div>

          </div>

          <div className="container">
            <div className="row">
              <div className="col">

                <div className="input-group" >
                  <div className="input-group-prepend">
                    <span className="input-group-text text-danger" id="">Purchase Receive #</span>
                  </div>
                  <input value={getCustomReceive?.Purchase_Receive_Number} readOnly={true}
                   placeholder="auto generated" type="text" className="ml-3 form-control" />
                </div>

                <div className="input-group mt-4 " >
                  <div className="input-group-prepend w-full flex ">
                    <div className=" w-[12vw] input-group-text  text-danger" id="">Receive Date</div>
                    <div className="">
                      {expectedDeliveryDate()}
                    </div>
                  </div>
                  {/* <input type="text" className="form-control" /> */}

                </div>

              </div>

              <div className="col">
                {/* One of three columns */}
              </div>
            </div>
          </div>


          <div className="mt-5 border ">

            <TableContainer className="mt-5 w-full">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {/* <colgroup>
                    <col style={{width:'30%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'10%'}}/>
                </colgroup> */}
                <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                  <TableRow>
                    <TableCell className="subheading">Items and Description</TableCell>

                    <TableCell className="subheading"> Quantity Ordered</TableCell>
                    <TableCell className="subheading"> Quantity  Received </TableCell>
                    <TableCell className="subheading"> Quantity to Receive </TableCell>
                    <TableCell className="subheading">  </TableCell>


                  </TableRow>
                </TableHead>

                <TableBody className=" mb-3">
                  {editableReceive.list?.map((item, i) => {
                    return (
                      <TableRow className="border-1"
                        key={i}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell className="subheading" align="left">{item?.Item_Name} </TableCell>
                        <TableCell className="subheading" align="left">
                          {item?.Purcahse_Order_Quantity}
                        </TableCell>
                        <TableCell className="subheading" align="left">{item?.Purcahse_Receive_Quantity}  </TableCell>
                        <TableCell className="subheading" align="left">
                          <NumericFormat
                            value={item?.Remaining_Quantity}
                            style={{ width: "120px" }}
                            thousandSeparator=","
                            decimalSeparator="."
                            allowedDecimalSeparators={['%']}
                            onValueChange={(values, sourceInfo) => {
                              onRemainingQtyChange(parseFloat(Number(values.value)), i)
                            }}
                          />
                        </TableCell>
                        <TableCell className="subheading" align="right">
                          <DeleteIcon onClick={() => deleteItemFromTable(item)} className="text-danger" />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

          </div>



          <div className="container mt-3 ">
            <div className="row border" >
              <div className="col-6 offset-3">
                <ul className="list-group list-group-flush">

                  <li className="list-group-item subheading">Ordered Quantity
                    <span className="float-right"> {orderedQuantity()} </span> </li>

                  <li className="list-group-item subheading">Total Remaining
                    <span className="float-right"> {remainingQty()}</span>  </li>
                  <li className="list-group-item subheading font-bold">Received Quantity
                    <span className="float-right"> {receivedQty()}</span> </li>
                  <li className="list-group-item subheading font-bold">Receiving Quantity
                    <span className="float-right"> {receivingQty()}</span> </li>
                </ul>
              </div>
            </div>


          </div>

<section className="w-full mt-5">
  
<div>
              <TextareaAutosize className="mt-5"
                aria-label="minimum height"
                minRows={3}
                placeholder="Purchase receive notes (optional)"
                style={{ width: 300 }}
              />
            </div>

          
                
     
              <div className="w-full my-2 flex justify-between" >
                <div  onClick={() => exitPage()} className="btn btn-outline-dark  " >Cancel</div>
                <div   
                 className="btn btn-primary  " onClick={() => onSubmit()} >Save</div>
              </div>
            

</section>
        </section>
      </form>


    </>
  );
}

export default AddPurchaseReceivePage;

