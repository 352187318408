/* eslint-disable jsx-a11y/anchor-is-valid */

import { itemsService } from "../../../services/items.service";
import { useEffect, useState, useContext } from "react";
import underscore from "underscore";
import { useForm } from "react-hook-form";
import "../ItemInformation.css";
import TypeSelect from "./TypeSelect";
import ItemReturnable from "./ItemReturnable";
import DimentionSelect from "./DimensionSelect";
import AddItemTrackingTypeCheckRadioButton from "./AddItemTrackingTypeCheckRadioButton";
import {
  GetItemToEdit,
  SetItemMasterData,
  GetItemMasterData,
  SetItemToEdit,
} from "../../../store/itemAtom";
import { useNavigate, useParams } from "react-router-dom";
import GeneralDropDown from "../../../helpers/GeneralDropDown";
import NotificationContext from "../../../Context/NotificationContext";

import GeneralModal from "../../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../../Context/LogicContext";
import { Link, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import ImageSlotSelection from "../../image_upload/ImageSlotSelection";
import AddItemFormGroupSelect from "../../item_group/AddItemFormGroupSelect";

const slot_info = [
  { Code: 0, Name: "Please Select" },
  { Code: 1, Name: "1" },
  { Code: 2, Name: "2" },
  { Code: 3, Name: "3" },
  { Code: 4, Name: "4" },
  { Code: 5, Name: "5" },
  { Code: 6, Name: "6" },
  { Code: 7, Name: "7" },
  { Code: 8, Name: "8" },
  { Code: 9, Name: "9" },
  { Code: 10, Name: "10" },
  { Code: 11, Name: "11" },
];

function AddItemForm(props) {
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const myPromiseModal = createModal(GeneralModal);
  const [tempForm, setTempForm] = useState({});
  const setItemToEdit = SetItemToEdit();
  const itemToEdit = GetItemToEdit();

  const getMasterData = GetItemMasterData();
  const notificationContext = useContext(NotificationContext);
  const [defaultCurrency, setDefaultCurrency] = useState([]);
  const [productUnit, setProductUnit] = useState([]);
  const [productBrand, setProductBrand] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [productManufacturer, setProductManufacturer] = useState([]);
  const [productDimension, setProductDimension] = useState([]);
  const [productWeight, setProductWeight] = useState([]);
  const [salesAccounts, setSalesAccounts] = useState([]);
  const [purchaseAccounts, setPurchaseAccounts] = useState([]);
  const [inventoryAccounts, setInventoryAccounts] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [itemtypeList, setItemtypeList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [purchaseCheck, setPurchaseCheck] = useState(false);
  const [salesCheck, setSalesCheck] = useState(false);
  const [trackCheck, setTrackingCheck] = useState(false);
  const [outOfstock, setOutOfStock] = useState(true);
  const [productShare, setProductShare] = useState(false);

  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const { option } = useParams();

  useEffect(() => {
    notificationContext.getMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMasterData?.length]);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    setValue("Item_Type_Id", 1);
    setValue("Item_Weight_Id", 1);
    if (itemToEdit.Sales_Accounts_Id) {
      setSalesCheck(true);
    }

    setOutOfStock(itemToEdit.Item_Stock_Out);

    if (itemToEdit.Product_Sharing_Status_Id) {
      setProductShare( itemToEdit?.Product_Sharing_Status_Id === 1 ? true : false
      );
    }
    if (itemToEdit.Purchase_Accounts_Id) {
      setPurchaseCheck(true);
    }
    if (itemToEdit.Inventory_Accounts_Name) {
      setTrackingCheck(true);
    }
    if (itemToEdit?.Item_Id) {
      //  const cleanItemToEdit =  deleteByVal(null, itemToEdit);
  
      setValue("Item_Weight_Id", itemToEdit?.Item_Weight_Id);
      setValue("Manufacturer_Id", itemToEdit?.Manufacturer_Id);
      setValue("Item_Dimension_Unit_Id", 1); // just to have default
      setValue("Item_Dimension_Unit_Id", itemToEdit?.Item_Dimension_Unit_Id);
      setValue("Item_Id", itemToEdit?.Item_Id);
      setValue("Item_Type_Id", itemToEdit?.Item_Type_Id);
      setValue("Item_Returnable_Id", itemToEdit?.Item_Returnable_Id);
      setValue("Item_Name", itemToEdit?.Item_Name);
      setValue("Item_Category_Id", itemToEdit?.Item_Category_Id);
      setValue("Item_Unit_Id", itemToEdit?.Item_Unit_Id);
      setValue("Item_Brand_Id", itemToEdit?.Item_Brand_Id);
      setValue("Item_Dimension_Length", itemToEdit?.Item_Dimension_Length);
      setValue("Item_Dimension_Width", itemToEdit?.Item_Dimension_Width);
      setValue("Item_Dimension_Height", itemToEdit?.Item_Dimension_Height);
      setValue("Item_Weight", itemToEdit?.Item_Weight);
      setValue("Item_Sku", itemToEdit?.Item_Sku);
      setValue("Item_ISBN", itemToEdit?.Item_ISBN);
      setValue("Item_UPC", itemToEdit?.Item_UPC);
      setValue("Item_EAN", itemToEdit?.Item_EAN);
      setValue("Item_MPN", itemToEdit?.Item_MPN);
      setValue("Market_Rate", itemToEdit?.Market_Rate);
      setValue("Sales_Rate", itemToEdit?.Sales_Rate);
      setValue("Cost_Rate", itemToEdit?.Cost_Rate);
      setValue("Sales_Accounts_Id", itemToEdit?.Sales_Accounts_Id);
      setValue("Sales_Description", itemToEdit?.Sales_Description);
      setValue("Sales_Tax_Id", itemToEdit?.Sales_Tax_Id);
      setValue("Purchase_Accounts_Id", itemToEdit?.Purchase_Accounts_Id);
      setValue("Purchase_Description", itemToEdit?.Purchase_Description);
      setValue("Purchase_Tax_Id", itemToEdit?.Purchase_Tax_Id);
      setValue("Item_Tracking_Need_Id", itemToEdit?.Item_Tracking_Need_Id);
      setValue("Inventory_Accounts_Id", itemToEdit?.Inventory_Accounts_Id);
      setValue("Preferred_Vendor_Id", itemToEdit?.Preferred_Vendor_Id);
      setValue("Item_Variety_Id", itemToEdit?.Item_Variety_Id);
      setValue("Item_Group_Id", itemToEdit?.Item_Group_Id);
      setValue(  "Product_Sharing_Status_Id", itemToEdit?.Product_Sharing_Status_Id);
      setValue("zoho_item_id", itemToEdit?.zoho_item_id);
      setValue("Item_Weight_Id", itemToEdit?.Item_Weight_Id);
      setValue("item_tracking_type_id", itemToEdit?.item_tracking_type_id);
      setValue("option_allow", itemToEdit?.option_allow);
      setValue("Sharing_Slot_Id", itemToEdit?.Sharing_Slot_Id);
      setValue("Minimum_Payment", itemToEdit?.Minimum_Payment);
      setValue("Reorder_Quantity", itemToEdit?.Reorder_Quantity);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToEdit?.Item_Id]);

  useEffect(() => {
    var masterDataGroup = underscore.groupBy(getMasterData, "Master_Type");
    setDefaultCurrency(masterDataGroup[1]);
    setProductUnit(masterDataGroup[2]);
    setProductCategory(masterDataGroup[3]);
    setProductDimension(masterDataGroup[4]);
    setProductWeight(masterDataGroup[5]);
    setProductManufacturer(masterDataGroup[6]);
    setProductBrand(masterDataGroup[7]);
    setSalesAccounts(masterDataGroup[8]);
    setPurchaseAccounts(masterDataGroup[9]);
    setTaxList(masterDataGroup[10]);
    setInventoryAccounts(masterDataGroup[11]);
    setVendors(masterDataGroup[12]);
    setItemtypeList(masterDataGroup[13]);
    setGroupList(masterDataGroup[14]);
  }, [getMasterData]);

  function selectedBrand(selection) {
    setValue("Item_Brand_Id", selection.Code);
  }

  function selectedCategory(selection) {
    setValue("Item_Category_Id", selection.Code);
  }

  function selectedItemType(selection) {
    setValue("Item_Variety_Id", selection.Code);
    // props.controlAssociate(selection.Code);
  }

  function selectedUnitType(selection) {
    setValue("Item_Unit_Id", selection.Code);
  }

  function selectedManufacturer(selection) {
    setValue("Manufacturer_Id", selection.Code);
  }

  function selectedDimensionUnit(selection) {
    setValue("Item_Dimension_Unit_Id", selection);
  }

  function selectedProductWeight(selection) {
    setValue("Item_Weight_Id", selection);
  }

  function selectedSalesAccount(selection) {
    setValue("Sales_Accounts_Id", selection.Code);
  }

  function selectedPurchaseAccount(selection) {
    setValue("Purchase_Accounts_Id", selection.Code);
  }

  function selectedInventoryAccount(selection) {
    setValue("Inventory_Accounts_Id", selection.Code);
  }

  function salesTaxList(selection) {
    setValue("Sales_Tax_Id", selection.Code);
  }

  function selectPurchaseTax(selection) {
    setValue("Purchase_Tax_Id", selection.Code);
  }

  function selectDefaultCurrency(selection) {
    setValue("Currency_Id", selection.Code);
  }

  function selectedVendor(selection) {
    setValue("Preferred_Vendor_Id", selection.Code);
  }

  function addItemType(type) {
    setValue("Item_Type_Id", type);
  }

  function addItemReturnable(type) {
    setValue("Item_Returnable_Id", type);
  }

  function addTrackingForItem(type) {
    if (type === true) {
      setTrackingCheck(true);
      setValue("Item_Tracking_Need_Id", 1);
    } else {
      setValue("Item_Tracking_Need_Id", 2);
      setTrackingCheck(false);
    }
  }

  function addItemTrackingType(type) {
    setValue("item_tracking_type_id", type);
  }

  const onOutOfStockChange = (event) => {
    const decision = event.target.checked;
    let stock_out = decision ? 1 : 0;
    setValue("stock_out", stock_out);
    setOutOfStock(decision);
  };

  const onProductIsGrooPoolChange = (event) => {
    const decision = event.target.checked;
    let sharing = decision ? 1 : 0;
    setValue("Product_Sharing_Status_Id", sharing);
    setProductShare(decision);
  };

  const onSlotSelection = (sel) => {
    setValue("Sharing_Slot_Id", sel.Code);
  };

  const onGroupSelect = (sel) => {
    setValue("Item_Group_Id", sel.Code);
  };

  const onSubmit = async () => {
    let formValues = getValues();

    const slotNumber =   formValues?.Sharing_Slot_Id || 0;
    if ((productShare === true) && (slotNumber <  1)) { // check if product is Gropopl
      return logicContext.showToast(
        "Since item is Gropool, you need to select product slot number!",
        ""
      );
    }

    await myPromiseModal({
      title: "Confirmation",
      body: ` Do you want to ${  itemToEdit?.Item_Id ? "update" : " save" } this record?`,
      showInput: false,
      placeHolder: "",
    }).then((response) => {
      if (response.decision === true) {
        submitItemForm({ ...formValues, option_allow: 0 });
      }
    });
  };

  // strart
  async function submitItemForm(form) {
    setTempForm(form);
    if (itemToEdit?.Item_Id) {
      // Update existing item.
      itemsService.updateItem({ ...form, option_allow: 1 }).then((res) => {
        let message_id = undefined;
        let message_text = undefined;
        let message_ref_no = undefined;
        if (res.data?.length) {
          message_id = res.data[0]?.message_id;
          message_text = res.data[0]?.message_text;
          message_ref_no = res.data[0]?.message_ref_no;
        }
        if (res.data?.result_status?.length) {
          message_id = res.data?.result_status[0]?.message_id;
          message_text = res.data?.result_status[0]?.message_text;
          message_ref_no = res.data?.result_status[0]?.message_ref_no;
        }

        if (message_ref_no === form.Item_Name) {
          return logicContext.showToast(message_text, "error");
        } else if (message_ref_no === form.Item_Sku) {
          return logicContext.showToast(message_text, "error");
        }

        if (message_ref_no === form.Item_Name) {
          return logicContext.showToast(message_text, "error");
        } else if (message_ref_no === form.Item_Sku) {
          return logicContext.showToast(message_text, "error");
        } else {
          const newItem = res.data?.item_info[0];

          if(newItem?.Item_Id){
            setItemToEdit(newItem); // set the updated item that has just been created as an edit

          }
     
         
          if (newItem?.Item_Variety_Id === 2) {
            // for composite Item only
            associateItemsPrompt(newItem?.Item_Id);
          }
          logicContext.showToast(`${message_text}`, "success");
        } // End of update
      });
    } else {
      //  save new item
      itemsService.saveNewItem(form).then((res) => {
          let message_id = undefined;
          let message_text = undefined;
          let message_ref_no = undefined;
          if (res.data?.length) {
            message_id = res.data[0]?.message_id;
            message_text = res.data[0]?.message_text;
            message_ref_no = res.data[0]?.message_ref_no;
          }
          if (res.data?.result_status?.length) {
            message_id = res.data?.result_status[0]?.message_id;
            message_text = res.data?.result_status[0]?.message_text;
            message_ref_no = res.data?.result_status[0]?.message_ref_no;
          }
          if (message_ref_no === form.Item_Name) {
            return logicContext.showToast(message_text, "error");
          } else if (message_ref_no === form.Item_Sku) {
            return logicContext.showToast(message_text, "error");
          } else {
            const newItem = res.data?.item_info[0];
            if(newItem?.Item_Id){
              setItemToEdit(newItem); // set the new updated item that has just been created as an edit
  
            }
       
       
            // reset({});
            if (newItem?.Item_Variety_Id === 2) {
              // for composite Item only
              associateItemsPrompt(newItem?.Item_Id);
            }
            logicContext.showToast(`${message_text}`, "success");
          }
        })
        .catch((err) => {
          logicContext.showToast("error saving record!", "error");
        });
    }
  }


  //

  async function associateItemsPrompt(item_id) {
    if (option == 1) {
      await myPromiseModal({
        title: "Add Associated Items",
        body: " Do you want to add associated items?",
        showInput: false,
        placeHolder: "",
      }).then((result) => {
        if (result.decision === true) {
          props.changeTab("2");
        }
      });
    }
    if (option == 2) {
      await myPromiseModal({
        title: "New item has been saved!",
        body: " Do you want to back production?",
        showInput: false,
        placeHolder: "",
      }).then((result) => {
        if (result.decision === true) {
          return navigate(
            `/${currModuleId}/${currModuleName}/production-setup/production-setup-details/${item_id}`
          );
        }
      });
    }

    if (option == 3) {
      await myPromiseModal({
        title: "New item has been saved!",
        body: " Do you want to go back production?",
        showInput: false,
        placeHolder: "",
      }).then((result) => {
        if (result.decision === true) {
          return navigate(
            `/${currModuleId}/${currModuleName}/production-setup/production-setup-details/${item_id}`
          );
        }
      });
    }

    if (option == 4) {
      await myPromiseModal({
        title: "New item has been saved!",
        body: " Do you want to go back to Composite Group?",
        showInput: false,
        placeHolder: "",
      }).then((result) => {
        if (result.decision === true) {
          return navigate(
            `/${currModuleId}/${currModuleName}/composite-item-groups/add-new`
          );
        }
      });
    }
  }

  function onErrors(error) {
    logicContext.showToast("One or more value is required!", "error");
  }

  function manageCategoryList() {
    notificationContext.showModal({
      sql_ref_no_send: 27,
      newOption: 1,
      updateOption: 2,
      sql_ref_no_get: 28,
      option_get: 1,
    });
  }

  function manageUnitList() {
    notificationContext.showModal({
      sql_ref_no_send: 27,
      newOption: 5,
      updateOption: 6,
      sql_ref_no_get: 28,
      option_get: 3,
    });
  }

  function manageBrandList() {
    notificationContext.showModal({
      sql_ref_no_send: 27,
      newOption: 3,
      updateOption: 4,
      sql_ref_no_get: 28,
      option_get: 2,
    });
  }

  function manageManufacturer() {
    notificationContext.showModal({
      sql_ref_no_send: 27,
      newOption: 7,
      updateOption: 8,
      sql_ref_no_get: 28,
      option_get: 4,
    });
  }

  function manageTaxList() {
    notificationContext.showModal({
      sql_ref_no_send: 27,
      newOption: 13,
      updateOption: 14,
      sql_ref_no_get: 28,
      option_get: 7,
    });
  }

  function manageVendorList() {
    notificationContext.showModal({
      sql_ref_no_send: 27,
      newOption: 15,
      updateOption: 16,
      sql_ref_no_get: 28,
      option_get: 8,
    });
  }

  return (
    <>
      <form className="" onSubmit={handleSubmit(onSubmit, onErrors)}>
        <div className="">
          {/* TYPE SECTION */}

          <div className="grid md:grid-cols-3 gap-6">
            <div>
              <TypeSelect
                val={itemToEdit?.Item_Type_Id}
                addItemType={addItemType}
              ></TypeSelect>
                {/* returnable */}
                <ItemReturnable
                defaultVal={itemToEdit?.Item_Returnable_Id}
                addItemReturnable={addItemReturnable}
              />
            </div>
            <div className="" >
            <label className="text-sm text-gray-600 font-medium">
                Select Group *
              </label> 
            <AddItemFormGroupSelect list={groupList} defaultVal={itemToEdit?.Item_Group_Name}
            {...register("Item_Group_Id", { required: "Item Group required", })} 
            onGroupSelect={onGroupSelect} />
             <small className="text-red-500">
                {errors?.Item_Group_Id && errors.Item_Group_Id.message}
              </small>
            </div>

            <div className="">
              <label className="text-sm text-gray-600 font-medium">
                Item Variety Type *
              </label>
              <div className="-mt-1 w-full">
                <GeneralDropDown  {...register("Item_Variety_Id", { required: "Item Variety Type required", })} 
                  defaultVal={itemToEdit?.Item_Variety_Name}
                  dropDown={itemtypeList}
                  optionSelect={selectedItemType}
                />
                <small className="text-red-500">
                {errors?.Item_Group_Id && errors.Item_Variety_Id.message}
              </small>
              </div>
            </div>
          </div>
          {/* here */}

          {/* ITEM INFORMATION SECTION  */}
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="col-span-1 w-full">
              <div className="w-full">
                <label className="text-sm text-gray-600 font-medium">
                  Name *
                </label>
                <input
                  className="-mt-1 h-11 w-full text-sm placeholder:text-sm placeholder:text-gray-400 rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                  type="text"
                  placeholder="Item name"
                  {...register("Item_Name", {
                    required: "Item name is required",
                  })}
                />
              </div>
              <small className="text-red-500">
                {errors?.Item_Name && errors.Item_Name.message}
              </small>

              <div className="mt-4">
                <label className="text-sm text-gray-600 font-medium">
                  Category *
                </label>
                <div className="-mt-1">
                  <GeneralDropDown {...register("Item_Category_Id", { required: "Item Category required", })} 
                    manage={true}
                    defaultVal={itemToEdit?.Item_Category_Name}
                    manageList={manageCategoryList}
                    dropDown={productCategory}
                    optionSelect={selectedCategory}
                  />
                </div>
              </div>

              <div className="mt-[26px]">
                <label className="text-sm text-gray-600 font-medium">
                  Dimension <i>(Length X Width X Height)</i>
                </label>
                <div className="flex flex-row items-center">
                  <div className="">
                    <input
                      type="number"
                      className="w-full z-10 h-10 text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                      {...register("Item_Dimension_Length")}
                    />
                  </div>
                  <div className="ml-2 flex flex-row items-center space-x-1">
                    <div className="text-xs font-medium text-gray-700">X</div>
                    <input
                      type="number"
                      className="w-full z-10 h-10 text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                      {...register("Item_Dimension_Width")}
                    />
                  </div>
                  <div className="ml-3 flex flex-row items-center space-x-1">
                    <div className="text-xs font-medium text-gray-700">X</div>
                    <input
                      type="number"
                      className="w-full z-10 h-10 text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                      {...register("Item_Dimension_Height")}
                    />
                  </div>
                  <div className="ml-2">
                    <DimentionSelect
                    defaultVal={itemToEdit?.Item_Dimension_Unit_Name}
                      selectedDimension={selectedDimensionUnit}
                      productDimension={productDimension}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-7">
                <label className="text-sm text-gray-600 font-medium">UPC</label>

                <input
                  {...register("Item_UPC")}
                  className="-mt-1 h-11 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="mt-4">
                <label className="text-sm text-gray-600 font-medium">
                  Stock out
                </label>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={outOfstock}
                        onChange={(event) => onOutOfStockChange(event)}
                        name="gilad"
                      />
                    }
                    label={
                      outOfstock ? " Item is out of Stock " : "Item in stock"
                    }
                  />
                </FormGroup>
              </div>

              <div className="mt-4">
                <label className="text-sm text-gray-600 font-medium">
                  Product sharing status
                </label>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={productShare}
                        onChange={(event) => onProductIsGrooPoolChange(event)}
                        name="gropool"
                      />
                    }
                    label={productShare ? " GroPool " : " GroPool"}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="col-span-1 w-full">
              {/* take of this space to control the space  */}
              <div className=" ">
                <div>
                  <label className="text-sm text-gray-600 font-medium">
                    Sku
                  </label>{" "}
                  <input
                    className="-mt-1 h-11 w-full placeholder:text-sm text-sm placeholder:text-gray-400 rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                    type="text"
                    placeholder="Sku"
                    {...register("Item_Sku", {
                      required: "Sku is required",
                    })}
                  />
                </div>
                <small className="text-red-500">
                  {errors?.Item_Sku && errors.Item_Sku.message}
                </small>

                <div className="mt-4">
                  <label className="text-sm text-gray-600 font-medium">
                    Unit
                  </label>
                  <div className="-mt-1 w-full">
                    <GeneralDropDown manage={true}   defaultVal={itemToEdit?.Item_Unit_Name}
                      manageList={manageUnitList}
                      {...register("Item_Unit_Id",{required: "Unit is required",})}
                      dropDown={productUnit}  optionSelect={selectedUnitType}
                    />
                    <small className="ml-3 text-red-500">
                  {errors?.Item_Unit_Id && errors.Item_Unit_Id.message}
                </small>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="relative text-sm text-gray-600 font-medium">
                    Weight
                  </label>
                  <span className="w-full flex flex-row items-center space-x-1">
                    <input
                      {...register("Item_Weight")}
                      step="0.01"
                      className="h-11 w-full z-10 text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                      type="number"
                      placeholder=""
                    />
                    <span className="">
                      <DimentionSelect  defaultVal={itemToEdit?.Item_Weight_Name}
                        selectedDimension={selectedProductWeight}
                        productDimension={productWeight}
                      />
                    </span>
                  </span>
                </div>
                <div className="mt-4">
                  <label className="text-sm text-gray-600 font-medium">
                    EAN
                  </label>
                  <input
                    {...register("Item_EAN")}
                    className="h-11 z-10 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div className="mt-4">
                  <label className="text-sm text-gray-600 font-medium">
                    Product sharing slot
                  </label>
                  <ImageSlotSelection
                    defaultVal={itemToEdit?.Sharing_Slot_Id}
                    slot_info={slot_info}
                    onSlotSelection={onSlotSelection}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-1 w-full">
              <div>
                <div className="">
                  <label className="text-sm text-gray-600 font-medium">
                    Brand
                  </label>
                  <div className="-mt-1 w-full">
                    <GeneralDropDown
                      manage={true}
                      defaultVal={itemToEdit?.Item_Brand_Name}
                      manageList={manageBrandList}
                      dropDown={productBrand}
                      optionSelect={selectedBrand}
                    />
                  </div>
                </div>
                <div className="">
                  <label className="text-sm text-gray-600 font-medium">
                    Manufacturer
                  </label>
                  <div className="-mt-1 w-full">
                    <GeneralDropDown
                      manage={true}
                      defaultVal={itemToEdit?.Manufacturer_Name}
                      manageList={manageManufacturer}
                      dropDown={productManufacturer}
                      optionSelect={selectedManufacturer}
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <label className="text-sm text-gray-600 font-medium">
                    ISBN
                  </label>
                  <input
                    {...register("Item_ISBN")}
                    className="h-11 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div className="mt-4">
                  <label className="text-sm text-gray-600 font-medium">
                    MPN
                  </label>
                  <input
                    {...register("Item_MPN")}
                    className="h-11 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div className="mt-4">
                  <label className="text-sm text-gray-600 font-medium">
                    Zoho Item Id
                  </label>
                  <input
                    {...register("zoho_item_id", {
                      required: "ZoHo Item is required",
                    })}
                    className=" h-11 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                    type="text"
                    placeholder=""
                  />
                  <small className="text-red-500">
                    {errors?.zoho_item_id && errors.zoho_item_id.message}
                  </small>
                </div>
              </div>
            </div>
          </div>

          {/* 
    OTHER INFORMATION */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="col-span-1 w-full">
              <div className="mt-4 flex flex-row items-center space-x-1.5">
                <input
                  onChange={(e) => setSalesCheck(e.target.checked)}
                  type="checkbox"
                  checked={salesCheck}
                  className="h-4 w-4 border-[1px] border-gray-500 rounded-sm focus:ring-0 ring-0 cursor-pointer"
                />
                <div className="text-sm font-medium text-gray-700">
                  Sales Information
                </div>
              </div>

              {salesCheck && (
                <div className="w-full">
                  <div className="mt-4 w-full">
                    <label className="relative text-[#F17E7E] text-sm font-medium">
                      Selling Price
                    </label>
                    <span className="w-full flex flex-row items-center space-x-1">
                      <input
                        {...register("Sales_Rate")}
                        className="h-11 z-10 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                        type="text"
                        placeholder=""
                      />
                      <span className="">
                        <DimentionSelect
                          selectedDimension={selectDefaultCurrency}
                          productDimension={defaultCurrency}
                        />
                      </span>
                    </span>
                  </div>
                  {/* Market price */}
                  <div className="mt-4">
                    <label className="w-full flex flex-row items-center space-x-1">
                      Market Price
                    </label>
                    <span className="w-full flex flex-row items-center space-x-1">
                      <input
                        {...register("Market_Rate")}
                        className="h-11 z-10 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                        type="text"
                        placeholder=""
                      />
                      <span className="">
                        <DimentionSelect
                          selectedDimension={selectDefaultCurrency}
                          productDimension={defaultCurrency}
                        />
                      </span>
                    </span>
                  </div>
                  {/* Market price end*/}
                  <div className="mt-4">
                    <label className="relative text-[#F17E7E] text-sm font-medium">
                      Sales Account
                    </label>
                    <div className="w-full">
                      <GeneralDropDown
                        manage={false}
                        dropDown={salesAccounts}
                        defaultVal={itemToEdit?.Sales_Accounts_Name}
                        optionSelect={selectedSalesAccount}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className=" text-gray-600 text-sm font-medium">
                      Description
                    </label>
                    <textarea
                      {...register("Sales_Description")}
                      className="z-10 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                      role="4"
                      type="text"
                      placeholder="Write a Description"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="relative text-[#F17E7E] text-sm font-medium">
                      Sales Tax
                    </label>
                    <div className="w-full">
                      <GeneralDropDown
                        manage={true}
                        defaultVal={itemToEdit?.Sales_Tax_Name}
                        manageList={manageTaxList}
                        dropDown={taxList}
                        optionSelect={salesTaxList}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* CONTROL SPACE BETEEN */}
            <div className="col-span-1 w-full">
              <div className="mt-4 flex flex-row items-center space-x-1.5">
                <input
                  onChange={(e) => setPurchaseCheck(e.target.checked)}
                  type="checkbox"
                  checked={purchaseCheck}
                  className="h-4 w-4 border-[1px] border-gray-500 rounded-sm focus:ring-0 ring-0 cursor-pointer"
                />
                <div className="text-sm font-medium text-gray-700">
                  Purchase Information
                </div>
              </div>

              {purchaseCheck && (
                <span>
                  <div className="mt-4">
                    <label className="w-full text-gray-600 text-sm font-medium">
                      Cost Price
                    </label>
                    <span className="w-full flex flex-row items-center space-x-1">
                      <input
                        {...register("Cost_Rate")}
                        className="h-11 z-10 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                        type="text"
                        placeholder=""
                      />
                      <span className="dimenSelect_input ">
                        <DimentionSelect
                          selectedDimension={selectDefaultCurrency}
                          productDimension={defaultCurrency}
                        />
                      </span>
                    </span>
                  </div>
                  <div className="mt-4">
                    <label className="w-full text-[#F17E7E] text-sm font-medium">
                      Purchase Account
                    </label>
                    <div className="w-full">
                      <GeneralDropDown
                        manage={false}
                        defaultVal={itemToEdit?.Purchase_Account_Name}
                        dropDown={purchaseAccounts}
                        optionSelect={selectedPurchaseAccount}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <label className="w-full text-gray-600 text-sm font-medium">
                      Description
                    </label>
                    <textarea
                      {...register("Purchase_Description")}
                      className="z-10 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                      role="4"
                      type="text"
                      maxLength="1000"
                      placeholder="Write a Description"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="w-full text-[#F17E7E] text-sm font-medium">
                      Purchase Tax{" "}
                    </label>
                    <div className="w-full">
                      <GeneralDropDown
                        manage={true}
                        manageList={manageTaxList}
                        defaultVal={itemToEdit?.Purchase_Tax_Name}
                        dropDown={taxList}
                        optionSelect={selectPurchaseTax}
                      />
                    </div>
                  </div>
                </span>
              )}
            </div>

            <div className="col-span-1 w-full">
              {/* <AddTrackingItemCheckbox
                  val={itemToEdit?.Item_Tracking_Need_Id}
                  addTrackingForItem={addTrackingForItem}
                ></AddTrackingItemCheckbox> */}
              <div className="mt-4 flex flex-row items-center space-x-1.5">
                <input
                  onChange={(e) => addTrackingForItem(e.target.checked)}
                  type="checkbox"
                  checked={trackCheck}
                  className="h-4 w-4 border-[1px] border-gray-500 rounded-sm focus:ring-0 ring-0 cursor-pointer"
                />
                <div className="text-sm font-medium text-gray-700">
                  Track inventory for this Item{" "}
                </div>
              </div>

              {trackCheck && (
                <span>
                  <AddItemTrackingTypeCheckRadioButton
                    val={itemToEdit?.Item_Tracking_Type_Id}
                    addItemTrackingType={addItemTrackingType}
                  ></AddItemTrackingTypeCheckRadioButton>

                  <div className="mt-2">
                    <label className="relative text-gray-600 text-sm font-medium">
                      Minimum Payment
                    </label>
                    <span className="w-full flex flex-row items-center space-x-1">
                      <input
                        {...register("Minimum_Payment")}
                        className="h-11 z-10 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                        type="text"
                        placeholder=""
                      />
                      <span className="dimenSelect_input ">
                        <DimentionSelect
                          selectedDimension={selectDefaultCurrency}
                          productDimension={defaultCurrency}
                        />
                      </span>
                    </span>
                    {/* <input
                      {...register("Minimum_Payment")}
                      className="bg-filterInput placeHolder_input z-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="number"
                      placeholder=""
                    />
                    <span className="text-filterTextColor -z-0 absolute bg-white left-0  h-[6vh] w-[4vw] mt-1  py-2 px-2 rounded  text-xs ">
                      NGN
                    </span> */}
                  </div>
                  <div className="mt-4">
                    <label className="relative text-[#F17E7E] text-sm font-medium">
                      Inventory Account
                    </label>
                    <div className="w-full">
                      <GeneralDropDown
                        manage={false}
                        defaultVal={itemToEdit?.Inventory_Accounts_Name}
                        dropDown={inventoryAccounts}
                        optionSelect={selectedInventoryAccount}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="relative text-gray-600 text-sm font-medium">
                      Reorder Point
                    </label>
                    <input
                      {...register("Reorder_Quantity")}
                      className="h-11 z-10 w-full text-sm rounded-md outline-none border-gray-300 focus:border-transparent focus:ring-[2px] focus:ring-blue-500"
                      type="number"
                      placeholder=""
                    />
                  </div>

                  <div className="mt-4">
                    <label className="relative text-[#F17E7E] text-sm font-medium">
                      Preferred Vendor
                    </label>
                    <div className="w-full">
                      <GeneralDropDown
                        manage={true}
                        defaultVal={itemToEdit?.Preferred_Vendor_Name}
                        manageList={manageVendorList}
                        dropDown={vendors}
                        optionSelect={selectedVendor}
                      />
                    </div>
                  </div>
                </span>
              )}

              {/* <div className="mt-2">
          <span className="text-xs">Preffered Vendor</span> <br></br>
          <input
            className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
            type="text"
            placeholder=""
          />
        </div> */}
            </div>
          </div>

          <section className="flex my-5 justify-between   ">
            <a
              onClick={() => navigate(-1)}
              style={{ color: "#F17E7E" }}
              className="py-2 btn px-4 text-sm rounded "
            >
              Cancel
            </a>

            <button
              type="submit"
              className="py-2 px-6 text-sm rounded-md bg-blue-500 text-white font-medium"
            >
              Save
            </button>
          </section>
        </div>
      </form>
    </>
  );
}

export default AddItemForm;
